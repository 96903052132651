import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookiesComponent } from './components/cookies/cookies.component';
import { MentionsLegalesComponent } from './components/mentions-legales/mentions-legales.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'mentions-legales',
    component: MentionsLegalesComponent
  },
  {
    path: 'cookies',
    component: CookiesComponent
  },
  {
    path: 'login',
    loadChildren: './core/core.module#CoreModule'
  },
  {
    path: 'sante',
    loadChildren: './features/process-sante/process-sante.module#ProcessSanteModule'
  },
  {
    path: 'acds',
    loadChildren: './features/process-acds/process-acds.module#ProcessAcdsModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

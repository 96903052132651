export abstract class OavUrlConstants {

  /**************global URLs************* */
  static readonly GLOBAL = '/oav/ws/rest';
  static readonly LS = '/list';
  static readonly CR = '/create';
  static readonly GET = '/get';
  static readonly SAVE = '/save';
  /**************beneficiare URLs****************/
  static readonly BENEFICIAIRE_ENDPOINT = '/beneficiaires';
  static readonly GET_NATIONALITIES = '/nationalities';
  static readonly GET_PAYS = '/pays_autorise';
  static readonly GET_ETABLISSEMENTS = '/etablissements';
  static readonly UPLOAD_DOCS = '/uploadAdherentDocuments';
  static readonly UPDATE_INFO = '/update_benef';
  static readonly GET_DEVIS_DOCUMENT = '/devis_document';
  static readonly DELETE_BY_DEVIS = '/delete_by_devis';
  /****************devis URLs******************* */
  static readonly DEVIS_ENDPOINT = '/devis';
  static readonly UPDATE_FORMULE_DEVIS = '/update_devis_formule';
  static readonly CREATE_REF_BANCAIRE = '/saveRefBanque';
  static readonly UPDATE_DEVIS_VENTE = '/update_vente';
  static readonly SEND_ACTIVATION_MAIL = '/send_activation_mail';
  /*****************formule URLs**************** */
  static readonly FORMULE_ENDPOINT = '/formules';
  static readonly DOWNLOAD_PDF = '/download_garantie';
  /*****************payment URLs***************** */
  static readonly PAYMENT_ENDPOINT = '/payement';
  static readonly PAY_DEVIS = '/pay_devis';
  static readonly PAY_RESULT = '/pay_result';
  static readonly PAYMENT_BY_DEVIS = '/byDevis';
  static readonly LATEST_PAYMENT_BY_DEVIS = '/latestbyDevis';
  /******************profil ULRs****************** */
  static readonly PROFIL_ENDPOINT = '/profil';
  static readonly CONTRAT_BY_ID_EXTERNE = 'contrat_by_id_externe';
  static readonly USER = '/user';
  static readonly UPDATE = '/update';
  static readonly UPDATE_CREDENTIALS = '/update_credentials';
  static readonly BY_USERNAME = '/byUsername';
  static readonly FIND_BY_USERNAME = '/findByUsername';
  static readonly RENIT_PASS = '/renit_pass';
  static readonly CHECK_OLD_PASSWORD = '/check_old_password';
  static readonly REINIT_CREDENTIALS = '/reinit_credentials';
  /******************regime URLs****************** */
  static readonly REGIME_ENDPOINT = '/regimes';
  /******************risque URLs********************/
  static readonly RISQUE_ENDPOINT = '/risques';
  static readonly LS_QUESTIONS = '/listQuestions';
  static readonly LS_DEVIS_QUESTIONS = '/listDevisQuestions';
  /*******************signature URLs**************** */
  static readonly SIGNATURE_ENDPOINT = '/signature';
  static readonly FINISH_SIGN = '/finishsign';
  static readonly UPDATE_PACKAGE_TESSI_SIGN_STATUS = '/update_package_tessi_sign';
  /*******************tarificateur URLs************* */
  static readonly TARIFICATEUR_ENDPOINT = '/tarifs';
  static readonly ESTIMATION_DEVIS = '/estimation';
  static readonly UPDATE_DEVIS = '/update_devis';
  static readonly CALCUL_TARIF_SN = '/calcul_SN';
  /****************Auth URLs******************* */
  static readonly TOKEN = '/oauth/token';
  static readonly AUTHENTIFICATION = '/authentication/login';
  static readonly PUBLIC_LOGIN = '/authentication/public_login';
  /****************Audit URLs******* */
  static readonly CREATE_AUDIT = '/create_audit';
  /****************Audit URLs******* */
  static readonly LS_CODE_PROMO = '/list_codes_promo';
  /****************CallBack URLs******* */
  static readonly CR_CALLBACK = '/create_callback';
  /****************Souscripteur URLs***************** */
  static readonly SOUSRIPTEUR_ENDPOINT = '/souscripteur';
  static readonly BY_USER = '/byUser';
  /****************CallBack URLs******* */
  static readonly CR_COURRIER = '/create_courrier';
  /****************Besoins URLs******* */
  static readonly BESOINS_ENDPOINT = '/besoins';
  static readonly QUESTIONS_REPONSES_SCORE = '/getQuestionReponsesScores';
  static readonly SAVE_REPONSES = '/SaveReponses';
  /****************CallBack URLs******* */
  static readonly LS_FORMULES_ACDS = '/list_acds';
  static readonly CR_TEMPLATE = '/devis_template';
  /******************* Espace prospect ******************************/
  static readonly GET_BENEFICAIRE_BY_ID_USER = '/get_beneficiaire_by_id_user';
  static readonly GET_BY_ID_USER = '/get_by_id_user';
  static readonly BY_ID_EXTERNE = '/user_by_id_externe';
  static readonly CONTRATS_BY_ID_EXTERNE = '/contrat_by_id_externe';
  static readonly GET_DEVIS_BY_ID_USER = '/get_devis_by_id_user';
  static readonly UPDATE_DEVIS_STATUS = '/update_status';
  /*****************************Bandeau message************* */
  static readonly BANDEAU_MESSAGE = '/bandeau_message';
  static readonly GET_BANDEAU_MESSAGE = '/get_bandeau_message';
  /*****************************ville************************ */
  static readonly VILLE_ENDPOINT = '/ville';
  /*****************************Offers************************ */
  static readonly SEARCH_CONTRAT_CONJOINT = '/getContart';
  static readonly SAVE_OFFERS = '/save_offers_details';
  /*****************************document statique************************ */
  static readonly DOCUMENT_STATIQUE = '/document_statique';
  static readonly GET_DOC_STATIQUE_BY_PRODUIT_AND_PARCOURS_AND_ECRAN = '/get_list_documents';
  static readonly GET_DOC_STATIQUE_BY_ID = '/get_documnet_statique';
  /*****************************QMS************************ */
  static readonly QMS = '/qms';
  static readonly GET_QMS_DOC = '/get_qms_doc';
  static readonly ACCPET_QMS = '/accept_qms';
  static readonly SAVE_QMS = '/save_qms';
  /*****************************REF_BANCAIRE************************ */
  static readonly UPDATE_REF_BANCAIRE = '/updateRefBanque';
  /*****************************REF_Devis************************ */
  static readonly CR_REFERENCE = '/createReference';
  /*******************************Pays************************* */
  static readonly PAYS_ENDPOINT = '/pays';
  /*********************************************************** */
  static readonly ASSUREUR_ENDPOINT = '/assureur';
  /*****************************Mes infos****************************** */
  static readonly UPDATE_INFOS_DEVIS = '/update_devis_infos';
  static readonly REF_CONTRAT = '/create_ref_contrat';
  static readonly DOWNLOAD_PDF_CG = '/download_CG';
  static readonly UPDATE_STATUS = '/update_status';
  static readonly VALIDATE_PROMO = '/validate_promo';
  static readonly SEARCH_UNFINISHED_DEVIS = '/search_unfinished_devis';
  static readonly GET_USER_BY_PARAMS = '/get_user_by_params';
  static readonly GET_AGENT_BY_USER = '/get_by_user';
  static readonly GET_BY_ID_EXTERNE = '/get_by_id_externe';
  static readonly AGENT_ENDPOINT = '/agent';
  static readonly GET_BY_ID_AGENT_EXT = '/get_by_id_agent_ext';
  /* *********** update devis from profil ******************** */
  static readonly UPDATE_PROFIL = '/update_devis_profil';
  /* *********** users endpoint ******************** */
  static readonly USER_ENDPOINT = '/users';
  static readonly USER_DETAILS = '/details';
  static readonly LOCK_ACCOUNT = '/lock_account';
  static readonly CP_NAISSANCE = '/cp_naissance';
  static readonly TESSI_DOCS = '/tessi_docs';
  static readonly DOWNLOAD_PDF_TESSISIGN = '/download_pdf_tessi';
  static readonly MAIL_FINALISATION_DEVIS = '/send_finalisation_devis';
  /*********************Filter ****************************** */
  static readonly FILTER_ENDPOINT = '/filter';
  static readonly FILTER_USERS = '/filter_users';
  /***********************QMS************* */
  static readonly DOWNLOAD_QMS = '/download_qms';
  static readonly DELETE = '/delete';
  /************* RESET PASSWORD TOKEN ******************/
  static readonly RESET_CREDENTIALS_TOKEN_ENDPOINT = '/resetCredentialsToken';
  static readonly CHECK_TOKEN_VALIDITY = '/check_token_validity';
  /************* REFRESH TOKEN *************************/
  static readonly REFRESH_TOKEN_ENDPOINT = '/refresh_token';
  static readonly GENERATE_REFRESH_TOKEN = '/generate_refresh_token';
  static readonly CHECK_TOKEN_VALIDITY_AND_GET_USERNAME = '/check_token_validity_and_get_username';
  static readonly DELETE_RESET_PASSWORD_TOKEN = '/delete_reset_password_token';
  /************* ENTRY POINT ENDPOINT ******************/
  static readonly ENTRY_POINT_ENDPOINT = '/oav/epnt';
  /************** CALL BACK*****************************/
  static readonly CALL_BACK_ENDPOINT = '/call_back';
  /************** Paiement ENDPOINT *******************/
  static readonly PAIEMENT_ENDPOINT = '/api-payment/V4/Charge';
  static readonly PAIEMENT_CREATE_PAYMENT = '/CreatePayment';
  /***********************QM****************************************** */
  static readonly QM_ENDPOINT = '/qm';
}

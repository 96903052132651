
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfig } from 'src/app/app.config';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { PaymentRequest } from '../../models/payment-request';
@Component({
  selector: 'app-lbp-bip',
  templateUrl: './lbp-bip.component.html',
  styleUrls: ['./lbp-bip.component.css']
})
export class LbpBIPComponent implements OnInit {
  paymentRequest = new PaymentRequest();
  devis;
  produit;


  constructor(private paymentService: PaymentService, private cookies: CookieService,
    private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.produit = this.cookies.get(OavConstants.PRODUIT);
    this.devis = this.cookies.get(OavConstants.DEVIS);
    this.getLatestByDevis();
  }

  getLatestByDevis() {
    this.paymentService.getLatestByDevis(this.devis).subscribe((data: any) => {
      this.redirect(data);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.error('error when get latest payment by devis');
    });
  }

  redirect(data): void {
    if (data.status === 'PAID') {
      if (this.produit === 'ACDS') {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_MANDAT_ACDS
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.VALIDATION_ACDS_URL]);
      } else {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_MANDAT_SANTE
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.VALIDATION_SANTE_URL]);
      }
    } else {
      if (this.produit === 'ACDS') {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_ACDS,
          undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.MANDAT_SIGNATURE_ACDS_URL]);
      } else {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE,
          undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.MANDAT_SIGNATURE_SANTE_URL]);
      }
    }
  }

}

import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AppConfig} from 'src/app/app.config';
import {PaymentRequest} from 'src/app/core/models/payment-request';
import {OavConstants} from 'src/app/shared/data/OavConstants';
import {PaymentService} from 'src/app/shared/services/payment.service';
import {DevisModelDTO} from '../../models/devisDTO';
import {DevisService} from '../../services/devis.service';
import {SignatureService} from '../../services/signature.service';
import KRGlue from '@lyracom/embedded-form-glue';
import {DevisStatus} from '../../models/devisStatus';
import {GuardService} from '../../../../shared/services/guard.service';
import {ReferenceBancaire} from '../../models/referenceBancaire';
import {TrakingParamConstants} from '../../../../shared/data/TrakingParamValues';
import {ModalService} from '../../../../shared/services/modal.service';
import {TagCommanderSevice} from '../../../../shared/services/tag-Commander.sevice';


@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.css']
})
export class PaiementComponent implements OnInit, AfterViewInit, OnDestroy {
  paymentRequest = new PaymentRequest();
  @ViewChild('paiementForm') paiementForm: ElementRef;
  devisId;
  payed = false;
  items = [
    {
      label: 'Mes informations'
    },
    {
      label: 'Mon mandat de prélèvement'
    },
    {
      label: 'Ma signature'
    },
    {
      label: 'Paiement'
    },
    {
      label: 'Mon contrat'
    }
  ];
  token: string;
  changeStepperView = false;
  activeIndex = 3;
  try = 0;
  modePayement;
  setToPrelev = false;
  private canal: string;

  constructor(private paymentService: PaymentService, private spinner: NgxSpinnerService, private cookies: CookieService,
              private router: Router, private devisService: DevisService, private signatureService: SignatureService,
              private guardService: GuardService,  private tagCommanderSevice: TagCommanderSevice,
              private readonly elementRef: ElementRef, private renderer: Renderer2, private modalService: ModalService) {
  }

  ngOnInit() {
    this.devisId = this.cookies.get(OavConstants.DEVIS);
    this.modePayement = this.cookies.get('modePay');
    console.log('ModePayement', this.modePayement);
    if (this.modePayement !== 'PRELEVEMENT') {
      this.canal = this.tagCommanderSevice.getCanal();
      this.tagCommanderSevice.trackingPage(TrakingParamConstants.SANTE_SOUSCRIPTION_ETAPE3_PAIEMENT_CB_PAGENAME, this.canal);
      this.tagCommanderSevice.reloadScript(TrakingParamConstants.SANTE_SOUSCRIPTION_ETAPE3_PAIEMENT_CB_PAGENAME,
        this.renderer, this.elementRef);
    }

    this.try = localStorage.getItem('t3') ? +localStorage.getItem('t3') : 0;
    if (this.cookies.get(OavConstants.CURRENT_SCREEN) === OavConstants.ECRAN_MANDAT_CB_SANTE) {
      this.changeStepperView = true;
      this.payementFormBuilder();
    } else {
      this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
        if (this.cookies.get(OavConstants.CURRENT_SCREEN) !== OavConstants.ECRAN_INFORMATIONS_SANTE ||
          this.cookies.get(OavConstants.CURRENT_SCREEN) === OavConstants.ECRAN_INFORMATIONS_SANTE && this.modePayement !== 'CB') {
          if (this.modePayement !== 'CB' && this.router.url.includes('SIGNED')) {
            this.setToPrelev = true;
            this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_MANDAT_SANTE
              , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          }
          if (this.cookies.get(OavConstants.CURRENT_SCREEN) !== OavConstants.ECRAN_MANDAT_CB_SANTE) {
            this.changeStepperView = false;
          }
          if (this.setToPrelev) {
            const refBanq = devis.refBancaire;
            refBanq.premiereCotisation = 'PRELEVEMENT';
            refBanq.idDevis = this.devisId;
            this.devisService.updateRefBque(refBanq).subscribe(ref => {
              this.guardService.checkCurrentScreen().subscribe(data => {
              });
            });
          } else {
            this.guardService.checkCurrentScreen().subscribe(data => {
            });
          }
        } else if (devis.status !== DevisStatus.A_PAYER && !this.router.url.includes('SIGNED')) {
          this.changeStepperView = false;
          this.guardService.checkCurrentScreen().subscribe(data => {
          });
        } else {
          this.spinner.show();
          if (this.router.url.includes('REJECT')) {
            this.changeStepperView = false;
            this.updateDevisStatus(DevisStatus.MANDAT);
            this.updatePackageTessiSignStatus('Rejected');
            this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE
              , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
            this.router.navigate([OavConstants.MANDAT_SIGNATURE_SANTE_URL]);
          } else if (this.router.url.includes('SIGNED')) {
            this.changeStepperView = true;
            this.updateDevisStatus(DevisStatus.A_PAYER);
            this.updatePackageTessiSignStatus('Finished');
          }
          if (!this.router.url.includes('REJECT')) {
            this.changeStepperView = true;
            this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_MANDAT_CB_SANTE
              , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
            this.payementFormBuilder();
          }
        }
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // @ts-ignore
      // tslint:disable-next-line:no-shadowed-variable
      const steps: Array<HTMLElement> = document.getElementsByClassName('ui-steps-item');
      if (this.changeStepperView) {
        for (let i = 0; i < steps.length; i++) {
          // tslint:disable-next-line:no-unused-expression
          steps[i].style.cssText = 'width : 20% !important';
        }
      }
    }, 200);
  }

  payementFormBuilder() {
    const endpoint = OavConstants.PAIEMENT_SCELLIUS_URL;
    const publicKey = OavConstants.CONNEXION_COORD_PAIEMENT;
    this.paymentService.createPayement(this.devisId).subscribe(paymentResponse => {
      if (paymentResponse) {
        KRGlue.loadLibrary(endpoint, publicKey) /* Load the remote library */
          .then(({KR}) =>
            KR.setFormConfig({
              /* set the minimal configuration */
              formToken: paymentResponse.answer.formToken,
            })
          )
          .then(({KR}) => {
            this.spinner.hide();
            this.changeStepperViewCss();
            KR.onError(err => {
              if (err.errorCode === 'PSP_099') {
                localStorage.setItem('t3', String(5));
                window.location.reload();
              }
              if (err.errorCode === 'PSP_532') {
                err.errorMessage = 'Carte non valide';
                this.try = 0;
              }
              document.getElementsByClassName('customerror')[0].innerHTML = err.errorMessage;
              this.try++;
              this.spinner.show();
              // localStorage.setItem('t3', String(this.try));
              console.log('error', err.metadata);
              if (err.metadata.answer) {
                this.errorPaymentForm(err.metadata.answer.clientAnswer);
                console.log('error', err.metadata.answer.clientAnswer);
              } else {
                this.spinner.hide();
                console.log('error', err);
                if (err.errorCode === 'CLIENT_305') {
                  window.location.reload();
                }
              }
            });
            KR.onSubmit(response => {
              this.spinner.show();
              this.initPaymentForm(response);
            });
          }); /* show the payment form */
      } else {
        this.payed = true;
      }
    }, error => {
      alert('Impossible d\'accéder au service de payement voyez resseyez');
      this.spinner.hide();
    });
  }

  changeStepperViewCss() {
    setTimeout(() => {
      // @ts-ignore
      const steps: Array<HTMLElement> = document.getElementsByClassName('ui-steps-item');
      if (this.changeStepperView) {
        for (let i = 0; i < steps.length; i++) {
          steps[i].style.cssText = 'width : 20% !important';
        }
      }
    });
  }

  private updateDevisStatus(status) {
    this.devisService.updateDevisStatus(this.devisId, status).subscribe((data: DevisModelDTO) => {
    }, error => {
      console.error('Error when update devis status');
      this.spinner.hide();
    });
  }

  private updatePackageTessiSignStatus(status) {
    this.signatureService.updatePackageTessiSignStatus(this.devisId, status).subscribe((data: DevisModelDTO) => {
    }, error => {
      console.error('Error when update tessi sign package status');
      this.spinner.hide();
    });
  }

  initPaymentForm(response) {
    this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
      if (devis.refBancaire.premiereCotisation === 'CB') {
        this.paymentRequest.vads_trans_date = this.createTransactionDate();
        this.paymentRequest.idDevis = this.cookies.get(OavConstants.DEVIS);
        this.paymentService.getPaymentInfo(this.paymentRequest).subscribe((data: PaymentRequest) => {
          this.paymentRequest = data;
          this.cookies.set(OavConstants.PRODUIT, 'SANTE', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          console.log('IPN', response.post()['kr-answer']);
          this.cookies.set('trans_ref', data.vads_trans_id, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          this.paymentService.afterPayement(response.post()['kr-answer'], data.vads_trans_id, this.devisId).subscribe((rslt) => {
            this.payed = true;
            this.spinner.hide();
            this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_PAIEMENT_SANTE
              , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
            this.router.navigate([OavConstants.PAIEMENT_CONFIRME_SANTE_URL]);
          });

        }, error => {
          this.spinner.hide();
          console.error('error when get payment info');
        });
      } else {
        this.spinner.hide();
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_MANDAT_SANTE
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.VALIDATION_SANTE_URL]);
      }
    }, error => {
      this.spinner.hide();
      console.error('error when get devis by id');
    });
  }

  errorPaymentForm(response) {
    this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
      this.paymentRequest.vads_trans_date = this.createTransactionDate();
      this.paymentRequest.idDevis = this.cookies.get(OavConstants.DEVIS);
      this.paymentService.getPaymentInfo(this.paymentRequest).subscribe((data: PaymentRequest) => {
        this.paymentRequest = data;
        this.cookies.set(OavConstants.PRODUIT, 'SANTE', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.paymentService.afterPayement(JSON.stringify(response), data.vads_trans_id, this.devisId).subscribe((rslt) => {
          this.spinner.hide();
        });
      }, error => {
        this.spinner.hide();
        console.error('error when get payment info');
      });
    }, error => {
      this.spinner.hide();
      console.error('error when get devis by id');
    });
  }

  private createTransactionDate(): string {
    const date = new Date();
    return date.getFullYear().toString() + this.convertToString(date.getMonth() + 1) + this.convertToString(date.getDate()) +
      this.convertToString(date.getHours()) + this.convertToString(date.getMinutes()) + this.convertToString(date.getSeconds());
  }

  private convertToString(nb: number): string {
    return nb > 9 ? nb.toString() : '0' + nb.toString();
  }

  backViewTab() {
    this.cookies.set('previous', 'mes informations', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE);
    this.router.navigate([OavConstants.MANDAT_SIGNATURE_SANTE_URL]);
  }

  prelevemntAutomatique() {
    this.spinner.show();
    let refbque = new ReferenceBancaire();
    this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
      refbque = devis.refBancaire;
      console.log(refbque);
      refbque.idDevis = this.devisId;
      refbque.premiereCotisation = 'PRELEVEMENT';
      this.devisService.updateRefBque(refbque).subscribe(() => {
        this.spinner.hide();
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_MANDAT_SANTE
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.VALIDATION_SANTE_URL]);
      });
    });
  }

  ngOnDestroy() {
    // @ts-ignore
    const steps: Array<HTMLElement> = document.getElementsByClassName('ui-steps-item');
    for (let i = 0; i < steps.length; i++) {
      // tslint:disable-next-line:no-unused-expression
      steps[i].removeAttribute('style');
    }
    localStorage.setItem('t3', '0');
  }

}

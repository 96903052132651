import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { EncryptionDecryptionService } from 'src/app/shared/services/encryption-decryption.service';
import { ResetPasswordTokenService} from '../../services/resetPasswordToken.service';
import { ReinitPasswordObj } from '../../models/reinitPasswordObj';
import { AuthenticationUtilitiesService } from '../../services/authenticationUtilities.service';
import { AuthService } from '../../../shared/services/auth.service';
import { LoginCredentials } from '../../models/loginObj';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from 'src/app/app.config';
import {TrakingParamConstants} from '../../../shared/data/TrakingParamValues';
import {TagCommanderSevice} from '../../../shared/services/tag-Commander.sevice';

@Component({
  selector: 'app-first-connexion',
  templateUrl: './first-connexion.component.html',
  styleUrls: ['./first-connexion.component.css']
})
export class FirstConnexionComponent implements OnInit {

  token: string;
  expired = false;
  passwordSameAsUsername = false;
  password;
  confirmation;
  confirmationPassswordError: boolean;
  tryToSubmitForm = false;
  isNewPasswordValid = true;
  accountLocked = false;
  @ViewChild('firstConnexionForm') firstConnexionForm;
  private canal: string;

  constructor(private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute, private userService: UserService,
              private encryptionDecryptionService: EncryptionDecryptionService,
              private resetPasswordTokenService: ResetPasswordTokenService,
              private authUtilities: AuthenticationUtilitiesService,
              private authService: AuthService, private router: Router,
              private cookies: CookieService , private tagCommanderSevice: TagCommanderSevice,
              private readonly elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.PROSPECT_REINITIALISATION_PAGENAME, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.PROSPECT_REINITIALISATION_PAGENAME, this.renderer, this.elementRef);

    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['reset_password_token'];
      this.checkTokenValidity();
    });
  }

  checkTokenValidity() {
    this.spinner.show();
    this.resetPasswordTokenService.checkTokenValidity(this.token).subscribe((result) => {
      this.expired = !result;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  validate() {
    this.spinner.show();
    this.passwordSameAsUsername = false;
    this.isNewPasswordValid = true;
    this.accountLocked = false;
    if (this.validForm()) {
      this.tryToSubmitForm = false;
      const reinitPasswordObject = new ReinitPasswordObj();
      reinitPasswordObject.token = this.token;
      reinitPasswordObject.newPassword = this.encryptionDecryptionService.encryptPassword(this.password);
      this.userService.updateUserAndPwd(reinitPasswordObject).subscribe((response: any) => {
        const credentials = new LoginCredentials();
        credentials.password = reinitPasswordObject.newPassword;
        credentials.activationId = response.result;
        this.authService.login(credentials).subscribe((data: any) => {
          this.authUtilities.clearStorage();
          this.authUtilities.updateCookie(data);
          this.authUtilities.processAfterConnection(false);
        }, error => {
          this.spinner.hide();
        });
      }, error => {
        this.onFailUpdateUserPwd(error);
        this.spinner.hide();
      });
    } else {
      this.tryToSubmitForm = true;
      this.spinner.hide();
    }
  }

  onFailUpdateUserPwd(error) {
    if (error) {
      if (error.errorCode === 12) {
        this.accountLocked = true;
      } else if (error.errorCode === 15) {
        this.isNewPasswordValid = false;
      } else if (error.errorCode === 16) {
        this.passwordSameAsUsername = true;
      }
    }
  }

  initErrorMessages() {
    this.isNewPasswordValid = true;
    this.passwordSameAsUsername = false;
  }

  resetForm() {
    this.firstConnexionForm.reset();
    this.password = '';
    this.confirmation = '';
    this.tryToSubmitForm = false;
    this.accountLocked = false;
    this.passwordSameAsUsername = false;
    this.isNewPasswordValid = true;
  }

  validForm() {
    return (this.validPassword() && (this.password === this.confirmation));
  }

  validPassword() {
    const lettersRe = /[a-zA-Z]+/;
    const digitRe = /\d+/;
    const specialCharacterRe = /\W+/;
    if (this.password) {
      return ((this.password.length >= 10) && (specialCharacterRe.test(this.password))
        && (digitRe.test(this.password)) && (lettersRe.test(this.password)));
    }
    return false;
  }

  checkConfirmationPasswordError() {
    this.confirmationPassswordError = this.password && this.confirmation && this.password !== this.confirmation;
  }

  renitPassword() {
    this.cookies.set(OavConstants.SHOW_LOGIN, OavConstants.RANDOM_STRING,
      undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.LOGIN_URL]);
  }
}

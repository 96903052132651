import { Injectable } from '@angular/core';
import { CookiesEntriesModel } from '../../shared/models/cookiesEntries.Model';
import { CookieService } from 'ngx-cookie-service';
import { UserDTO } from '../models/userDTO';
import { UserService } from './user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RoleConstants } from '../../shared/data/RoleConstants';
import { OavConstants } from '../../shared/data/OavConstants';
import { ClientDataDTO } from '../models/clientData';
import { Router } from '@angular/router';
import { EspaceAgentService } from './espace-agent.service';
import { NavbarService } from '../../service/navbar.service';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationUtilitiesService {

  constructor(private cookies: CookieService, private userService: UserService,
    private spinner: NgxSpinnerService, private router: Router, private agentService: EspaceAgentService,
    private navbarService: NavbarService) { }

  clearStorage() {
    const cookiesEntries = new CookiesEntriesModel().entries;
    cookiesEntries.forEach(entry => {
      this.cookies.delete(entry);
    });
  }

  updateCookie(data: any) {
    this.cookies.set('accessToken', data.token);
    this.cookies.set('refreshToken', data.refreshToken);
    this.cookies.set('expires_in', data.expires_in);
    this.navbarService.refreshTheToken();
  }

  processAfterConnection(isFromLogin: boolean) {
    this.userService.getConnectedUser().subscribe(result => {
      if (isFromLogin) {
        const connectedUser: any = result;
        this.userService.getUserByUsername(connectedUser.username).subscribe((userDTO: UserDTO) => {
          const user: UserDTO = userDTO;
          user.username = connectedUser.username;
          this.spinner.hide();
          const isAgent = this.isAgent(connectedUser.authorities);
          if (user) {
            this.redirectUserSwitchRole(isAgent, user, isFromLogin);
          }
        }, error => {
          this.spinner.hide();
        });
      } else {
        setTimeout(() => {
          const connectedUser: any = result;
          this.userService.getUserByUsername(connectedUser.username).subscribe((userDTO: UserDTO) => {
            const user: UserDTO = userDTO;
            user.username = connectedUser.username;
            this.spinner.hide();
            const isAgent = this.isAgent(connectedUser.authorities);
            if (user) {
              this.redirectUserSwitchRole(isAgent, user, isFromLogin);
            }
          }, error => {
            this.spinner.hide();
          });
        }, 3000);
      }
    }, error => {
      this.spinner.hide();
    });
  }

  isAgent(authorities: any) {
    let isAgent = false;
    authorities.forEach(element => {
      if (element.authority === RoleConstants.AGENT) {
        isAgent = true;
        return isAgent;
      }
    });
    return isAgent;
  }

  redirectUserSwitchRole(isAgent, user, isFromLogin) {
    if (isAgent) {
      this.agentService.getAgentByIdUser(user.activationId).subscribe(rep => {
        this.cookies.set('agent', JSON.stringify(rep));
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_AGENT
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        if (isFromLogin) {
          this.router.navigate([OavConstants.ESPACE_AGENT]);
        } else {
          this.router.navigate([OavConstants.RENIT_PASS_RESUME]);
        }
        this.cookies.set(OavConstants.IS_CONNECTED, OavConstants.TRUE, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.cookies.set('user', 'false', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      }, error => {
        this.spinner.hide();
      });
    } else {
      const client = new ClientDataDTO();
      client.email = user.username;
      client.clientUid = user.activationId;
      this.cookies.set('client', JSON.stringify(client), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set('user', 'true', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set(OavConstants.IS_CONNECTED, OavConstants.TRUE, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      if (isFromLogin) {
        this.router.navigate([OavConstants.ESPACE_PROSPECT]);
      } else {
        this.router.navigate([OavConstants.RENIT_PASS_RESUME]);
      }
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { DevisModelDTO } from '../models/devisDTO';
import { OavUrlConstants } from '../../../shared/data/OavURLConstants';
import { DevisVenteDTO } from '../models/devisVenteDTO';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DevisService {

  publicUri = AppConfig.publicEndpoint + OavUrlConstants.PROFIL_ENDPOINT;
  securedUri = AppConfig.securedEndpoint + OavUrlConstants.DEVIS_ENDPOINT;
  entryPublicUri = AppConfig.publicEndpoint + OavUrlConstants.ENTRY_POINT_ENDPOINT;


  constructor(private http: HttpClient, private cookie: CookieService) { }


  createDevis(devisDTO: DevisModelDTO) {
    let options = { headers: new HttpHeaders() };
    if (this.cookie.get('accessToken')) {
      options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.cookie.get('accessToken')
        })
      };
      return this.http.post(this.securedUri + OavUrlConstants.CR, devisDTO, options);
    } else {
      return this.http.post(this.entryPublicUri + OavUrlConstants.CR, devisDTO, options);
    }

  }

  searchUnfinishedDevis(devis: DevisModelDTO) {
    return this.http.post(this.entryPublicUri + OavUrlConstants.SEARCH_UNFINISHED_DEVIS, devis);
  }

  getDevisById(id: string) {
    return this.http.get(this.securedUri + OavUrlConstants.GET + '/' + id);
  }

  updateDevisFormule(devis: DevisModelDTO) {
    return this.http.post(this.securedUri + OavUrlConstants.UPDATE_FORMULE_DEVIS, devis);
  }

  createRefBque(refObj) {
    return this.http.post(this.securedUri + OavUrlConstants.CREATE_REF_BANCAIRE, refObj);
  }
  createAudit(devis: DevisModelDTO) {
    return this.http.post(this.securedUri + OavUrlConstants.CREATE_AUDIT, devis);
  }
  createTemplate(devis: DevisModelDTO) {
    return this.http.post(this.securedUri + OavUrlConstants.CR_TEMPLATE, devis);
  }
  getDevisByIdUser(id) {
    return this.http.get(this.securedUri + OavUrlConstants.GET_BY_ID_USER + '/' + id);
  }

  saveOffers(devisDTO: DevisModelDTO) {
    return this.http.post(this.securedUri + OavUrlConstants.SAVE_OFFERS, devisDTO);
  }

  acceptQms(devisDTO: DevisModelDTO) {
    return this.http.post(this.securedUri + OavUrlConstants.ACCPET_QMS, devisDTO);
  }

  saveQms(devisDTO: DevisModelDTO) {
    return this.http.post(this.securedUri + OavUrlConstants.SAVE_QMS, devisDTO);
  }

  updateRefBque(refObj) {
    return this.http.post(this.securedUri + OavUrlConstants.UPDATE_REF_BANCAIRE, refObj);
  }

  updateDevisInfos(devis: DevisModelDTO) {
    return this.http.post(this.securedUri + OavUrlConstants.UPDATE_INFOS_DEVIS, devis);
  }

  createRefContrat(id: string) {
    return this.http.get(this.securedUri + OavUrlConstants.REF_CONTRAT + '/' + id);
  }

  updateDevisStatus(id: string, status: string) {
    return this.http.get(this.securedUri + OavUrlConstants.UPDATE_STATUS + '/' + id + '/' + status);
  }

  updateDevisProfil(devis: DevisModelDTO) {
    return this.http.post(this.securedUri + OavUrlConstants.UPDATE_PROFIL, devis);
  }

  updateDevisVente(devis: DevisVenteDTO) {
    return this.http.put(this.securedUri + OavUrlConstants.UPDATE_DEVIS_VENTE, devis);
  }

  sendActivationMail(id: string) {
    return this.http.get(this.securedUri + OavUrlConstants.SEND_ACTIVATION_MAIL + '/' + id);
  }

}

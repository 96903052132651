

export class TrakingParamConstants {

  /**************PAGE NAME ********SANTE******/
 // static readonly SANTE_DEVIS_MON_PROFIL_PAGENAME: string = 'sante::devis::mon_profil';
  static readonly SANTE_DEVIS_ETAPE1_MON_PROFIL_PAGENAME: string = 'sante::devis::etape1_mon_profil';
  static readonly SANTE_DEVIS_ETAPE2_BESOINS_PAGENAME: string = 'sante::devis::etape2_besoins';
  static readonly SANTE_DEVIS_ETAPE3_FORMULES_PAGENAME: string = 'sante::devis::etape3_formules';
  static readonly SANTE_DEVIS_ETAPE4_RECAPITULATIF_PAGENAME: string = 'sante::devis::etape4_recapitulatif';
  static readonly SANTE_SOUSCRIPTION_ETAPE1_MES_INFORMATION_PAGENAME: string = 'sante::souscription::etape1_mes_informations';
  static readonly SANTE_SOUSCRIPTION_ETAPE2_MANDAT_PAGENAME: string = 'sante::souscription::etape2_mandat';
  static readonly SANTE_SOUSCRIPTION_ETAPE3_PAIEMENT_CB_PAGENAME: string = 'sante::souscription::etape3_paiement_cb';
  static readonly SANTE_SOUSCRIPTION_ETAPE3_PAIEMENT_CB_CONFIRME_PAGENAME: string = 'sante::souscription::etape3_paiement_cb_confirme';
  static readonly SANTE_SOUSCRIPTION_ETAPE4_VOTRE_CONTRAT_PAGENAME: string = 'sante::souscription::etape4_votre_contrat';
  static readonly SANTE_DEVIS_CREATION_ESPACE_PAGENAME: string = 'sante::devis::creation_espace';
  /**************CLICK LABEL***********SANTE***/
  static readonly SANTE_FORMULES_DOCUMENTATION_CLICKLABEL: string = 'sante_formules_documentation';
  static readonly SANTE_FORMULES_RECEVOIR_DEVIS_MAIL_CLICKLABEL: string = 'sante_formules_recevoir_devis_mail';
  static readonly SANTE_FORMULES_VISUALISER_DEVIS_CLICKLABEL: string = 'sante_formules_visualiser_devis';
  static readonly SANTE_FORMULES_SOUSCRIRE_CLICKLABEL = 'sante_formules_souscrire';
  static readonly SANTE_RECAPITULATIF_ABANDONNER_CLICKLABEL = 'sante_recapitulatif_abandonner';
  static readonly SANTE_VALIDER_SIGNER_CLICKLABEL: string = 'sante_valider_signer';
  static readonly CONTACTER_CONSEILLER_HUB_CLICKLABEL: string = 'contacter_conseiller_hub';
  static readonly CONTACTER_CONSEILLER_IMMEDIATEMENT_CLICKLABEL: string = 'contacter_conseiller_immediatement';
  static readonly CONTACTER_CONSEILLER_SUR_RDV_CLICKLABEL: string = 'contacter_conseiller_sur_rdv';
  static readonly CONTACTER_CONSEILLER_VALIDER: string = 'contacter_conseiller_valider';
  /**************PAGE NAME ********ACDS******/
  static readonly ACDS_DEVIS_ETAPE_MON_PROFIL_PAGENAME: string = 'acds::devis::etape1_mon_profil';
  static readonly ACDS_DEVIS_ETAPE_BESOINS_PAGENAME: string = 'acds::devis::etape2_besoins';
  static readonly ACDS_DEVIS_ETAPE3_FORMULES_PAGENAME: string = 'acds::devis::etape3_formules';
  static readonly ACDS_DEVIS_ETAPE4_RECAPITULATIF_PAGENAME: string = 'acds::devis::etape4_recapitulatif';
  static readonly ACDS_SOUSCRIPTION_ETAPE0_QMS_PAGENAME: string = 'acds::souscription::etape0_qms';
  static readonly ACDS_SOUSCRIPTION_ETAPE0_QMS_NON_ELIGIBLE_PAGENAME: string = 'acds::souscription::etape0_qms_non-eligible';
  static readonly ACDS_SOUSCRIPTION_ETAPE1_MES_INFORMATIONS_PAGENAME: string = 'acds::souscription::etape1_mes_informations';
  static readonly ACDS_SOUSCRIPTION_ETAPE2_MANDAT_PAGENAME: string = 'acds::souscription::etape2_mandat';
  static readonly ACDS_SOUSCRIPTION_ETAPE4_VOTRE_CONTRAT_PAGENAME: string = 'acds::souscription::etape4_votre_contrat';
  static readonly ACDS_SOUSCRIPTION_ETAPE3_PAIEMENT_CB_CONFIRME_PAGENAME: string = 'acds::souscription::etape3_paiement_cb_confirme';
  /**************CLICK LABEL***********ACDS***/
  static readonly ACDS_FORMULES_DOCUMENTATION_CLICKLABEL: string = 'acds_formules_documentation';
  static readonly ACDS_VALIDER_SIGNER_CLICKLABEL: string = 'acds_valider_signer';
  static readonly ACCEDER_A_MES_SERVICES_CLICKLABEL: string = 'acceder_a_mes_services';
  //
  static readonly ACDS_SOUSCRIPTION_ETAPE3_PAIEMENT_PAGENAME: string = 'acds::souscription::etape3_paiement_cb';
  static readonly PROSPECT_DEVIS_LISTE_DEVIS_PAGENAME: string = 'prospect::devis::liste_devis';
  static readonly PROSPECT_DEVIS_LISTE_CONTRAT_PAGENAME: string = 'prospect::devis::liste_contrat';
  static readonly PROSPECT_DEVIS_LOGIN_PAGENAME: string = 'prospect::devis::login';
  static readonly PROSPECT_DEVIS_DASHBOARD: string = 'prospect::devis::dashboard';

  static readonly TRANSVERSE_COOKIES_PAGENAME: string = 'transverse::cookies';
  static readonly TRANSVERSE_MENTIONS_LEGALES_PAGENAME: string = 'transverse::mentions_legales';
  static readonly PROSPECT_CONFIRMATION_ENVOI_MAIL_REINITIALISATION_PAGENAME: string = 'prospect::confirmation_envoi_mail_reinitialisation';
 static readonly  PROSPECT_REINITIALISATION_PAGENAME: string = 'prospect::reinitialisation';
 static readonly  PROSPECT_CONFIRMATION_REINITIALISATION_PAGENAME: string = 'prospect::confirmation_reinitialisation';

}

export enum ClickType {
  ACTION = 'action',
  EXIT = 'exit',
  DOWNLOAD = 'download',
  NAVIGATION = 'navigation'
}



import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {CustomWindow} from '../../models/CustomWindow';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';

declare let window: CustomWindow;

@Injectable({
  providedIn: 'root'
})

export class TagCommanderSevice  {
  private mapPagesRemovedFromTab = new Map();
  private mapPages = new Map();
  private currentRoute: string;
  private path: string = '';
  private arrayOfPages: any[] = [];

  constructor(private cookies: CookieService, private httpClient: HttpClient,
              private router: Router, private activatedRoute: ActivatedRoute) {
  }
 // catching navigation pages
  trackingPage(page_name, canal) {
    if (page_name && canal) {
      window.tc_vars = {
        'env_work': 'prod', // valeur attendue 'prod' OU 'preprod'
        'page_name': page_name,
        'canal': canal // valeur attendue 'web' OU 'reseau' OU 'crc'
      };
    }
    console.log('window.tc_vars',  window.tc_vars );
  }
  // This method will be triggered when the "event catching" (click BTN)
  trackingClick(click_label, click_type) {
    window.tc_vars = window.tc_vars || {};
    if (click_label && click_type) {
      window.tc_vars['click_label'] = click_label;
      // 'navigation' OU 'exit' OU 'action' OU 'download'
      window.tc_vars['click_type'] = click_type;
      console.log('click_type', window.tc_vars['click_type']);
      console.log('click_label', window.tc_vars['click_label']);
      // @ts-ignore
      tC.event.click(this, {});
    }
  }

  // including Script
  reloadScript(pageName: any, renderer: any, elementRef: any): void {

    const script = renderer.createElement('script');
    script.id = pageName;
    script.src = 'https://cdn.tagcommander.com/4482/tc_IARD_24.js';
    script.onload = () => {
    };
    renderer.appendChild(elementRef.nativeElement, script);

  }
  // return the canal (CRC ,reseau,web) from the cookies
  getCanal() {
    let agentCRC, canal;
    if (this.cookies.get('agent')) {
     const agentData = JSON.parse(this.cookies.get('agent'));
      agentCRC = agentData.profil === '53' || agentData.profil === '33';
      if (agentCRC) {
        canal = 'CRC';
      } else {
        canal = 'reseau';
      }
    } else {
      canal = 'web';
    }
    return canal;
  }
}

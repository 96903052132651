import {ClientDataDTO} from './../../models/clientData';
import {UserService} from './../../services/user.service';
import {AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {EspaceProspectService} from '../../services/espace-prospect.service';
import {UserDTO} from '../../models/userDTO';
import {AgentDataDTO} from '../../models/agentData';
import {DevisStatus} from 'src/app/features/process-sante/models/devisStatus';
import {DevisModelDTO} from 'src/app/features/process-sante/models/devisDTO';
import {OavConstants} from 'src/app/shared/data/OavConstants';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {ModalService} from 'src/app/shared/services/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {SignatureModelDTO} from 'src/app/features/process-sante/models/signatureDTO';
import {NgxSpinnerService} from 'ngx-spinner';
import {DevisService} from 'src/app/features/process-sante/services/devis.service';
import {SignatureService} from 'src/app/features/process-sante/services/signature.service';
import {BdocDTO} from 'src/app/features/process-sante/models/bdocDTO';
import {Observable} from 'rxjs';
import {AgreementComponent} from 'src/app/shared/components/agreement/agreement.component';
import {FilterRequestList} from 'src/app/models/filterRequestList';
import {FilterResponse} from 'src/app/models/filterResponse';
import {FilterService} from 'src/app/features/process-sante/services/filter.service';
import {Person} from 'src/app/models/person';
import {AppConfig} from 'src/app/app.config';
import {GuardService} from 'src/app/shared/services/guard.service';
import {TrakingParamConstants} from '../../../shared/data/TrakingParamValues';
import {TagCommanderSevice} from '../../../shared/services/tag-Commander.sevice';

@Component({
  selector: 'app-espace-prospect',
  templateUrl: './espace-prospect.component.html',
  styleUrls: ['./espace-prospect.component.css']
})
export class EspaceProspectComponent implements OnInit, AfterViewInit {
  isAgent = false;
  client: any;
  produit: any;
  agentData = new AgentDataDTO();
  clientData = new ClientDataDTO();
  user = new UserDTO();
  listDevis: any = [];
  listDevisDisplay: any = [];
  listBeneficiaires: any = [];
  listContracts: any = [];
  usurpationURL;
  dismissible = true;
  Welcomemsg;
  externalId;
  devisEncours = false;
  prospectError = false;
  incompleteDevis = new DevisModelDTO();
  WelcomeUserAlerts: any =
    {
      type: 'default',
      msg: ''
    };
  message1 = 'Bienvenue Mme ';
  message2 = ', cet espace vous permet d';
  message3 = '\'';
  message4 = 'effectuer le suivi de vos devis SANTE';

  modeDevis = false;
  modeContats = false;
  modeConsultDevisSante = false;
  modeConsultDevisAcds = false;
  idDevis: number;
  bdocDto = new BdocDTO();
  devisId;
  userId: any;
  nbDevis = 0;
  dataLoaded = false;
  filter;
  enablePayment = false;
  isContrat = false;
  showAgentPaymentMsg = false;
  private canal: string;

  @HostListener('click') onMouseClick() {
    if (this.prospectError) {
      this.prospectError = false;
    }
  }

  constructor(private cookies: CookieService, private espaceProspectService: EspaceProspectService, private spinner: NgxSpinnerService,
              private devisService: DevisService, private signatureService: SignatureService, private userService: UserService,
              private guardService: GuardService, private router: Router, private modalService: ModalService,
              private translate: TranslateService, private readonly elementRef: ElementRef, private renderer: Renderer2,
              private filterService: FilterService, private tagCommanderSevice: TagCommanderSevice) {
    this.showCallBackComponent();
  }

  ngOnInit() {
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.PROSPECT_DEVIS_DASHBOARD, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.PROSPECT_DEVIS_DASHBOARD, this.renderer, this.elementRef);
    if (this.cookies.get(OavConstants.CURRENT_SCREEN) !== OavConstants.ECRAN_ESPACE_PROSPECT ||
      !this.cookies.get(OavConstants.CURRENT_SCREEN)) {
      this.checkForCurrentScreen();
    }
    this.spinner.show();
    this.cookies.delete('signType');
    this.cookies.delete('_finishProcess');
    if (this.cookies.get(OavConstants.CURRENT_SCREEN) !== OavConstants.ECRAN_INFORMATIONS_SANTE) {
      this.cookies.delete(OavConstants.DEVIS);
    }
    if (this.cookies.get('client') && JSON.parse(this.cookies.get('client')) != null) {
      this.clientData = JSON.parse(this.cookies.get('client'));
      this.externalId = this.clientData.idExterne;
    }
    if (this.cookies.get('agent') && JSON.parse(this.cookies.get('agent')) != null) {
      this.agentData = JSON.parse(this.cookies.get('agent'));
      this.isAgent = true;
    }
    this.userId = this.clientData.clientUid;
    if (this.externalId && this.externalId !== undefined) {
      this.getUserByIdExterne();
    } else if (this.userId) {
      this.getUserDetailsById();
    }
    this.produit = this.cookies.get('produit');
    if ((this.userId == null || this.userId === undefined) && (this.externalId == null || this.externalId === undefined)) {
      this.router.navigate([OavConstants.LOGIN_URL]);
    }
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => {
    });
  }

  private getUserByIdExterne() {
    this.userService.getUserByIdExterne(this.externalId).subscribe((user: UserDTO) => {
      if (user != null) {
        this.initUser(user);
      }
    }, error => {
      this.spinner.hide();
      console.error('error when get user by id externe');
    });
  }

  private getUserDetailsById() {
    this.userService.getUserDetailsById(this.userId).subscribe((user: UserDTO) => {
      this.clientData.nom = user.firstName;
      this.clientData.prenom = user.lastName;
      this.clientData.clientUid = user.activationId;
      this.cookies.set('client', JSON.stringify(this.clientData), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.initUser(user);
    }, error => {
      this.spinner.hide();
      console.error('error when get user details by id');
    });
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  showCallBackComponent() {
    document.getElementById('sectionDevis').style.display = 'block';
  }

  initUser(user) {
    this.user = user;
    this.Welcomemsg = this.message1 + this.user.lastName + ' ' + this.user.firstName + this.message2 + this.message3 + this.message4;
    this.loadWelcomeMsg();
    this.loadAyantDroit(this.user.id);
  }


  loadWelcomeMsg() {
    this.WelcomeUserAlerts.msg = this.Welcomemsg;
  }

  onClosed(dismissedAlert: any): void {
    this.WelcomeUserAlerts = this.WelcomeUserAlerts.filter(alert => alert !== dismissedAlert);
  }

  loadAyantDroit(idUser) {
    this.espaceProspectService.getBeneficiaireByIdUser(idUser).subscribe(result => {
      this.listBeneficiaires = result;
      this.loadContrats(this.user.id);
    }, error => {
      this.spinner.hide();
      console.error('error when get ayant droit');
    });
  }

  loadDevisClient(idUser) {
    this.espaceProspectService.getDevisByIdUser(idUser).subscribe(result => {
      this.listDevis = result;
      this.listDevisDisplay = this.listDevis.filter(e => (e.status === 'Devis' ||
        e.status === 'Abandonne' ||  e.status === 'Refuse' ||
        (e.status === 'A signer' && this.expired(e.dateFinDevis)) ||
        (e.status === 'Information' && this.expired(e.dateFinDevis)) ||
        (e.status === 'Mandat' && this.expired(e.dateFinDevis))
      ));
      this.listDevisDisplay.sort((a, b) => {
        if (moment(a.dateDevis) > moment(b.dateDevis)) {
          return -1;
        } else if (moment(a.dateDevis) < moment(b.dateDevis)) {
          return 1;
        } else {
          return 0;
        }
      });
      this.nbDevis = this.listDevis.filter(e => (e.status === 'Devis' && !this.expired(e.dateFinDevis))).length;
      this.getIncompleteDevis(this.listDevis);
      this.dataLoaded = true;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.error('error when load devis list');
    });
  }

  loadContrats(idUser) {
    this.espaceProspectService.getContratsByIdUser(idUser).subscribe(result => {
      if (result != null) {
        this.listContracts = result;
        if (this.listContracts && this.listContracts.length > 0) {
          for (const contrat of this.listContracts) {
            if (contrat.baseURL) {
              this.usurpationURL = contrat.baseURL;
              break;
            }
          }
        }
      }
      this.loadDevisClient(this.user.id);
    }, error => {
      this.spinner.hide();
      console.error('error when get contrats list');
    });
  }

  getIncompleteDevis(devisList) {
    for (const devis of devisList) {
      if ((devis.status === DevisStatus.INFORMATION || devis.status === DevisStatus.A_SIGNER || devis.status === DevisStatus.MANDAT
        || devis.status === DevisStatus.PENDING || devis.status === DevisStatus.REJCTED || devis.status === DevisStatus.A_PAYER)
        && !this.expired(devis.dateFinDevis)) {
        this.incompleteDevis = devis;
        this.isEnablePayment(devis);
        this.isContrat = devis.status === DevisStatus.A_PAYER;
        this.devisEncours = true;
        if (devis.status === DevisStatus.PENDING) {
          this.filter = 'PENDING';
          this.filterUsers(devis);
        }
        if (devis.status === DevisStatus.REJCTED) {
          this.filter = 'TRUE';
        }
        if (devis.formule.produit === OavConstants.PRODUIT_SANTE || devis.status === DevisStatus.PENDING ||
          devis.status === DevisStatus.REJCTED) {
          break;
        }
      }
    }
  }

  filterUsers(incompleteDevis) {
    this.spinner.show();
    this.devisService.getDevisById(incompleteDevis.idDevis).subscribe((data) => {
      const filterRequestList = new FilterRequestList();
      this.prepareFilterRequestList(filterRequestList, data);
      this.filterService.filterUsers(filterRequestList).subscribe((result: FilterResponse) => {
        if (result.assetFreezing === 'TRUE') {
          this.filter = 'TRUE';
          this.updateDevisStatus(DevisStatus.REJCTED, incompleteDevis.idDevis);
          this.incompleteDevis.status = DevisStatus.REJCTED;
        } else if (result.assetFreezing === 'FALSE') {
          this.filter = 'FALSE';
          this.updateDevisStatus(DevisStatus.MANDAT, incompleteDevis.idDevis);
          this.incompleteDevis.status = DevisStatus.MANDAT;
        } else if (result.assetFreezing === 'PENDING') {
          this.spinner.hide();
          this.filter = 'PENDING';
        } else {
          this.spinner.hide();
        }
      }, error => {
        this.spinner.hide();
        console.error('error when filter users');
      });
    });
  }

  updateDevisStatus(status, idDevis) {
    this.espaceProspectService.updateDevisStatus(idDevis, status).subscribe(rep => {
      this.spinner.hide();
    }, error => {
      console.error('error when update devis status');
    });
  }

  private prepareFilterRequestList(filterRequestList: FilterRequestList, devis) {
    if (this.cookies.get('agent')) {
      filterRequestList.userId = JSON.parse(this.cookies.get('agent')).idRHAgent;
    }
    for (let i = 0; i < devis.beneficiaires.length; i++) {
      if (devis.beneficiaires[i].typeBenef === 1) {
        filterRequestList.person.push(this.createPersonFromAdherent(devis, i));
      }
      if (devis.beneficiaires[i].typeBenef === 2 || devis.beneficiaires[i].typeBenef === 3) {
        filterRequestList.person.push(this.createPersonFromConjointOrEnfant(devis.beneficiaires[i], devis.souscripteur));
      }
    }
  }

  private createPersonFromAdherent(devis, i) {
    const person = new Person();
    person.address.addressSupplement = devis.adressePostale.complement;
    person.address.city.name = devis.adressePostale.ville;
    person.address.city.zipCode = devis.adressePostale.codePostale;
    person.address.country.code = 'FR';
    person.address.country.name = 'France';
    person.address.place = devis.adressePostale.lieuDit;
    person.birthCity = devis.beneficiaires[i].villeNaissance;
    person.birthDepartmentCode = devis.beneficiaires[i].paysNaissance;
    person.birthDate = devis.beneficiaires[i].dateNaissance;
    person.firstName = devis.beneficiaires[i].prenomBenef;
    person.lastName = devis.beneficiaires[i].nomBenef;
    person.gender = devis.beneficiaires[i].civilite ? 'F' : 'M';
    person.idPersonPartner = (devis.souscripteur.id.toString().substring(0, 10)
      + devis.beneficiaires[i].nomBenef.replace(/\s/g, '').substring(0, 10) +
      devis.beneficiaires[i].prenomBenef.replace(/\s/g, '').substring(0, 20) +
      devis.beneficiaires[i].dateNaissance.toString().substring(0, 10)).toUpperCase();
    person.surname = devis.beneficiaires[i].nomJeuneFille ? devis.beneficiaires[i].nomJeuneFille : devis.beneficiaires[i].nomBenef;
    return person;
  }

  private createPersonFromConjointOrEnfant(benef, souscripteur) {
    const person = new Person();
    person.address.country.code = 'FR';
    person.address.country.name = 'France';
    person.birthCity = benef.villeNaissance;
    person.birthCountryCode = benef.paysNaissance;
    person.birthDate = benef.dateNaissance.toString();
    person.firstName = benef.prenomBenef;
    person.lastName = benef.nomBenef;
    person.gender = benef.sexe ? 'F' : 'M';
    person.idPersonPartner = (souscripteur.id.toString().substring(0, 10)
      + benef.nomBenef.replace(/\s/g, '').substring(0, 10) + benef.prenomBenef.replace(/\s/g, '').substring(0, 20) +
      benef.dateNaissance.toString().substring(0, 10)).toUpperCase();
    person.surname = benef.nomJeuneFille ? benef.nomJeuneFille : benef.nomBenef;
    return person;
  }

  SupprimerDevis(idDevis) {
    let btnOui: string;
    let btnNon: string;
    let confirmation: string;
    let confirmMsg: string;
    this.translate.get('espaceProspect.oui').subscribe((res: string) => {
      btnOui = res;
    });
    this.translate.get('espaceProspect.non').subscribe((res: string) => {
      btnNon = res;
    });
    this.translate.get('espaceProspect.confirmHeader').subscribe((res: string) => {
      confirmation = res;
    });
    this.translate.get('espaceProspect.confirmMsg').subscribe((res: string) => {
      confirmMsg = res;
    });
    this.modalService.confirm(
      confirmation,
      confirmMsg,
      [btnOui, btnNon],
      'danger')
      .subscribe((answer) => {
        if (answer === 'Oui' || answer === 'Yes') {
          this.espaceProspectService.updateDevisStatus(idDevis, 'Abandonne').subscribe(rep => {
            this.listDevisDisplay = this.listDevisDisplay.filter(e => e.idDevis !== idDevis);
            this.nbDevis = this.listDevisDisplay.filter(e => (e.status === 'Devis')).length;
            this.loadDevisClient(this.user.id);
          });
        }
      });
  }

  abondanCurrentDevis(incompleteDevis, listDevisDisplay, listDevis) {
    this.incompleteDevis = incompleteDevis;
    this.listDevis = listDevis;
    this.listDevisDisplay = listDevisDisplay;
    this.spinner.show();
    this.espaceProspectService.updateDevisStatus(this.incompleteDevis.idDevis, 'Abandonne').subscribe(rep => {
      this.listDevisDisplay = this.listDevisDisplay.filter(e => e.idDevis !== this.incompleteDevis.idDevis);
      this.listDevis = this.listDevis.filter(e => e.idDevis !== this.incompleteDevis.idDevis);
      this.incompleteDevis = null;
      this.devisEncours = false;
      this.getIncompleteDevis(this.listDevis);
      this.spinner.hide();
      if (listDevisDisplay.length > 0 || this.incompleteDevis) {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.ESPACE_PROSPECT]);
      } else {
        this.router.navigate(['/sante']);
      }
    }, error => {
      this.spinner.hide();
    });
  }

  ConsulterDevis(devis) {
    const object = {
      'idDevis': devis.idDevis, 'expired': this.expired(devis.dateFinDevis),
      'produit': (devis.formule) ? devis.formule.produit : OavConstants.PRODUIT_SANTE,
      'abandonned': devis.status === 'Abandonne'
    };
    this.cookies.set('devis', devis.idDevis, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.set('resumeDevis', JSON.stringify(object), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_DEVIS
      , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.RESUME_DEVIS]);
  }

  consulterContrat(idDevis, produit, baseUrl) {
    if (baseUrl !== null && baseUrl !== undefined) {
      if (this.isAgent) {
        setTimeout(() => {
          this.prospectError = true;
        });
      } else {
        this.accessPortailRedirect();
      }
    } else {
      const object = {'idDevis': idDevis, 'produit': (produit === 'SANTE') ? OavConstants.PRODUIT_SANTE : OavConstants.PRODUIT_ACDS};
      this.cookies.set('resumeContrat', JSON.stringify(object), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_CONTRAT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.RESUME_CONTRAT]);
    }

  }

  viewAllDevis() {
    sessionStorage.setItem('listDevis', JSON.stringify(this.listDevisDisplay));
    this.cookies.set('prospect', JSON.stringify(this.user), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_LIST_DEVIS,
      undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.LIST_DEVIS]);
  }

  viewAllContracts() {
    sessionStorage.setItem('listContrats', JSON.stringify(this.listContracts));
    this.cookies.set('usurpationURL', this.usurpationURL, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_LIST_CONTRAT,
      undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.LIST_CONTRAT]);
  }

  accessPortailRedirect() {
    window.open(this.usurpationURL, 'blank');
  }

  navToSante() {
    this.cookies.set('produit', OavConstants.PRODUIT_SANTE, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.delete('devis');
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
  }

  navToAcds() {
    this.cookies.set('produit', OavConstants.PRODUIT_ACDS, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.delete('devis');
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    this.router.navigate([OavConstants.PROFIL_ACDS_URL]);
  }

  capitalize(string) {
    if (string) {
      return string[0].toUpperCase() + string.slice(1);
    } else {
      return string;
    }
  }

  continueMandat(devis) {
    this.cookies.set(OavConstants.DEVIS, String(devis.idDevis), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    if (devis.formule.produit === OavConstants.PRODUIT_SANTE) {
      this.cookies.set('previous', 'mes informations', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE);
      this.router.navigate([OavConstants.MANDAT_SIGNATURE_SANTE_URL]);
    } else {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_ACDS,
        undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.MANDAT_SIGNATURE_ACDS_URL]);
    }
  }

  continueInformation(devis) {
    this.cookies.set(OavConstants.DEVIS, String(devis.idDevis), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    if (devis.formule.produit === OavConstants.PRODUIT_SANTE) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_FORMULE_SANTE,
        undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.INFORMATION_SANTE_URL]);
    } else {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_FORMULE_ACDS
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.INFORMATION_ACDS_URL]);
    }
  }

  continueSignature(devis) {
    this.spinner.show();
    this.cookies.set(OavConstants.DEVIS, String(devis.idDevis), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.devisService.getDevisById(devis.idDevis).subscribe((devisdto: DevisModelDTO) => {
      if ((devisdto.qmsAccepted || devis.formule.produit === OavConstants.PRODUIT_SANTE) && !this.cookies.check('agent')) {
        this.sign(devisdto, devis);
      } else {
        if (!devisdto.qmsAccepted && !this.cookies.get('agent') && devis.formule.produit === OavConstants.PRODUIT_ACDS) {
          this.cookies.set(OavConstants.QMS, '1');
          this.openAgreement(devisdto).subscribe((returnedObject) => {
            if (returnedObject) {
              this.spinner.show();
              this.devisService.acceptQms(devisdto).subscribe(() => {
                this.cookies.delete(OavConstants.QMS);
                this.sign(devisdto, devis);
              }, error => {
                console.error('error when accepting QMS');
              });
            }
          }, error => {
            console.error('error when load QMS');
          });
        } else if (this.cookies.get('agent')) {
          if (devis.formule.produit === OavConstants.PRODUIT_SANTE) {
            this.cookies.set('previous', 'mes informations', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
            this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE);
            this.router.navigate([OavConstants.MANDAT_SIGNATURE_SANTE_URL]);
          } else {
            this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_ACDS,
              undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
            this.router.navigate([OavConstants.MANDAT_SIGNATURE_ACDS_URL]);
          }
        }
      }
    }, error => {
      console.error('error when get devis by id');
      this.spinner.hide();
    });
  }

  continuePaiement(devis) {
    if (this.isAgent) {
      this.showAgentPaymentMsg = true;
    } else {
      this.cookies.set(OavConstants.DEVIS, String(devis.idDevis), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set('modePay', 'CB', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      if (devis.formule.produit === OavConstants.PRODUIT_SANTE) {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE,
          undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.PAIEMENT_SANTE_URL]);
      } else {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_ACDS,
          undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.PAIEMENT_ACDS_URL]);
      }
    }
  }

  passerAuQms(devis) {
    if (!this.isAgent) {
      this.spinner.show();
      this.cookies.set(OavConstants.DEVIS, String(devis.idDevis), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.QMS,
        undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.QMS_ACDS_URL]);
    }
  }

  dateDefaultFormat(input) {
    return moment(input).format('DD/MM/YYYY');
  }

  private sign(devisdto, dev) {
    if (dev.formule.produit === OavConstants.PRODUIT_SANTE) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE,
        undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    } else {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_ACDS,
        undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    }
    if (dev.refBancaire.premiereCotisation === 'CB') {
      this.cookies.set('modePay', 'CB', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    } else if (dev.refBancaire.premiereCotisation === 'PRELEVEMENT') {
      this.cookies.set('modePay', 'PRELEVEMENT', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    }
    const signatureRequest = new SignatureModelDTO();
    this.devisService.getDevisById(devisdto.idDevis).subscribe((devis: DevisModelDTO) => {
      this.initSignatureObject(signatureRequest, devis);
      this.devisId = this.cookies.get('devis');
      this.bdocDto.devisId = devisdto.idDevis;
      this.signatureService.sign(signatureRequest, this.bdocDto).subscribe(data => {
        this.createRefContrat(devisdto.idDevis);
        this.spinner.hide();
        const signData: any = data;
        window.location.href = signData.actionUrl;
      }, error => {
        this.spinner.hide();
      });
    }, error => {
      this.spinner.hide();
    });
  }

  private initSignatureObject(signatureRequest, devis) {
    signatureRequest.titulaire = devis.refBancaire.titulaire;
    signatureRequest.iban = devis.refBancaire.iban;
    signatureRequest.codeBic = devis.refBancaire.bic;
    signatureRequest.modeenvoie = devis.modeEnvoi;
    signatureRequest.currentAdherent = devis.souscripteur;
    signatureRequest.idDevis = devis.idDevis;
    signatureRequest.email = devis.souscripteur.contact.email;
    signatureRequest.nom = devis.souscripteur.nom;
    signatureRequest.prenom = devis.souscripteur.prenom;
  }

  createRefContrat(idDevis) {
    this.devisService.createRefContrat(idDevis).subscribe(data => {
    }, error => {
      this.spinner.hide();
    });
  }

  expired(dateFinDevis) {
    if (moment().isAfter(moment(dateFinDevis))) {
      return true;
    }
    return false;
  }

  continueAdhesion(qmsaccepted, idDevis, produit) {
    this.cookies.set(OavConstants.DEVIS, idDevis, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    if (produit === OavConstants.PRODUIT_ACDS) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_FORMULE_ACDS
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        if (this.isAgent) {
          this.router.navigate([OavConstants.INFORMATION_ACDS_URL]);
        } else {
        if (qmsaccepted != null && qmsaccepted !== undefined && qmsaccepted === true) {
        } else {
          this.router.navigate([OavConstants.QMS_ACDS_URL]);
        }}
    } else if (produit === OavConstants.PRODUIT_SANTE) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_FORMULE_SANTE,
        undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.INFORMATION_SANTE_URL]);
    }
  }

  back() {
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_AGENT
      , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.ESPACE_AGENT]);
  }

  uppercase(str: string) {
    if (str !== null && str !== undefined) {
      return str.toUpperCase();
    }
  }

  openAgreement(devis): Observable<any> {
    return this.modalService.openPopupSubscriber(AgreementComponent, {devis: devis}, 'modal-agreement', true, false, true);
  }

  trackByFn(index, item) {
    return index;
  }

  private isEnablePayment(devis: DevisModelDTO): void {
    const testDate = new Date().setDate(new Date().getDate() + 33);
    this.enablePayment = new Date(devis.dateAdhesion).getTime() <= new Date(testDate).getTime();
  }
}

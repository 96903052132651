import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { OavUrlConstants } from 'src/app/shared/data/OavURLConstants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssureurService {

  privateUri = AppConfig.securedEndpoint + OavUrlConstants.ASSUREUR_ENDPOINT;

  constructor(private http: HttpClient) { }

  create(assureur) {
    return this.http.post(this.privateUri + OavUrlConstants.CR, assureur);
  }
}

import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {DevisService} from 'src/app/features/process-sante/services/devis.service';
import {SignatureService} from 'src/app/features/process-sante/services/signature.service';
import {SignatureModelDTO} from 'src/app/features/process-sante/models/signatureDTO';
import {DevisModelDTO} from 'src/app/features/process-sante/models/devisDTO';
import {PaymentOptions} from 'src/app/features/process-sante/models/paymentOptions';
import {ReferenceBancaire} from 'src/app/features/process-sante/models/referenceBancaire';
import {OavConstants} from 'src/app/shared/data/OavConstants';
import {SouscripteurModelDTO} from '../../models/souscripteurModelDTO';
import {VilleService} from '../../services/ville.service';
import {Router} from '@angular/router';
import {DevisStatus} from '../../models/devisStatus';
import {BdocDTO} from '../../models/bdocDTO';
import {BdocService} from '../../services/bdoc.service';
import {CookieService} from 'ngx-cookie-service';
import {DevisVenteDTO} from '../../models/devisVenteDTO';
import {GuardService} from 'src/app/shared/services/guard.service';
import {AppConfig} from 'src/app/app.config';
import * as IBAN from 'iban';
import {FormulesService} from '../../services/formules.service';
import {ClickType, TrakingParamConstants} from '../../../../shared/data/TrakingParamValues';
import {ModalService} from '../../../../shared/services/modal.service';
import {TagCommanderSevice} from '../../../../shared/services/tag-Commander.sevice';


@Component({
  selector: 'app-mandat-signature',
  templateUrl: './mandat-signature.component.html',
  styleUrls: ['./mandat-signature.component.css']
})
export class MandatSignatureComponent implements OnInit {
  devisId;
  paymentOptions = new PaymentOptions();
  validation = false;
  tryToSubmitForm = false;
  helpText;
  items = [
    {
      label: 'Mes informations'
    },
    {
      label: 'Mon mandat de prélèvement'
    },
    {
      label: 'Ma signature'
    },
    {
      label: 'Mon contrat'
    }
  ];
  activeIndex = 1;
  toggleCodeValue;
  showCompte;
  showErrorCode;
  villesSouscripteur;
  souscripteur = new SouscripteurModelDTO();
  showSMS = false;
  bdocDto = new BdocDTO();
  toggleSignValue;
  agentData;
  devis;
  formuleLabel;
  valDevis;
  showSignType = false;
  agentCRC;
  refDevis;
  emailAdress;
  name;
  lastname;
  civilite;
  finishProcess = false;
  ibanFocusout = false;
  bicFocusout = false;
  produit;
  data: any;
  signType = false;
  type;
  postalAddress;
  dataLoaded = false;
  paysList = [];
  acceptCG = false;
  showPayment = false;
  canUseCB = false;
  isFakeEmail = false;
  isToPay = false;
  private canal: string;
  constructor(private cookies: CookieService, private spinner: NgxSpinnerService, private devisService: DevisService,
    private signatureService: SignatureService, private bdocService: BdocService, private villeService: VilleService,
    private router: Router, private guardService: GuardService, private formuleService: FormulesService,
    private readonly elementRef: ElementRef, private renderer: Renderer2, private modalService: ModalService,
    private tagCommanderSevice: TagCommanderSevice) { }

  ngOnInit() {
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.SANTE_SOUSCRIPTION_ETAPE2_MANDAT_PAGENAME, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.SANTE_SOUSCRIPTION_ETAPE2_MANDAT_PAGENAME, this.renderer, this.elementRef);
    this.cookies.delete('modePay', undefined, undefined);
    this.checkForCurrentScreen();
    window.scroll(0, 0);
    this.spinner.show();
    if (this.cookies.get('agent')) {
      this.agentData = JSON.parse(this.cookies.get('agent'));
      this.agentCRC = this.agentData.profil === '53' || this.agentData.profil === '33';
    } else {
      this.toggleSignValue = true;
    }
    this.type = 'Assurance Santé';
    this.devisId = this.cookies.get(OavConstants.DEVIS);
    this.getProcessAdherant();
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => { });
  }

  private initPaymentsOptions(devis) {
    const currentScreen = this.cookies.get(OavConstants.CURRENT_SCREEN);
    this.paymentOptions.idRefBancaire = devis.refBancaire && devis.refBancaire.id ? devis.refBancaire.id : '';
    this.paymentOptions.nom = devis.souscripteur.nom;
    this.paymentOptions.prenom = devis.souscripteur.prenom;
    this.paymentOptions.jourPrelevement = devis.refBancaire && devis.refBancaire.jourPrelevement ?
      devis.refBancaire.jourPrelevement : '';
    this.paymentOptions.codePostale = devis.refBancaire && devis.refBancaire.codePostale &&
      (!currentScreen || currentScreen !== OavConstants.ECRAN_INFORMATIONS_SANTE) ? devis.refBancaire.codePostale :
      devis.souscripteur.adressePostale.codePostale;
    this.paymentOptions.adresse = devis.refBancaire && devis.refBancaire.adresseLigne1 &&
      (!currentScreen || currentScreen !== OavConstants.ECRAN_INFORMATIONS_SANTE) ? devis.refBancaire.adresseLigne1 :
      devis.souscripteur.adressePostale.adresseLigne1;
    this.paymentOptions.titulaire = devis.refBancaire && devis.refBancaire.titulaire &&
      (!currentScreen || currentScreen !== OavConstants.ECRAN_INFORMATIONS_SANTE) ? devis.refBancaire.titulaire :
      devis.souscripteur.prenom + ' ' + devis.souscripteur.nom;
    this.paymentOptions.iban = devis.refBancaire && devis.refBancaire.iban ? devis.refBancaire.iban : '';
    this.paymentOptions.bic = devis.refBancaire && devis.refBancaire && devis.refBancaire.bic ? devis.refBancaire.bic : '';
    this.paymentOptions.modeenvoie = 'SMS';
    this.paymentOptions.premiereCotisation = devis.refBancaire && devis.refBancaire.premiereCotisation ?
      devis.refBancaire.premiereCotisation : null;
    this.paymentOptions.titulairePrest = devis.refBancaire && devis.refBancaire.titulairePrest ? devis.refBancaire.titulairePrest : '';
    this.paymentOptions.ibanPrest = devis.refBancaire && devis.refBancaire.ibanPrest ? devis.refBancaire.ibanPrest : '';
    this.paymentOptions.bicPrest = devis.refBancaire && devis.refBancaire.bicPrest ? devis.refBancaire.bicPrest : '';
    this.paymentOptions.modePaiement = devis.modePaiement;
    this.convertIBANToUpperCase(this.paymentOptions.iban);
    this.converIBANPrestToUpperCase(this.paymentOptions.ibanPrest);
    this.paymentOptions.ville = devis.refBancaire && devis.refBancaire.ville &&
      (!currentScreen || currentScreen !== OavConstants.ECRAN_INFORMATIONS_SANTE) ? devis.refBancaire.ville :
      devis.souscripteur.adressePostale.ville;
    this.villeService.getByCodePostal(this.paymentOptions.codePostale).subscribe(data => {
      this.villesSouscripteur = data;
    }, error => {
      this.spinner.hide();
      console.error('error when get assure villes');
    });
  }

  private initDataToDisplay(devis) {
    this.isFakeEmail = devis.user.fakeEmail;
    this.civilite = devis.souscripteur.sexe ? 'Madame' : 'Monsieur';
    this.name = devis.souscripteur.prenom;
    this.lastname = devis.souscripteur.nom;
    this.emailAdress = devis.souscripteur.contact.email;
    this.postalAddress = (devis.souscripteur.adressePostale.adresseLigne1 ? devis.souscripteur.adressePostale.adresseLigne1 : '') +
      ' ' + (devis.souscripteur.adressePostale.complement ? devis.souscripteur.adressePostale.complement : '') +
      ' ' + (devis.souscripteur.adressePostale.lieuDit ? devis.souscripteur.adressePostale.lieuDit : '') +
      ' ' + (devis.souscripteur.adressePostale.codePostale ? devis.souscripteur.adressePostale.codePostale : '')
      + ' ' + (devis.souscripteur.adressePostale.ville ? devis.souscripteur.adressePostale.ville : '');
  }

  getProcessAdherant() {
    this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
      this.devis = devis;
      this.isToPay = this.devis.status === DevisStatus.A_PAYER;
      this.isShowPayment(devis);
      this.finishProcess = this.cookies.get('_finishProcess') === 'true';
      this.initDataToDisplay(devis);
      if (!this.devis.vente && this.agentData) {
        this.setDefaultVente();
      }
      this.valDevis = this.devis.valDevis;
      this.formuleLabel = this.devis.formule.formuleLabel;
      if (devis.status === 'En gestion') {
        this.cookies.set('previous', 'mandat', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.cookies.delete(OavConstants.CURRENT_SCREEN);
        this.cookies.delete(OavConstants.DEVIS);
        this.router.navigate([OavConstants.LOGIN_URL]);
      } else {
        this.initPaymentsOptions(devis);
        this.showCompte = devis.refBancaire && (devis.refBancaire.titulairePrest ||
          devis.refBancaire.bicPrest || devis.refBancaire.ibanPrest) && !this.sameRefBancaire(devis.refBancaire);
        if (devis.refBancaire) {
          this.toggleCodeValue = devis.refBancaire != null &&
            (devis.refBancaire.titulairePrest != null || devis.refBancaire.bicPrest != null || devis.refBancaire.ibanPrest != null)
            && !this.sameRefBancaire(devis.refBancaire);
        }
        if ((devis.typeSignature === OavConstants.TYPE_SIGNATURE_MANUAL && this.agentData) || devis.user.fakeEmail) {
          this.toggleSignValue = false;
        }
        if (devis.typeSignature === OavConstants.TYPE_SIGNATURE_AUTO) {
          this.toggleSignValue = true;
          this.signAuto();
        }
        this.souscripteur = devis.souscripteur;
        this.souscripteur.adressePostale.ville = devis.souscripteur.adressePostale.ville;
        this.helpText = devis.modePaiement;
        if (devis.souscripteur.contact.telMobile.startsWith('06') || devis.souscripteur.contact.telMobile.startsWith('07')) {
          this.showSMS = true;
        }
      }
      this.dataLoaded = true;
      this.spinner.hide();
    }, error => {
      console.error('error when get devis by id');
      this.spinner.hide();
    });
  }

  private isShowPayment(devis: DevisModelDTO): void {
    const testDate = new Date().setDate(new Date().getDate() + 33);
    this.showPayment = this.toggleSignValue &&
      new Date(('' + devis.dateAdhesion).substring(0, 10)).getTime() <= new Date(testDate).getTime();
  }

  private sameRefBancaire(refBancaire) {
    return refBancaire.titulaire === refBancaire.titulairePrest && refBancaire.bicPrest === refBancaire.bic &&
      refBancaire.ibanPrest === refBancaire.iban;
  }

  changeViewTab(paymentOptions) {
    this.tryToSubmitForm = true;
    if (this.validForm(paymentOptions)) {
      console.log('SANTE_VALIDER_SIGNER_CLICKLABEL ::', TrakingParamConstants.SANTE_VALIDER_SIGNER_CLICKLABEL);
      this.tagCommanderSevice.trackingClick(TrakingParamConstants.SANTE_VALIDER_SIGNER_CLICKLABEL, ClickType.EXIT);
      if (this.isToPay && paymentOptions.premiereCotisation === 'CB') {
        this.cookies.set('modePay', 'CB', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        if (this.devis && this.devis.refBancaire.premiereCotisation !== 'CB') {
              this.spinner.show();
              const refbque = this.devis.refBancaire;
              refbque.premiereCotisation = 'CB';
              this.devisService.updateRefBque(refbque).subscribe(() => {
                this.spinner.hide();
            this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE
              , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
            this.router.navigate([OavConstants.PAIEMENT_SANTE_URL]);
          });
        } else {
          this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE
            , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          this.router.navigate([OavConstants.PAIEMENT_SANTE_URL]);
        }
      } else {
        this.createRefBque(paymentOptions);
      }
    }
  }

  private sendFinalisationMail(signatureRequest) {
    this.signatureService.sendMailFinalisation(signatureRequest, this.bdocDto).subscribe(data => { },
      error => {
        this.spinner.hide();
        console.error('error when sending finalisation mail');
      });
  }

  private createSignPackge(signatureRequest, paymentOptions) {
    this.signatureService.sign(signatureRequest, this.bdocDto).subscribe(data => {
      const signData: any = data;
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      window.location.href = signData.actionUrl;
      this.spinner.hide();
    }, error => {
      console.error('error when creating sign pack');
      this.spinner.hide();
    });
  }


  private sign(paymentOptions) {
    const signatureRequest = new SignatureModelDTO();
    this.initSignatureObject(signatureRequest, paymentOptions);
    this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
      signatureRequest.currentAdherent = devis.souscripteur;
      signatureRequest.idDevis = devis.idDevis;
      signatureRequest.email = devis.souscripteur.contact.email;
      signatureRequest.nom = devis.souscripteur.nom;
      signatureRequest.prenom = devis.souscripteur.prenom;
      this.devisId = this.cookies.get('devis');
      this.bdocDto.devisId = devis.idDevis;
      this.cookies.set('modePay', devis.refBancaire.premiereCotisation,
        undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      if (this.agentData) {
        signatureRequest.sendToUser = true;
        this.sendFinalisationMail(signatureRequest);
        this.updateDevisVente();
        this.spinner.hide();
      } else {
        this.createSignPackge(signatureRequest, paymentOptions);
      }
    }, error => {
      console.error('error when get devis by id');
      this.spinner.hide();
    });
  }

  private setDefaultVente() {
    if (this.agentData.profil.toString() === '24') {
      this.devis.vente = 'Vente face à face';
    } else if (this.agentData.profil.toString() === '33' || this.agentData.profil.toString() === '53' ||
      this.agentData.profil.toString() === '123' || this.agentData.profil.toString() === '23') {
      this.devis.vente = 'Vente à distance';
    }
  }

  private initSignatureObject(signatureRequest, paymentOptions) {
    signatureRequest.titulaire = paymentOptions.titulaire;
    signatureRequest.iban = paymentOptions.iban;
    signatureRequest.codeBic = paymentOptions.bic;
    signatureRequest.modeenvoie = paymentOptions.modeenvoie;
  }

  private createRefBque(paymentOptions) {
    this.spinner.show();
    const refbque = this.initRefBancaire(paymentOptions);
    this.devisService.updateRefBque(refbque).subscribe(() => {
      if (this.toggleSignValue) {
        this.sign(paymentOptions);
      } else {
        this.cookies.set('signType', JSON.stringify(refbque.typeSignature),
          undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.cookies.set('previous', 'mandat', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.finishContrat(this.devisId);
      }
    }, error => {
      console.error('error when update reference bancaire');
      this.spinner.hide();
    });
  }

  private finishContrat(idDevis) {
    this.devisService.updateDevisStatus(idDevis, DevisStatus.A_ENVOYER_EN_GESTION).subscribe(data => {
      this.bdocDto.devisId = idDevis;
      this.bdocDto.sendToGED = true;
      this.bdocService.getFileFromBdoc(this.bdocDto).subscribe(res => {
        if (this.agentData || this.cookies.get('client')) {
          this.updateDevisVente();
        } else {
          this.spinner.hide();
        }
      }, error => {
        console.error('error when get file from bdoc');
        this.spinner.hide();
      });
    }, error => {
      console.error('error when update devis status');
      this.spinner.hide();
    });
  }

  private updateDevisVente() {
    const devisVente = new DevisVenteDTO();
    if (this.agentData) {
      devisVente.idAgent = this.agentData.id;
    }
    devisVente.idDevis = this.cookies.get('devis');
    if (this.agentData.profil.toString() === '24') {
      devisVente.vente = 'Vente face à face';
    } else if (this.agentData.profil.toString() === '33' || this.agentData.profil.toString() === '53' ||
      this.agentData.profil.toString() === '123' || this.agentData.profil.toString() === '23') {
      devisVente.vente = 'Vente à distance';
    }
    this.devisService.updateDevisVente(devisVente).subscribe(data => {
      this.resetDevis();
    }, error => {
      this.spinner.hide();
      console.error('error when update devis vente');
    });
  }

  private resetDevis() {
    this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
      this.devis = devis;
      this.produit = this.cookies.get('produit');
      this.finishProcess = true;
      this.cookies.set('_finishProcess', this.finishProcess.toString(), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.initDataToDisplay(devis);
      if (!this.devis.vente && this.agentData) {
        this.setDefaultVente();
      }
      this.spinner.hide();
    }, error => {
      console.error('error when get devis by id');
      this.spinner.hide();
    });
  }

  private initRefBancaire(paymentOptions) {
    const refbque = new ReferenceBancaire();
    refbque.nom = paymentOptions.nom;
    refbque.prenom = paymentOptions.prenom;
    refbque.adresseLigne1 = paymentOptions.adresse;
    refbque.codePostale = paymentOptions.codePostale;
    refbque.ville = paymentOptions.ville;
    refbque.iban = this.paymentOptions.iban;
    refbque.bic = this.paymentOptions.bic;
    refbque.idDevis = this.devisId;
    refbque.titulaire = paymentOptions.titulaire;
    refbque.jourPrelevement = this.paymentOptions.jourPrelevement;
    if (paymentOptions.premiereCotisation) {
      refbque.premiereCotisation = paymentOptions.premiereCotisation;
    }
    if (this.toggleCodeValue) {
      refbque.titulairePrest = this.paymentOptions.titulairePrest;
      refbque.bicPrest = this.paymentOptions.bicPrest;
      refbque.ibanPrest = this.paymentOptions.ibanPrest;
    }
    refbque.modeEnvoi = paymentOptions.modeenvoie;
    if (this.toggleSignValue) {
      refbque.typeSignature = OavConstants.TYPE_SIGNATURE_AUTO;
    } else {
      refbque.typeSignature = OavConstants.TYPE_SIGNATURE_MANUAL;
      refbque.modeEnvoi = null;
    }
    refbque.statusDevis = DevisStatus.A_SIGNER;
    refbque.modePaiement = paymentOptions.modePaiement;
    if (paymentOptions.idRefBancaire) {
      refbque.id = paymentOptions.idRefBancaire;
    }
    return refbque;
  }

  private validBIC(bic) {
    const re = /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;
    return (re.test(String(bic)) && bic.length < 12);
  }

  private validIBAN(ibanValue) {
    const countryCode = '';
    if (countryCode && ibanValue) {
      return /^[A-Z]{2}$/i.test(countryCode) &&
        new RegExp(`^${countryCode}.*$`, 'i').test(ibanValue) && IBAN.isValid(ibanValue);
    } else if (ibanValue) {
      return IBAN.isValid(ibanValue);
    }
    return false;
  }

  private validField(field) {
    const re = /^[_'.@A-z�-�0-9- ]*$/;
    return (field && field.length > 0 && re.test(String(field)));
  }

  private validForm(paymentOptions) {
    return paymentOptions.titulaire && this.testForBlackSpaces(paymentOptions.titulaire) && paymentOptions.adresse &&
      this.testForBlackSpaces(paymentOptions.adresse) && this.acceptCG && this.validBoolean(this.toggleCodeValue) &&
      paymentOptions.codePostale && paymentOptions.ville && this.validBIC(paymentOptions.bic) &&
      this.validIBAN(paymentOptions.iban) && this.validBoolean(this.toggleSignValue)
      && this.valiedFieldsPrestation(this.toggleCodeValue) && this.paymentOptions.jourPrelevement && this.verifSignTypeChoice() &&
      this.paymentOptions.modePaiement && (!this.showPayment || (this.showPayment && this.paymentOptions.premiereCotisation !== null
        && this.paymentOptions.premiereCotisation !== undefined));
  }
  onOptSignatureChange(entry) {
    this.paymentOptions.modeenvoie = entry;
  }

  private validBoolean(value) {
    return value !== null && value !== undefined;
  }

  valiedFieldsPrestation(option) {
    if (option === true) {
      if (this.paymentOptions.titulairePrest && this.testForBlackSpaces(this.paymentOptions.titulairePrest) &&
        this.validIBAN(this.paymentOptions.ibanPrest) && this.validBIC(this.paymentOptions.bicPrest)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  onJourPrelevementChange(entry) {
    this.paymentOptions.jourPrelevement = entry;
  }

  getSouscripteurVilles(codePostal) {
    this.villeService.getByCodePostal(codePostal).subscribe(data => {
      this.villesSouscripteur = data;
      if (this.villesSouscripteur.length > 0) {
        this.souscripteur.adressePostale.ville = this.villesSouscripteur[0].libelle;
      }
    }, error => {
      console.error('error while get villes souscripteur');
    });
  }

  createRefContrat() {
    this.devisService.createRefContrat(this.devisId).subscribe(data => {
    }, error => {
      console.error('error when create reference contrat');
      this.spinner.hide();
    });
  }

  validFieldsSauvegarde(paymentOptions) {
    let validateBic = true;
    let validateIban = true;
    if (paymentOptions.bic.length === 0) {
    } else {
      if (paymentOptions.bic != null || paymentOptions.bic !== undefined) {
        validateBic = this.validBIC(paymentOptions.bic);
      }
      if (paymentOptions.iban != null || paymentOptions.iban !== undefined) {
        validateIban = this.validIBAN(paymentOptions.iban);
      }
    }
    return validateBic && validateIban;
  }

  initRefBancaireForCreate(paymentOptions) {
    const refbque = new ReferenceBancaire();
    refbque.nom = paymentOptions.nom;
    refbque.prenom = paymentOptions.prenom;
    refbque.adresseLigne1 = paymentOptions.adresse;
    refbque.codePostale = paymentOptions.codePostale;
    refbque.ville = paymentOptions.ville;
    refbque.iban = this.paymentOptions.iban;
    refbque.bic = this.paymentOptions.bic;
    refbque.idDevis = this.devisId;
    refbque.titulaire = paymentOptions.titulaire;
    refbque.jourPrelevement = this.paymentOptions.jourPrelevement;
    refbque.modePaiement = paymentOptions.modePaiement;
    if (paymentOptions.premiereCotisation) {
      refbque.premiereCotisation = paymentOptions.premiereCotisation;
    }
    if (this.toggleCodeValue) {
      refbque.titulairePrest = this.paymentOptions.titulairePrest;
      refbque.bicPrest = this.paymentOptions.bicPrest;
      refbque.ibanPrest = this.paymentOptions.ibanPrest;
    }
    refbque.modeEnvoi = paymentOptions.modeenvoie;
    if (this.toggleSignValue) {
      refbque.typeSignature = OavConstants.TYPE_SIGNATURE_AUTO;
    } else {
      refbque.typeSignature = OavConstants.TYPE_SIGNATURE_MANUAL;
      refbque.modeEnvoi = null;
    }
    refbque.statusDevis = DevisStatus.MANDAT;
    return refbque;
  }

  private createRefBqueSauvegarde(paymentOptions) {
    this.tryToSubmitForm = true;
    this.spinner.show();
    const refbque = this.initRefBancaireForCreate(paymentOptions);
    this.devisService.updateRefBque(refbque).subscribe(() => {
      this.cookies.set('previous', 'mandat', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_SAUVEGARDE_INFORMATIONS_SANTE
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.spinner.hide();
      this.router.navigate([OavConstants.SAUVEGARDE_DEVIS_SANTE_URL]);
    }, error => {
      console.error('error when update reference bancaire');
      this.spinner.hide();
    });
  }

  saveDevis(paymentOptions) {
    if (this.validFieldsSauvegarde(paymentOptions)) {
      this.createRefBqueSauvegarde(paymentOptions);
    }
  }

  backViewTab() {
    this.cookies.set('previous', 'mandat', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_FORMULE_SANTE,
      undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.INFORMATION_SANTE_URL]);
  }

  verifSignTypeChoice() {
    if (this.toggleSignValue) {
      if (this.paymentOptions.modeenvoie) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  testForBlackSpaces(value) {
    if (value) {
      return value.toString().replace(/\s/g, '');
    }
    return true;
  }

  redirectToEspace() {
    this.cookies.delete('_finishProcess');
    this.cookies.delete(OavConstants.DEVIS);
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    if (this.cookies.get('agent')) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_AGENT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.ESPACE_AGENT]);
    } else {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.ESPACE_PROSPECT]);
    }
  }

  trackByFn(index, item) {
    return index;
  }

  imprimerDevis() {
    this.spinner.show();
    this.bdocDto.devisId = this.devisId;
    this.bdocDto.sendToGED = false;
    this.bdocService.getFileFromBdoc(this.bdocDto).subscribe(res => {
      this.spinner.hide();
      this.data = res;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        this.handeIE11DownloadFile(this.data.bdocReponse.documentb64, 'Contrat.pdf');
      } else {
        const a = document.createElement('a');
        a.href = 'data:application/pdf;base64,' + this.data.bdocReponse.documentb64;
        a.download = 'Contrat.pdf';
        a.click();
      }
    }, error => {
      this.spinner.hide();
      console.error('error when get file from bdoc');
    });
  }

  handeIE11DownloadFile(data64, name) {
    window.navigator.msSaveBlob(this.base64ToBlob(data64), name);
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: 'application/pdf' });
  }

  convertIBANToUpperCase(str: string) {
    this.paymentOptions.iban = str.toUpperCase();
  }

  converIBANPrestToUpperCase(str: string) {
    this.paymentOptions.ibanPrest = str.toUpperCase();
  }

  downloadCG() {
    this.downloadPdf('CG');
  }

  downloadProduitAssuranceDoc() {
    this.downloadPdf('IPID');
  }

  downloadPdf(type) {
    this.devis.formule.fileToDownload = type;
    this.formuleService.getPdf(this.devis).subscribe((data: ArrayBuffer) => {
      const newBlob = new Blob([data], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const dataa = window.URL.createObjectURL(newBlob);
      if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = dataa;
        a.download = type;
        a.click();
        return;
      }
      window.open(dataa);
    }, error => {
      this.spinner.hide();
    });
  }

  signManual() {
    this.toggleSignValue = false;
    this.isShowPayment(this.devis);
    this.paymentOptions.premiereCotisation = null;
  }

  signAuto() {
    this.toggleSignValue = true;
    this.isShowPayment(this.devis);
  }
}

import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {OavConstants} from '../../../../../shared/data/OavConstants';
import {AppConfig} from '../../../../../app.config';
import {Router} from '@angular/router';
import {PaymentService} from '../../../../../shared/services/payment.service';
import {DevisModelDTO} from '../../../models/devisDTO';
import {DevisStatus} from '../../../models/devisStatus';
import {DevisService} from '../../../services/devis.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {GuardService} from '../../../../../shared/services/guard.service';
import {TrakingParamConstants} from '../../../../../shared/data/TrakingParamValues';
import {TagCommanderSevice} from '../../../../../shared/services/tag-Commander.sevice';

@Component({
  selector: 'app-paiement-form',
  templateUrl: './paiement-form.component.html',
  styleUrls: ['./paiement-form.component.css']
})
export class PaiementFormComponent implements OnInit {

  devisId;
  trans_ref;
  sceillus;
  amount;
  private canal: string;

  constructor(private cookies: CookieService, private router: Router, private payementService: PaymentService,
              private devisService: DevisService, private spinner: NgxSpinnerService,
              private guardService: GuardService, private tagCommanderSevice: TagCommanderSevice,
              private readonly elementRef: ElementRef, private renderer: Renderer2) {
    this.guardService.checkCurrentScreen().subscribe(data => {
    });
  }

  ngOnInit() {
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.SANTE_SOUSCRIPTION_ETAPE3_PAIEMENT_CB_CONFIRME_PAGENAME, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.SANTE_SOUSCRIPTION_ETAPE3_PAIEMENT_CB_CONFIRME_PAGENAME, this.renderer, this.elementRef);

    this.devisId = this.cookies.get(OavConstants.DEVIS);
    this.trans_ref = this.cookies.get('trans_ref');
    this.cookies.delete('modePay', undefined, undefined);
    if (this.trans_ref) {
      this.payementService.getSceillusPayement(this.trans_ref, this.devisId).subscribe(rep => {
        this.spinner.show();
        this.sceillus = rep;
        this.amount = this.sceillus.mountTopay.toString().replace('.', ',');
        this.initData();
      });
    } else {
      console.error('Referance de transaction est introuvable');
    }
  }

  initData() {
    this.devisService.getDevisById(this.devisId).subscribe((data: DevisModelDTO) => {
      this.updateDevisStatus(DevisStatus.A_ENVOYER_EN_GESTION);
    }, error => {
      this.spinner.hide();
      console.error('error when get devis by id');
    });
  }

  private updateDevisStatus(status) {
    this.devisService.updateDevisStatus(this.devisId, status).subscribe((data: DevisModelDTO) => {
      this.spinner.hide();
    }, error => {
      console.error('error when update devis status');
      this.spinner.hide();
    });
  }

  nextViewTab() {
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_MANDAT_SANTE
      , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.VALIDATION_SANTE_URL]);
  }

}

export interface IAppConfig {
    securedEndpoint: string;
    publicEndpoint: string;
    cookieSecure: boolean;
}

/**
 *  The cookieSecure : should be true in server environement and undefined in local environement
 */
export const AppConfig: IAppConfig = {

    /*************************************** Local **************************** */
      // publicEndpoint: 'http://localhost:8080',
      // securedEndpoint: 'http://localhost:8080/oav/ws/rest',
      // cookieSecure: undefined

    /*************************************** Recette fabrique******************/
    // publicEndpoint: 'https://oav-lbpas-rec.meprotege.fr/oav',
    // securedEndpoint: 'https://oav-lbpas-rec.meprotege.fr/oav/oav/ws/rest',
    // cookieSecure: undefined

    /*****************************************Recette fonctionnelle********************** */
    //publicEndpoint: 'https://oav-lbpas-recettefonc.meprotege.fr/oav',
    //securedEndpoint: 'https://oav-lbpas-recettefonc.meprotege.fr/oav/oav/ws/rest',
    //cookieSecure: true

    /*****************************************Prod********************** */
     publicEndpoint: 'https://assurancesante.labanquepostale.fr/oav',
     securedEndpoint: 'https://assurancesante.labanquepostale.fr/oav/oav/ws/rest',
     cookieSecure: true
};

import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { CookieService } from 'ngx-cookie-service';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/service/navbar.service';
import { BandeauMessage } from 'src/app/models/bandeauMessage';
import { CookiesEntriesModel } from 'src/app/shared/models/cookiesEntries.Model';
import { AppConfig } from 'src/app/app.config';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('showDevis', style({ opacity: 1 })),
      state('hideDevis', style({ opacity: 0 })),
      transition('showDevis <=> hideDevis', animate('300ms')),
    ])
  ]
})
export class NavbarComponent implements OnInit {
  inProduction: boolean;
  isNavbarCollapsed = true;
  languages: any[];
  swaggerEnabled: boolean;
  version: string;
  account: Account;
  userName: String;
  dispo = false;
  state = 'showDevis';
  displayDevisState = false;
  menuOn = false;
  isAgent = false;
  isClient = false;
  contentBandeau = new BandeauMessage();

  constructor(private cookies: CookieService, private router: Router,
    private navbarService: NavbarService) { }

  ngOnInit() {
    this.getBandeauContent();
  }

  isAuthenticated() {
    return true;
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  collapseNavbar() {
    this.isNavbarCollapsed = true;
  }

  isCurrentProduitSante() {
    return OavConstants.PRODUIT_SANTE === this.cookies.get('produit');
  }

  isCurrentProduitACDS() {
    return OavConstants.PRODUIT_ACDS === this.cookies.get('produit');
  }

  isUserLoggedIn() {
    const agent = this.cookies.get('agent');
    const client = this.cookies.get('client');
    this.isAgent = (agent && JSON.parse(agent) && JSON.parse(agent).agentUid) ? true : false;
    this.isClient = (client && JSON.parse(client) && JSON.parse(client).clientUid) ? true : false;
    return (this.isAgent || this.isClient) ? true : false;
  }

  getUserInfo() {
    if (this.isAgent) {
      return JSON.parse(this.cookies.get('agent'));
    } else if (this.isClient) {
      return JSON.parse(this.cookies.get('client'));
    }
    return false;
  }

  navToSante() {
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    this.cookies.delete('_finishProcess');
    this.cookies.set('produit', OavConstants.PRODUIT_SANTE, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.delete('devis');
    if (!this.cookies.get('agent') && !this.cookies.get('client')) {
      this.cookies.delete('accessToken');
    }
    if (this.cookies.get('agent') && this.cookies.get('client') && this.router.url !== OavConstants.OAV_REDIRECTION) {
      this.cookies.delete('client');
    }
    if (this.router.url === '/sante' || this.router.url === '/') {
      location.reload();
    } else {
      this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
    }
  }

  navToAcds() {
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    this.cookies.delete('_finishProcess');
    this.cookies.set('produit', OavConstants.PRODUIT_ACDS, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.delete('devis');
    if (!this.cookies.get('agent') && !this.cookies.get('client')) {
      this.cookies.delete('accessToken');
    }
    if (this.cookies.get('agent') && this.cookies.get('client') && this.router.url !== OavConstants.OAV_REDIRECTION) {
      this.cookies.delete('client');
    }
    if (this.router.url === '/acds') {
      location.reload();
    } else {
      this.router.navigate([OavConstants.PROFIL_ACDS_URL]);
    }
  }

  logout() {
    this.router.navigate([OavConstants.LOGIN_URL]);
    const cookiesEntries = new CookiesEntriesModel().entries;
    cookiesEntries.forEach(entry => {
      this.cookies.delete(entry);
    });
  }

  monCompte() {
    const agent = this.cookies.get('agent');
    const client = this.cookies.get('client');
    if (this.isUserLoggedIn() && agent) {
      this.cookies.delete('devis');
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_AGENT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.ESPACE_AGENT]);
    } else if (this.isUserLoggedIn() && client) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.delete('devis');
      this.router.navigate([OavConstants.ESPACE_PROSPECT]);
    } else {
      this.cookies.delete('devis');
      this.router.navigate([OavConstants.LOGIN_URL]);
    }

  }

  getBandeauContent() {
    this.navbarService.getContentBandeau().subscribe(result => {
      this.contentBandeau = result;
    }, error => {
    });
  }

  uppercase(str: string) {
    return (str) ? str.toUpperCase() : '';
  }

}

import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AppConfig} from 'src/app/app.config';
import {OavConstants} from 'src/app/shared/data/OavConstants';
import {UserDTO} from '../../models/userDTO';
import {UserService} from '../../services/user.service';
import {TagCommanderSevice} from '../../../shared/services/tag-Commander.sevice';
import {TrakingParamConstants} from '../../../shared/data/TrakingParamValues';

@Component({
  selector: 'app-change-pass-info',
  templateUrl: './change-pass-info.component.html',
  styleUrls: ['./change-pass-info.component.css']
})
export class ChangePassInfoComponent implements OnInit {
  isAgent = false;
  isClient = false;
  clientData;
  agentData;
  private canal: string;

  constructor(private router: Router, private cookies: CookieService, private userService: UserService,
              private spinner: NgxSpinnerService, private tagCommanderSevice: TagCommanderSevice,
              private readonly elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.PROSPECT_CONFIRMATION_REINITIALISATION_PAGENAME, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.PROSPECT_CONFIRMATION_REINITIALISATION_PAGENAME, this.renderer, this.elementRef);

    this.isAgent = this.cookies.get('user') === 'false';
    this.isClient = this.cookies.get('user') === 'true';
    if (!this.isClient && !this.isAgent) {
      this.router.navigate([OavConstants.LOGIN_URL]);
    }
    if (this.cookies.get('client') && JSON.parse(this.cookies.get('client')) != null) {
      this.clientData = JSON.parse(this.cookies.get('client'));
      if (this.clientData.clientUid) {
        this.getUserDetailsById();
      }
    }
    if (this.cookies.get('agent') && JSON.parse(this.cookies.get('agent')) != null) {
      this.agentData = JSON.parse(this.cookies.get('agent'));
    }
  }

  private getUserDetailsById() {
    this.userService.getUserDetailsById(this.clientData.clientUid).subscribe((user: UserDTO) => {
      this.clientData.nom = user.firstName;
      this.clientData.prenom = user.lastName;
      this.clientData.clientUid = user.activationId;
      this.cookies.set('client', JSON.stringify(this.clientData), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    }, error => {
      this.spinner.hide();
      console.error('error when get user details by id');
    });
  }

  userSpace() {
    if (this.isAgent) {
      this.router.navigate([OavConstants.ESPACE_AGENT]);
    }
    if (this.isClient) {
      this.router.navigate([OavConstants.ESPACE_PROSPECT]);
    }
  }

}

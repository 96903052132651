import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { AgreementComponent } from './components/agreement/agreement.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ResumeFormuleSanteComponent } from '../features/process-sante/components/resumeFormule/resumeFormuleSante.component';
import { ResumeFormuleAcdsComponent } from '../features/process-acds/components/resumeFormule/resumeFormuleAcds.component';
import {StepsModule} from 'primeng/steps';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { SearchCountConfirmModalComponent } from './components/search-count-confirm-modal/search-count-confirm-modal.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NumberFormatPipe } from './components/numberFormat/number.pipe';
import { ValidationSanteComponent } from '../features/process-sante/components/validation/validation.component';
import { ValidationAcdsComponent } from '../features/process-acds/components/validation/validation.component';
import { OavPlaceholderDetector } from './directives/oav-input-change.directive';
import { IbanDirective } from './directives/iban-validator.directive';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '../../assets/i18n/');
}
@NgModule({
  declarations: [
    AgreementComponent,
    ResumeFormuleSanteComponent,
    ResumeFormuleAcdsComponent,
    ValidationAcdsComponent,
    ValidationSanteComponent,
    ConfirmModalComponent,
    SearchCountConfirmModalComponent,
    NumberFormatPipe,
    OavPlaceholderDetector,
    IbanDirective
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    PdfViewerModule,
    FormsModule,
    StepsModule,
    TooltipModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
    entryComponents: [
      AgreementComponent,
      ConfirmModalComponent,
      SearchCountConfirmModalComponent
    ],
    providers: [ NumberFormatPipe ],
    exports: [
      ResumeFormuleSanteComponent,
      ResumeFormuleAcdsComponent,
      ValidationAcdsComponent,
      ValidationSanteComponent,
      NumberFormatPipe,
      OavPlaceholderDetector,
      IbanDirective
    ]
})
export class SharedModule { }

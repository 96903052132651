import {AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegimeService } from 'src/app/features/process-sante/services/regime.service';
import { DevisService } from 'src/app/features/process-sante/services/devis.service';
import { RegimeDTO } from 'src/app/features/process-sante/models/regimeDTO';
import { DevisModelDTO } from 'src/app/features/process-sante/models/devisDTO';
import { UserUI } from 'src/app/features/process-sante/models/user';
import { Adherent } from 'src/app/features/process-sante/models/adherent';
import { Enfant } from 'src/app/features/process-sante/models/enfant';
import { Beneficiaire } from 'src/app/features/process-sante/models/beneficiaire';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AuthService } from 'src/app/shared/services/auth.service';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { CookieService } from 'ngx-cookie-service';
import { SouscripteurModelDTO } from '../../models/souscripteurModelDTO';
import { SouscripteurService } from '../../services/souscripteur.service';
import * as $ from 'jquery';
import { PublicUser } from 'src/app/shared/models/publicUser';
import { VenteType } from '../../models/venteType';
import { Parcour } from '../../models/parcourType';
import { DevisStatus } from '../../models/devisStatus';
import { VilleDTO } from '../../models/villeDTO';
import { VilleService } from '../../services/ville.service';
import { DocumentStatiqueService } from 'src/app/shared/services/document-statique.service';
import { DocumentStatiqueModel } from 'src/app/shared/models/documentStatiqueModel';
import { OavDateService } from 'src/app/shared/services/oav-date.service';
import { Observable } from 'rxjs';
import { AgreementComponent } from 'src/app/shared/components/agreement/agreement.component';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ListDevisDTO } from '../../models/listDevisDTO';
import { AdressePostalDTO } from '../../models/adressePostalDTO';
import { ContactDTO } from '../../models/contactDTO';
import { BeneficiareService } from '../../services/beneficiare.service';
import { UserService } from 'src/app/core/services/user.service';
import { NgForm } from '@angular/forms';
import { CreatedDevisModel } from '../../models/createdDevisModel';
import { GuardService } from 'src/app/shared/services/guard.service';
import { AppConfig } from 'src/app/app.config';
import { ClientDataDTO } from 'src/app/core/models/clientData';
import { ExternalClient } from '../../models/externalClient';
import { SanticlaireService } from 'src/app/shared/services/santiclaire.service';
import { TranslateService } from '@ngx-translate/core';
import {TrakingParamConstants} from '../../../../shared/data/TrakingParamValues';
import {TagCommanderSevice} from '../../../../shared/services/tag-Commander.sevice';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  userUI = new UserUI();
  adhesionD = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
  maxDate = new Date();
  regimes = new Array<RegimeDTO>();
  tryToSubmitForm = false;
  minDate;
  devis = new DevisModelDTO();
  childrenNb = 0;
  devisId;
  adherentDTO = new Adherent();
  souscripteur = new SouscripteurModelDTO();
  adhesionDate;
  devisDTO = new DevisModelDTO();
  showConjoint;
  enfantList = new Array<Enfant>();
  regimeTypeConjoint = 1;
  regimeTypeProfile = 1;
  conjointDateNaiss: Date;
  toggleAcsValue;
  showAssure = false;
  userConnected = false;
  vente;
  modalRef: BsModalRef;
  activSlide = false;
  dateAdhesionInvalide = false;
  dateNaissInvalide = false;
  dateNaissConjInvalide = false;
  dateNaissanceSouscripteur;
  dateNaissanceSouscripteurInvalide = false;
  dateNaissanceSouscripteurpickDirty = false;
  dateNaissanceDirty = false;
  choixAssureClicked = false;
  validCodePostal = true;
  villes: Array<VilleDTO>;
  docsOpen = false;
  dateNaissance;
  listDocumentStatique: DocumentStatiqueModel[] = [];
  childrenDateNaissValidation = new Array<boolean>();
  childrenValidationDateNaissanceLimit = new Array<boolean>();
  cssBlock = false;
  idSouscripteur: number;
  clientData = new ExternalClient();
  agentData;
  items = [];
  activeIndex = 0;
  bsConfig: Partial<BsDatepickerConfig>;
  showUnfinishDevis;
  continueDevis;
  adresse = new AdressePostalDTO();
  contact = new ContactDTO();
  benef: Array<Beneficiaire>;
  agentCRC = false;
  dataLoaded = false;
  idRHApporteur;
  showDocs;
  codePostalDirty = false;
  resiliation;
  cpFocusOut = false;
  mailFocuout = false;
  connectedProcess = false;
  emailInfoTitle = '';
  noMailAddress = false;
  fromEspaceAgent = false;
  noMailPopupOpen = false;
  private canal: string;
  @ViewChild('myForm') myForm: NgForm;
  @ViewChild('templateEmail') templateEmail: TemplateRef<any>;
  dateFilter = (d: Date) => d.getDate() === 1;
  @HostListener('document:keydown.enter')
  onEnter() {
    if (this.noMailPopupOpen) {
      setTimeout(() => {
        this.noMailAddressMadalClicked(this.templateEmail);
      });
    }
  }

  constructor(private router: Router, private spinner: NgxSpinnerService, private devisService: DevisService,
    private regimeService: RegimeService, private beneficiaireService: BeneficiareService,
    private localeService: BsLocaleService, private oavDateService: OavDateService, private userService: UserService,
    private authService: AuthService, private cookies: CookieService, private souscripteurService: SouscripteurService,
    private bsModalService: BsModalService, private modalService: ModalService, private guardService: GuardService,
    private villeService: VilleService, private documentStatiqueService: DocumentStatiqueService,
    private santiclaireService: SanticlaireService, private translate: TranslateService,
    private readonly elementRef: ElementRef, private renderer: Renderer2, private tagCommanderSevice: TagCommanderSevice) {
    if (this.cookies.get(OavConstants.CURRENT_SCREEN)) {
      this.checkForCurrentScreen();
    }
    frLocale.invalidDate = '';
    defineLocale('fr', frLocale);
    this.localeService.use('fr');
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', isAnimated: true, showWeekNumbers: false });
    document.getElementById('footer').style.position = 'relative';
    this.items = [{
      label: 'Mon Profil'
    },
    {
      label: 'Mes besoins'
    },
    {
      label: 'Formules'
    },
    {
      label: 'Votre devis'
    }
    ];
    this.minDate = this.adhesionD;
    this.adhesionDate = new Date(this.minDate);
    this.showCallBackComponent();
  }

  ngOnInit() {
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.SANTE_DEVIS_ETAPE1_MON_PROFIL_PAGENAME, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.SANTE_DEVIS_ETAPE1_MON_PROFIL_PAGENAME, this.renderer, this.elementRef);
    if (this.cookies.get('agent')) {
      this.agentData = JSON.parse(this.cookies.get('agent'));
      this.agentCRC = this.agentData.profil === '53' || this.agentData.profil === '33';
      setTimeout(() => {
      this.emailInfoTitle = this.agentCRC ? this.translate.instant('oavApp.profile.information.emailInfo') : '';
      }, 3000);
    }
    this.devisId = this.cookies.check(OavConstants.DEVIS) ? this.cookies.get(OavConstants.DEVIS) : null;
    this.getAllRegimes();
    this.cookies.set('produit', OavConstants.PRODUIT_SANTE, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    if (this.cookies.get('_latest') === '1' && !this.cookies.get('client') && !this.cookies.get(OavConstants.CURRENT_SCREEN)) {
      this.cookies.delete(OavConstants.DEVIS);
      this.devisId = null;
    } else {
      this.cookies.set('_latest', '1', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.getDevis();
      this.initData();
    }
    this.getListDocumentStatique();
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => { });
  }

  getParcours() {
    if (this.cookies.get('agent')) {
      return 'AGENT';
    } else if (this.cookies.get('client')) {
      return 'Prospect';
    } else {
      return 'Prospect';
    }
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: 'application/pdf' });
  }

  openDocumentStatique(doc) {
    this.documentStatiqueService.getDocumentStatique(doc).subscribe(rep => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(this.base64ToBlob(rep.encoded));
      } else {
        const fileURL = URL.createObjectURL(this.base64ToBlob(rep.encoded));
        if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = doc;
          a.click();
          return;
        }
        window.open(fileURL);
      }
    });
  }

  getListDocumentStatique() {
    this.documentStatiqueService.getListDocumentsByProduitAndParcoursAndEcran('Sante', this.getParcours(), 'Mon profil').subscribe(rep => {
      this.listDocumentStatique = rep;
      if (this.listDocumentStatique.length > 0 && this.agentData) {
        this.showDocs = true;
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.spinner.hide();
    this.modalRef = this.bsModalService.show(template);
  }

  ngAfterViewInit() {
    if (this.isCurrentBrowserIe11) {
      setTimeout(() => {
        this.dataLoaded = true;
      }, 100);
    } else {
      this.dataLoaded = true;
    }
  }

  showCallBackComponent() {
    document.getElementById('sectionDevis').style.display = 'block';
  }

  initData() {
    if (this.cookies.get('agent')) {
      this.agentData = JSON.parse(this.cookies.get('agent'));
    }
    if (this.cookies.get('client')) {
      this.fromEspaceAgent = true;
      this.clientData = JSON.parse(this.cookies.get('client'));
    }
    if (this.cookies.get(OavConstants.DEVIS) && !this.cookies.get('_ext') && !this.clientData.email) {
      this.userConnected = true;
    }
    if (!this.showAssure && this.clientData) {
      if (!this.cookies.get('_ext') && this.clientData.email) {
        this.userConnected = true;
      }
      if (this.clientData.idExterne) {
        this.spinner.show();
        this.initUserDataByIdExterne();
      } else if (this.clientData.email) {
        this.spinner.show();
        this.initUserDataByUsername();
      }
    }
    this.adherentDTO.email = (this.clientData.email ? this.clientData.email : '');
  }

  initUserDataByUsername() {
    this.userService.getUserByUsername(this.clientData.email).subscribe(data => {
      const res: any = data;
      if (res && res.id) {
        this.noMailAddress = res.fakeEmail;
        this.userConnected = true;
        this.souscripteurService.getByUser(res.id).subscribe(sousc => {
          const result: any = sousc;
          this.souscripteur = result;
          if (result.dateNaissance) {
            this.souscripteur.dateNaissance = this.oavDateService.loadUTCDate(result.dateNaissance);
            this.dateNaissance = this.souscripteur.dateNaissance;
            this.checkDate();
          }
          this.adherentDTO.codePostal = result.adressePostale.codePostale ? result.adressePostale &&
            result.adressePostale.codePostale : '';
          this.verifCodePostal(this.adherentDTO.codePostal);
          this.souscripteur.civilite = result.sexe;
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          console.error('error when get souscripteur by user');
        });
      } else {
        this.spinner.hide();
        this.souscripteur.contact.email = this.clientData.email ? this.clientData.email : '';
      }
    }, error => {
      this.spinner.hide();
      console.error('error when get user by username');
    });
  }

  initUserDataByIdExterne() {
    this.userService.getUserByIdExterne(this.clientData.idExterne).subscribe(data => {
      const res: any = data;
      if (res && res.id) {
        this.noMailAddress = res.fakeEmail;
        this.userConnected = true;
        this.souscripteurService.getByUser(res.id).subscribe(sousc => {
          const result: any = sousc;
          this.souscripteur = result;
          this.adherentDTO.email = this.souscripteur.contact.email;
          if (result.dateNaissance) {
            this.souscripteur.dateNaissance = this.oavDateService.loadUTCDate(result.dateNaissance);
            this.dateNaissance = this.souscripteur.dateNaissance;
            this.checkDate();
          }
          this.adherentDTO.codePostal = result.adressePostale.codePostale ? result.adressePostale &&
            result.adressePostale.codePostale : '';
          this.verifCodePostal(this.adherentDTO.codePostal);
          this.souscripteur.civilite = result.sexe;
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          console.error('error when get souscripteur by user');
        });
      } else {
        this.fromEspaceAgent = !(this.agentData && !this.clientData.email);
        this.initFromExternalData();
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      console.error('error when get user by username');
    });
  }

  private initFromExternalData() {
    this.souscripteur.contact.email = this.clientData.email ? this.clientData.email : null;
    this.souscripteur.nom = this.clientData.nom;
    this.souscripteur.prenom = this.clientData.prenom;
    this.souscripteur.adressePostale.adresseLigne1 = this.clientData.adresse ? this.clientData.adresse : null;
    this.souscripteur.adressePostale.ville = this.clientData.ville ? this.clientData.ville : null;
    this.souscripteur.adressePostale.pays = this.clientData.pays ? this.clientData.pays : null;
    this.souscripteur.contact.telMobile = this.clientData.telephonePortable ? this.clientData.telephonePortable : null;
    this.souscripteur.contact.telDomicile = this.clientData.telephoneFixe ? this.clientData.telephoneFixe : null;
    this.devisDTO.user.idExterne = this.clientData.idExterne ? this.clientData.idExterne : null;
    this.dateNaissance = this.oavDateService.loadUTCDate(this.clientData.dateDeNaissance);
    this.souscripteur.civilite = this.clientData.civilite === 'Mme' ? true : false;
    this.adherentDTO.codePostal = this.clientData.codePostale;
    this.verifCodePostal(this.adherentDTO.codePostal);
    this.adherentDTO.email = this.clientData.email;
    this.checkDate();
  }
  getVilles() {
    this.villeService.getAll().subscribe((data: Array<VilleDTO>) => {
      this.villes = data;
    });
  }


  checkDate() {
    if (this.adhesionDate) {
      if (this.resiliation) {
        if (new Date(this.adhesionDate.getFullYear(), this.adhesionDate.getMonth(), this.adhesionDate.getDate()) >=
          new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate()) &&
          new Date(this.adhesionDate.getFullYear(), this.adhesionDate.getMonth(), this.adhesionDate.getDate()) <
          new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())) {
          this.dateAdhesionInvalide = false;
        } else {
          this.dateAdhesionInvalide = true;
        }
      } else {
        if (new Date(this.adhesionDate.getFullYear(), this.adhesionDate.getMonth(), this.adhesionDate.getDate()) >=
          new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate()) &&
          new Date(this.adhesionDate.getFullYear(), this.adhesionDate.getMonth(), this.adhesionDate.getDate()) <
          new Date(this.minDate.getFullYear() + 1, this.minDate.getMonth(), this.minDate.getDate())) {
          this.dateAdhesionInvalide = false;
        } else {
          this.dateAdhesionInvalide = true;
        }
      }
    } else {
      this.dateAdhesionInvalide = true;
    }
    this.checkAge();
    this.checkAgeSouscripteur();
    this.checkDateNaissanceConjoint();
  }

  checkAge() {
    this.dateNaissanceDirty = true;
    if (new Date(this.dateNaissance)) {
      if (this.calculeAge(this.dateNaissance) >= OavConstants.SANTE_AGE_MIN &&
        this.calculeAge(this.dateNaissance) <= OavConstants.SANTE_AGE_MAX) {
        this.dateNaissInvalide = false;
      } else {
        this.dateNaissInvalide = true;
      }
    } else {
      this.dateNaissInvalide = false;
    }
  }

  checkAgeSouscripteur() {
    this.dateNaissanceSouscripteurpickDirty = true;
    if (this.dateNaissanceSouscripteur) {
      if (this.calculeAge(this.dateNaissanceSouscripteur) >= OavConstants.SANTE_AGE_MIN &&
        this.calculeAge(this.dateNaissanceSouscripteur) <= OavConstants.SANTE_AGE_MAX) {
        this.dateNaissanceSouscripteurInvalide = false;
      } else {
        this.dateNaissanceSouscripteurInvalide = true;
      }
    } else {
      this.dateNaissanceSouscripteurInvalide = false;
    }
  }

  checkDateNaissanceConjoint() {
    if (this.conjointDateNaiss) {
      if (this.calculeAge(this.conjointDateNaiss) >= OavConstants.SANTE_AGE_MIN &&
        this.calculeAge(this.conjointDateNaiss) <= OavConstants.SANTE_AGE_MAX) {
        this.dateNaissConjInvalide = false;
      } else {
        this.dateNaissConjInvalide = true;
      }
    } else {
      this.dateNaissConjInvalide = false;
    }
  }

  calculeAge(dateString) {
    let differenceInTime = (new Date(this.adhesionDate)).getTime() - (new Date(dateString)).getTime();
    if (((differenceInTime / (1000 * 3600 * 24)) / 365.25) > 70) {
      const d = new Date(this.adhesionDate);
      d.setDate(d.getDate() - 1);
      differenceInTime = d.getTime() - (new Date(dateString)).getTime();
      return (differenceInTime / (1000 * 3600 * 24)) / 365.25;
    }
    return Math.floor((differenceInTime / (1000 * 3600 * 24)) / 365.25);
  }


  addChild() {
    if (this.childrenNb < OavConstants.NB_MAX_ENFANT) {
      this.childrenNb++;
      this.enfantList.push(new Enfant());
      this.childrenDateNaissValidation.push(false);
      this.childrenValidationDateNaissanceLimit.push(false);
    }
  }

  removeChildrenHandler(index) {
    this.enfantList.splice(index, 1);
    this.childrenNb--;
    this.childrenDateNaissValidation.splice(index, 1);
    this.childrenValidationDateNaissanceLimit.splice(index, 1);
  }

  // @HostListener('document:click', ['$event'])
  updateEnfantValidation(index) {
    if (this.enfantList[index].dateNaissance) {
      this.childrenDateNaissValidation[index] = true;
    } else {
      this.childrenDateNaissValidation[index] = false;
    }
    this.updateEnfantLimitValidation(index);
  }

  updateEnfantLimitValidation(index) {
    this.childrenValidationDateNaissanceLimit[index] = this.enfantList[index].dateNaissance &&
      this.enfantList[index].dateNaissance < new Date();
  }

  onRegimeTypeChildChange(regime, i) {
    this.enfantList[i].regime = regime;
  }

  onRegimeTypeConjointChange(entry) {
    this.regimeTypeConjoint = entry;
  }

  onRegimeTypeProfileChange(entry) {
    this.regimeTypeProfile = entry;
  }

  changeViewTab(templateEmail, template) {
    this.cookies.set(OavConstants.PRODUIT, '1', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    if (!this.showAssure) {
      this.adherentDTO.prenom = this.souscripteur.prenom;
      this.adherentDTO.nom = this.souscripteur.nom;
      this.adherentDTO.civilite = this.souscripteur.civilite;
      if (this.testForBlackSpaces(this.souscripteur.nomNaissance)) {
        this.adherentDTO.nomNaissance = this.souscripteur.nomNaissance;
      }
    }
    if (this.agentCRC) {
      this.vente = VenteType.A_DISTNACE;
    }
    if (!this.validForm()) {
      this.tryToSubmitForm = true;
      this.scrollToError();
    } else {
      if (this.noMailAddress) {
        this.spinner.hide();
        this.noMailPopupOpen = true;
        template.show();
      } else {
        this.searchUnfinishedDevis(templateEmail);
      }
    }
  }

  noMailAddressMadalClicked(templateEmail) {
    this.searchUnfinishedDevis(templateEmail);
  }
  private searchUnfinishedDevis(templateEmail) {
    this.spinner.show();
    const devis = new DevisModelDTO();
    devis.idProduit = 1;
    devis.user.email = this.adherentDTO.email;
    this.devisService.searchUnfinishedDevis(devis).subscribe((result: ListDevisDTO) => {
      if (this.cookies.get('accessToken')) {
        this.connectedProcess = true;
        if (!this.cookies.get('agent')) {
          this.connectedUserProcess(templateEmail, result);
        } else {
          this.connectedAgentProcess(templateEmail, result, devis.user.email);
        }
      } else {
        this.noConnectionProcess(templateEmail, result);
      }
    }, error => {
      this.spinner.hide();
      console.error('error when get unfinished devis');
    });
  }

  private noConnectionProcess(templateEmail, result: ListDevisDTO) {
    if (result && result.listDevisEnCours) {
      this.showUnfinishDevis = true;
      this.openModal(templateEmail);
    } else if (result && result.listDevis) {
      this.showUnfinishDevis = false;
      this.continueDevis = true;
      this.openModal(templateEmail);
    } else {
      this.prepareDevis();
    }
  }

  private prepareDevis() {
    this.initDateAdhesion();
    this.devisDTO.adressePostale.codePostale = this.adherentDTO.codePostal;
    this.devisDTO.contact.email = this.adherentDTO.email;
    this.devisDTO.dateAdhesion.setHours(10);
    this.devisDTO.status = DevisStatus.EN_COURS;
    this.devisDTO.resiliation = this.resiliation;
    this.devisDTO.typeParcours = (this.agentData ? Parcour.AGENT : Parcour.MIXTE);
    if (this.verifRefBancaire(this.clientData) === true) {
      this.createRefBancaire();
    }
    if (this.agentData) {
      if (this.agentCRC) {
        this.devisDTO.vente = VenteType.A_DISTNACE;
      } else {
        if (this.vente === 'face') {
          this.devisDTO.vente = VenteType.FACE_A_FACE;
        }
        if (this.vente === 'distance') {
          this.devisDTO.vente = VenteType.A_DISTNACE;
        }
      }
    }
    this.devisDTO.beneficiaires = [];
    this.initBenificiaires();
    this.createSouscripteur();
  }

  private initBenificiaires() {
    this.devisDTO.beneficiaires.push(this.createBeneficiaireFromAdherant());
    if (this.showAssure) {
      this.devisDTO.beneficiaires.push(this.createBeneficiaireFromSouscripteurNotAssure());
    }
    if (this.showConjoint) {
      this.devisDTO.beneficiaires.push(this.createBeneficiaireFromConjoint());
    }
    if (this.enfantList.length > 0) {
      this.enfantList.forEach((enfant) => {
        this.devisDTO.beneficiaires.push(this.createBeneficiaireFromEnfant(enfant));
      });
    }
  }

  private initDateAdhesion() {
    if (!this.adhesionDate) {
      if (this.oavDateService.saveUTCDate(this.adhesionD).getMonth() === 11) {
        this.devisDTO.dateAdhesion = new Date(this.adhesionD.getFullYear() + 1, 0, 1);
      } else {
        this.devisDTO.dateAdhesion = new Date(this.adhesionD.getFullYear(), this.adhesionD.getMonth() + 1, 1);
      }
    } else {
      if (this.adhesionDate.getFullYear() === 1970) {
        this.devisDTO.dateAdhesion = new Date(this.adhesionD);
      } else {
        this.devisDTO.dateAdhesion = new Date(this.adhesionDate);
      }
    }
  }

  private connectedAgentProcess(templateEmail, result: ListDevisDTO, email) {
    this.spinner.show();
    this.userService.findUserByUsername(email).subscribe((data: any) => {
      if (data) {
        const client = new ClientDataDTO();
        client.nom = data.lastName;
        client.prenom = data.firstName;
        client.email = data.email;
        client.clientUid = data.activationId;
        this.cookies.set('client', JSON.stringify(client), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      }
    }, error => {
      console.error('error when get user by username');
    });
    if (result.listDevisEnCours) {
      this.showUnfinishDevis = true;
      this.openModal(templateEmail);
    } else {
      this.prepareDevis();
    }
  }

  private createRefBancaire() {
    this.devisDTO.refBancaire.adresseLigne1 = this.clientData.adresse;
    this.devisDTO.refBancaire.codePostale = this.clientData.codePostale;
    this.devisDTO.refBancaire.bic = this.clientData.bic;
    this.devisDTO.refBancaire.iban = this.clientData.iban;
  }

  private createSouscripteur() {
    this.devisDTO.idRHApporteur = !this.testForBlackSpaces(this.idRHApporteur) ? null : this.idRHApporteur;
    this.souscripteur.contact.email = this.adherentDTO.email;
    this.devisDTO.assure = !this.showAssure;
    this.souscripteur.sexe = this.souscripteur.civilite;
    this.souscripteur.dateNaissance = this.dateNaissanceSouscripteur;
    const sendSouscripteur = Object.assign({}, this.souscripteur);
    if (this.showAssure) {
      sendSouscripteur.dateNaissance = this.oavDateService.saveUTCDate(sendSouscripteur.dateNaissance);
    } else {
      sendSouscripteur.dateNaissance = this.oavDateService.saveUTCDate(this.dateNaissance);
    }
    if (this.cookies.get(OavConstants.DEVIS)) {
      sendSouscripteur.adressePostale = this.adresse;
      sendSouscripteur.contact = this.contact;
      sendSouscripteur.adressePostale.codePostale = this.adherentDTO.codePostal;
      this.devisDTO.souscripteur = sendSouscripteur;
      this.updateDevis(this.devisDTO);
    } else {
      sendSouscripteur.adressePostale.codePostale = this.adherentDTO.codePostal;
      this.devisDTO.souscripteur = sendSouscripteur;
      this.createDevis(this.devisDTO);
    }
  }
  private createBeneficiaireFromSouscripteurNotAssure() {
    const benificiaire = new Beneficiaire();
    benificiaire.idRegime = this.regimeTypeProfile;
    benificiaire.typeBenef = 4;
    benificiaire.dateNaissance = this.dateNaissanceSouscripteur;
    benificiaire.sexe = this.souscripteur.civilite;
    benificiaire.nomJeuneFille = this.souscripteur.nomNaissance;
    benificiaire.nomBenef = this.souscripteur.nom;
    benificiaire.prenomBenef = this.souscripteur.prenom;

    return benificiaire;
  }
  private createBeneficiaireFromConjoint() {
    const benificiaire = new Beneficiaire();
    benificiaire.idRegime = this.regimeTypeConjoint;
    benificiaire.typeBenef = 2;
    benificiaire.dateNaissance = this.conjointDateNaiss;
    benificiaire.sexe = false;
    benificiaire.nomJeuneFille = '';
    return benificiaire;
  }

  private createBeneficiaireFromEnfant(enfant) {
    const benificiaire = new Beneficiaire();
    benificiaire.idRegime = enfant.regime;
    benificiaire.typeBenef = 3;
    benificiaire.dateNaissance = enfant.dateNaissance;
    benificiaire.sexe = false;
    benificiaire.nomJeuneFille = '';
    return benificiaire;
  }

  private createBeneficiaireFromAdherant() {
    const benificiaire = new Beneficiaire();
    benificiaire.nomBenef = this.adherentDTO.nom;
    benificiaire.prenomBenef = this.adherentDTO.prenom;
    benificiaire.dateNaissance = this.dateNaissance;
    benificiaire.idRegime = this.regimeTypeProfile;
    if (!this.showAssure) {
      benificiaire.sexe = this.souscripteur.civilite;
    } else {
      benificiaire.sexe = this.adherentDTO.civilite;
    }
    if (this.testForBlackSpaces(this.adherentDTO.nomNaissance)) {
      benificiaire.nomJeuneFille = this.adherentDTO.nomNaissance;
    }
    benificiaire.typeBenef = 1;
    return benificiaire;
  }

  private createDevis(devis: DevisModelDTO) {
    if (this.agentData == null) {
      devis.typeParcours = OavConstants.PACOURS_PROSPECT;
    } else {
      devis.idrhagent = this.agentData.idRHAgent;
      devis.typeParcours = OavConstants.PACOURS_AGENT;
    }
    devis.idProduit = OavConstants.PRODUIT_SANTE_ID;
    const sendDevis: DevisModelDTO = Object.assign({}, devis);
    sendDevis.beneficiaires = [];
    sendDevis.user.fakeEmail = this.noMailAddress;
    devis.beneficiaires.forEach(item => {
      sendDevis.beneficiaires.push(Object.assign({}, item));
    });
    sendDevis.dateAdhesion = this.oavDateService.saveUTCDate(sendDevis.dateAdhesion);
    sendDevis.beneficiaires.forEach(beneficiaire => {
      if (beneficiaire.dateNaissance) {
        beneficiaire.dateNaissance = this.oavDateService.saveUTCDate(beneficiaire.dateNaissance);
      }
    });
    this.devisService.createDevis(sendDevis).subscribe((data: CreatedDevisModel) => {
      this.spinner.hide();
      this.cookies.set(OavConstants.DEVIS, String(data.idDevis), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set(OavConstants.PRODUIT, String(devis.idProduit), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set(OavConstants.PARCOURS, String(devis.typeParcours), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      if (!this.cookies.get('accessToken')) {
        const user = new PublicUser(data.souscripteur.email, data.souscripteur.nom + data.souscripteur.prenom);
        this.createPublicToken(user);
      } else {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_PROFIL_SANTE
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.BESOIN_SANTE_URL]);
      }
    }, error => {
      this.spinner.hide();
      console.error('error when create a devis');
    });
  }

  private createPublicToken(user) {
    this.authService.createPublicToken(user).subscribe((result: any) => {
      this.cookies.set('accessToken', result.token, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set('refreshToken', result.refreshToken, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set('expires_in', result.expires_in, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_PROFIL_SANTE
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.BESOIN_SANTE_URL]);
    }, error => {
      this.spinner.hide();
    });
  }

  private initDevisToUpdate(devis) {
    const devisToUpdate = new DevisModelDTO();
    devisToUpdate.idDevis = this.devisId;
    devisToUpdate.dateAdhesion = devis.dateAdhesion;
    devisToUpdate.beneficiaires = devis.beneficiaires;
    devisToUpdate.souscripteur = devis.souscripteur;
    devisToUpdate.vente = this.devisDTO.vente;
    devisToUpdate.assure = !this.showAssure;
    devisToUpdate.resiliation = this.resiliation;
    devisToUpdate.user.fakeEmail = this.noMailAddress;
    if (this.testForBlackSpaces(devis.idRHApporteur)) {
      devisToUpdate.idRHApporteur = devis.idRHApporteur;
    }
    this.benef.forEach(benef => {
      devisToUpdate.beneficiaires.forEach(element => {
        if (element.typeBenef === 1 && benef.typeBenef === element.typeBenef) {
          element.id = benef.id;
        }
      });
    });
    return devisToUpdate;
  }

  private updateDevis(devis) {
    const devisToUpdate = this.initDevisToUpdate(devis);
    this.beneficiaireService.deleteAllEnfantsOfDevis(this.devisId).subscribe(rep => {
      this.devisService.getDevisById(this.devisId).subscribe((res: DevisModelDTO) => {
        const devisResult: any = res;
        if (!this.cookies.get(OavConstants.NEW_DEVIS)) {
          devisToUpdate.dateSejourFrom = devisResult.dateSejourFrom;
          devisToUpdate.dateSejourTo = devisResult.dateSejourTo;
          devisToUpdate.formule = devisResult.formule;
          devisToUpdate.modePaiement = devisResult.modePaiement;
        }
        const beneficiairesUpdatedDate: Beneficiaire[] = [];
        devisToUpdate.beneficiaires.forEach(item => {
          beneficiairesUpdatedDate.push(Object.assign({}, item));
        });
        beneficiairesUpdatedDate.forEach(beneficiaire => {
          if (beneficiaire.dateNaissance) {
            beneficiaire.dateNaissance = this.oavDateService.saveUTCDate(beneficiaire.dateNaissance);
          }
        });
        devisToUpdate.beneficiaires = beneficiairesUpdatedDate;
        this.devisService.updateDevisProfil(devisToUpdate).subscribe(result => {
          this.spinner.hide();
          this.cookies.set(OavConstants.DEVIS, this.devisId, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_PROFIL_SANTE
            , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          this.router.navigate([OavConstants.BESOIN_SANTE_URL]);
        }, error => {
          this.spinner.hide();
          console.error('error when update devis');
        });
      }, error => {
        this.spinner.hide();
        console.error('error when get devis by id');
      });
    }, error => {
      this.spinner.hide();
      console.error('error when delete children');
    });

  }

  validEmail(email) {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return (re.test(String(email).toLowerCase()) && email.length > 4 && email.length < 101);
  }

  validField(field, minLength, maxLength) {
    const re = /^[_'.@A-zÀ-ÿ- ]*$/;
    return re.test(String(field)) && String(field).length > minLength && String(field).length < maxLength &&
      this.testForBlackSpaces(String(field));
  }

  validForm() {
    if (this.agentData) {
      if (this.showAssure) {
        return (this.validAgent() && this.validAssureData() && this.validSouscripteur() && !this.dateAdhesionInvalide);
      } else {
        return (this.validAgent() && this.validAssureData() && !this.dateAdhesionInvalide);
      }
    } else {
      if (this.showAssure) {
        return (this.validAssureData() && this.validSouscripteur() && !this.dateAdhesionInvalide);
      } else {
        return (this.validAssureData() && !this.dateAdhesionInvalide);
      }
    }
  }

  private validAgent() {
    return (this.vente && this.validBoolean(this.toggleAcsValue) && this.validBoolean(this.showConjoint));
  }


  private validAssure() {
    if (this.showConjoint) {
      return (this.validField(this.souscripteur.nom, 0, 51) && this.validBoolean(this.souscripteur.civilite) &&
        this.souscripteur.nom && this.souscripteur.prenom && this.validField(this.souscripteur.prenom, 0, 51) &&
        this.validCodePostal && this.validEmail(this.adherentDTO.email) && this.adherentDTO.codePostal &&
        this.validBoolean(this.showConjoint) && this.validBoolean(this.toggleAcsValue) && this.validBoolean(this.resiliation) &&
        this.dateNaissance && !this.dateNaissInvalide && this.validBoolean(this.showAssure) && this.validConjoint());
    } else {
      return (this.validField(this.souscripteur.nom, 0, 51) && this.souscripteur.nom && this.souscripteur.prenom
        && this.validField(this.souscripteur.prenom, 0, 51) && this.validBoolean(this.souscripteur.civilite) &&
        this.validCodePostal && this.validBoolean(this.showConjoint) && this.validBoolean(this.toggleAcsValue) &&
        this.dateNaissance && !this.dateNaissInvalide && this.validEmail(this.adherentDTO.email) && this.adherentDTO.codePostal &&
        this.validBoolean(this.showAssure) && this.validBoolean(this.resiliation));
    }
  }

  private validAssureData() {
    if (this.childrenNb > 0) {
      return this.validAssure() && this.validChildren();
    } else {
      return this.validAssure();
    }
  }

  private validConjoint() {
    if (this.showConjoint) {
      if (this.conjointDateNaiss && !this.dateNaissConjInvalide) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private validChildren() {
    let validation = true;
    let dateExist;
    for (let i = 0; i < this.childrenDateNaissValidation.length; i++) {
      if (String(this.enfantList[i].dateNaissance) !== 'Invalid Date') {
        dateExist = true;
      } else {
        this.childrenDateNaissValidation[i] = false;
        this.enfantList[i].dateNaissance = null;
        dateExist = false;
      }
      validation = validation && this.childrenDateNaissValidation[i] && dateExist && this.childrenValidationDateNaissanceLimit[i];
    }
    return validation;
  }

  private validSouscripteur() {
    return (this.adherentDTO.nom && this.validField(this.adherentDTO.nom, 0, 51) && this.adherentDTO.prenom &&
      this.validField(this.adherentDTO.prenom, 0, 51) && this.validBoolean(this.adherentDTO.civilite)
      && this.dateNaissanceSouscripteur && !this.dateNaissanceSouscripteurInvalide);
  }

  validBoolean(b) {
    return (b === true || b === false);
  }

  resetForm() {
    this.adherentDTO = new Adherent();
    if (this.cookies.get('client')) {
      this.myForm.reset({ 'infoEmail': JSON.parse(this.cookies.get('client')).email });
      this.userConnected = true;
    } else {
      this.myForm.reset();
      this.userConnected = false;
    }
    this.userUI = new UserUI();
    this.enfantList = [];
    this.childrenNb = 0;
    this.regimeTypeConjoint = 1;
    this.regimeTypeProfile = 1;
    this.showConjoint = null;
    this.toggleAcsValue = null;
    this.adhesionDate = new Date(this.minDate);
    this.dateAdhesionInvalide = false;
    this.souscripteur = new SouscripteurModelDTO();
    this.tryToSubmitForm = false;
    this.dateNaissance = null;
    this.conjointDateNaiss = null;
    this.tryToSubmitForm = false;
    this.dateNaissanceDirty = false;
    this.codePostalDirty = false;
    this.showAssure = false;
    this.vente = null;
    this.cookies.delete(OavConstants.DEVIS);
    if (!this.cookies.get('agent') && !this.cookies.get('client')) {
      this.cookies.delete('accessToken');
    }
  }

  getAllRegimes() {
    this.regimeService.getAllRegimes().subscribe((res: Array<RegimeDTO>) => {
      this.regimes = res;
    });
  }

  getDevis() {
    if (this.devisId) {
      this.spinner.show();
      this.devisService.getDevisById(this.devisId).subscribe((data: DevisModelDTO) => {
        this.devis = data;
        this.benef = this.devis.beneficiaires;
        this.adresse = data.souscripteur.adressePostale;
        this.contact = data.souscripteur.contact;
        this.idSouscripteur = this.devis.souscripteur.id;
        this.renitData(this.devis);
        this.spinner.hide();
      }, error => {
        console.error('error when get devis by id');
        this.spinner.hide();
      });
    }
  }

  private renitAgentData(devis) {
    if (this.agentData) {
      if (this.devis.souscripteur.postier) {
        this.souscripteur.postier = true;
      } else {
        this.souscripteur.postier = false;
      }
      if (devis.vente === 'Vente face à face') {
        this.vente = 'face';
      }
      if (devis.vente === 'Vente à distance') {
        this.vente = 'distance';
      }
    }
  }

  private renitAdherant(devis: DevisModelDTO) {
    this.souscripteur.nom = devis.souscripteur.nom;
    this.souscripteur.prenom = devis.souscripteur.prenom;
    this.adherentDTO.email = devis.souscripteur.contact.email;
    devis.beneficiaires.forEach(element => {
      if (element.typeBenef === 1) {
        this.regimeTypeProfile = element.idRegime;
        this.dateNaissance = this.oavDateService.loadUTCDate(element.dateNaissance);
        this.souscripteur.nomNaissance = element.nomJeuneFille;
      } else if (element.typeBenef === 2) {
        this.renitConjoint(element);
      } else if (element.typeBenef === 3) {
        this.renitChild(element);
      }
    });
  }

  private renitSouscripteur(devis: DevisModelDTO) {
    this.souscripteur.nom = devis.souscripteur.nom;
    this.souscripteur.prenom = devis.souscripteur.prenom;
    this.adherentDTO.email = devis.souscripteur.contact.email;
    this.dateNaissanceSouscripteur = this.oavDateService.loadUTCDate(devis.souscripteur.dateNaissance);
    this.souscripteur.nomNaissance = devis.souscripteur.nomNaissance;
    devis.beneficiaires.forEach(element => {
      if (element.typeBenef === 1) {
        this.regimeTypeProfile = element.idRegime;
        this.dateNaissance = this.oavDateService.loadUTCDate(element.dateNaissance);
        this.adherentDTO.prenom = element.prenomBenef;
        this.adherentDTO.nom = element.nomBenef;
        this.adherentDTO.codePostal = devis.souscripteur.adressePostale.codePostale;
        this.verifCodePostal(this.adherentDTO.codePostal);
        this.adherentDTO.nomNaissance = element.nomJeuneFille;
        this.adherentDTO.civilite = element.sexe;
      } else if (element.typeBenef === 2) {
        this.renitConjoint(element);
      } else if (element.typeBenef === 3) {
        this.renitChild(element);
      }
    });
  }

  private renitConjoint(element) {
    this.showConjoint = true;
    this.regimeTypeConjoint = element.idRegime;
    this.conjointDateNaiss = this.oavDateService.loadUTCDate(element.dateNaissance);
    this.showConjoint = true;
  }

  private renitChild(element) {
    const enfant = new Enfant();
    enfant.prenom = (!element.prenom ? '' : element.prenom);
    enfant.nom = (!element.nom ? '' : element.nom);
    enfant.regime = element.idRegime;
    enfant.dateNaissance = this.oavDateService.loadUTCDate(element.dateNaissance);
    this.enfantList.push(enfant);
    this.childrenNb++;
    this.childrenValidationDateNaissanceLimit[this.childrenNb - 1] = true;
    this.childrenDateNaissValidation[this.childrenNb - 1] = true;
  }

  private renitData(devis: DevisModelDTO) {
    this.devis = new DevisModelDTO();
    this.devis.assure = devis.assure;
    this.noMailAddress = devis.user.fakeEmail;
    this.showAssure = !devis.assure;
    this.idRHApporteur = devis.idRHApporteur ? devis.idRHApporteur : '';
    this.enfantList = new Array<Enfant>();
    this.childrenNb = 0;
    this.adhesionDate = this.oavDateService.loadUTCDate(devis.dateAdhesion);
    this.adherentDTO.codePostal = devis.souscripteur.adressePostale.codePostale;
    this.verifCodePostal(this.adherentDTO.codePostal);
    this.validCodePostal = true;
    this.souscripteur.civilite = devis.souscripteur.sexe;
    this.toggleAcsValue = false;
    this.showConjoint = false;
    this.resiliation = devis.resiliation;
    this.renitAgentData(devis);
    if (devis.assure) {
      this.renitAdherant(devis);
    } else {
      this.renitSouscripteur(devis);
    }

  }

  changeShowAssure(event) {
    this.showAssure = event;
  }

  changetoggleAcsValue(value) {
    this.toggleAcsValue = value;
  }

  changeShowConjoint(value) {
    this.showConjoint = value;
  }

  private scrollToError() {
    if (this.agentData) {
      if (!this.vente) {
        this.scrollToElement('vente');
      } else {
        this.scrollIntoBloc();
      }
    } else {
      this.scrollIntoBloc();
    }
  }

  private scrollToElement(id) {
    document.getElementById(id).scrollIntoView(true);
  }

  private scrollIntoBloc() {
    if (!this.validBoolean(this.toggleAcsValue)) {
      this.scrollToElement('toggleAcs');
    } else {
      if (!this.validBoolean(this.souscripteur.civilite)) {
        this.scrollToElement('civilite');
      } else {
        if (!this.validField(this.souscripteur.prenom, 0, 51) || !this.souscripteur.prenom ||
          !this.testForBlackSpaces(this.souscripteur.prenom)) {
          this.scrollToElement('firstname');
        } else {
          if (!this.validField(this.souscripteur.nom, 0, 51) || !this.souscripteur.nom || !this.testForBlackSpaces(this.souscripteur.nom)) {
            this.scrollToElement('lastname');
          } else {
            if (!this.validEmail(this.adherentDTO.email)) {
              this.scrollToElement('email');
            } else {
              if (this.showAssure) {
                this.scrollIntoAssureBloc();
              } else {
                if (!this.validBoolean(this.resiliation)) {
                  this.scrollToElement('resiliationBlc');
                } else {
                  if (!this.dateNaissance || this.dateNaissInvalide || !this.controlDate(this.dateNaissance)) {
                    this.scrollToElement('dateNaissance');
                  } else {
                    if (!this.adhesionDate || this.dateAdhesionInvalide || !this.controlDate(this.adhesionDate)) {
                      this.scrollToElement('dateAdhesion');
                    } else {
                      if (!this.validCodePostal || this.adherentDTO.codePostal.length < 5 ||
                        !this.validNumeric(this.adherentDTO.codePostal) || !this.adherentDTO.codePostal ||
                        !this.testForBlackSpaces(this.adherentDTO.codePostal)) {
                        this.scrollToElement('cp');
                      } else {
                        if (!this.validBoolean(this.showConjoint)) {
                          this.scrollToElement('showConjoint');
                        } else {
                          if (this.showConjoint) {
                            if (!this.conjointDateNaiss || this.dateNaissConjInvalide || !this.controlDate(this.conjointDateNaiss)) {
                              this.scrollToElement('dateConj');
                            } else {
                              if (this.childrenNb > 0) {
                                this.scrollForChildren();
                              }
                            }
                          } else {
                            if (this.childrenNb > 0) {
                              this.scrollForChildren();
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  private scrollIntoAssureBloc() {
    if (!this.validBoolean(this.adherentDTO.civilite)) {
      this.scrollToElement('civiliteadh');
    } else {
      if (!this.dateNaissanceSouscripteur || this.dateNaissanceSouscripteurInvalide ||
        !this.controlDate(this.dateNaissanceSouscripteur)) {
        this.scrollToElement('dateNaissSouscripteur');
      } else {
        if (!this.validField(this.adherentDTO.prenom, 0, 51) || !this.adherentDTO.prenom ||
          !this.testForBlackSpaces(this.adherentDTO.prenom)) {
          this.scrollToElement('firstnameAdh');
        } else {
          if (!this.validField(this.adherentDTO.nom, 0, 51) || !this.adherentDTO.nom || !this.testForBlackSpaces(this.adherentDTO.nom)) {
            this.scrollToElement('lastnameadh');
          } else {
            if (!this.dateNaissance || this.dateNaissInvalide || !this.controlDate(this.dateNaissance)) {
              this.scrollToElement('dateNaissance');
            } else {
              if (!this.adhesionDate || this.dateAdhesionInvalide || !this.controlDate(this.adhesionDate)) {
                this.scrollToElement('dateAdhesion');
              } else {
                if (!this.validCodePostal || this.adherentDTO.codePostal.length < 5 ||
                  !this.validNumeric(this.adherentDTO.codePostal) || !this.adherentDTO.codePostal ||
                  !this.testForBlackSpaces(this.adherentDTO.codePostal)) {
                  this.scrollToElement('cp');
                } else {
                  if (!this.validBoolean(this.toggleAcsValue)) {
                    this.scrollToElement('toggleAcs');
                  } else {
                    if (!this.validBoolean(this.showConjoint)) {
                      this.scrollToElement('showConjoint');
                    } else {
                      if (this.showConjoint) {
                        if (!this.conjointDateNaiss || this.dateNaissConjInvalide || !this.controlDate(this.conjointDateNaiss)) {
                          this.scrollToElement('dateConj');
                        } else {
                          if (this.childrenNb > 0) {
                            this.scrollForChildren();
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  private scrollForChildren() {
    let i = 0;
    for (const enfant of this.enfantList) {
      if (!enfant.dateNaissance || !this.controlDate(enfant.dateNaissance) || !this.childrenValidationDateNaissanceLimit[i]) {
        this.scrollToElement('child' + i);
        break;
      } else {
        i++;
      }
    }
  }

  verifCodePostal(codePostal) {
    this.codePostalDirty = true;
    if (this.adherentDTO.codePostal && this.adherentDTO.codePostal.length === 5 && this.validNumeric(this.adherentDTO.codePostal) &&
      this.testForBlackSpaces(this.adherentDTO.codePostal)) {
      this.villeService.getByCodePostal(codePostal).subscribe((villes: Array<VilleDTO>) => {
        this.validCodePostal = villes.length > 0;
      }, error => {
        console.error('error while get villes by CP');
        this.validCodePostal = false;
      });
    } else {
      this.validCodePostal = false;
    }
  }

  validNumeric(num) {
    const re = /^[0-9]\d*$/;
    return re.test(String(num));
  }

  verifRefBancaire(data) {
    return (data.adresse && data.codePostale && data.bic && data.iban);
  }
  resetConjoint() {
    this.conjointDateNaiss = null;
    this.regimeTypeConjoint = 1;
  }

  controlDate(date) {
    const temp = new Date(date).getFullYear() >= 1523;
    return (new Date(date).toString() !== 'Invalid Date' && temp);
  }


  openAgreement(): Observable<any> {
    return this.modalService.openPopupSubscriber(AgreementComponent, { data: {} }, 'modal-agreement', true, false, true);
  }

  checkCss(value) {
    if (document.getElementById('resaslid2')) {
      if (value) {
        document.getElementById('resaslid2').classList.add('disabledblock');
      } else {
        document.getElementById('resaslid2').classList.remove('disabledblock');
      }
      setTimeout(() => {
        this.cssBlock = value;
      });
    }
  }

  resetDates(value) {
    this.dateNaissInvalide = false;
    this.dateNaissanceSouscripteurInvalide = false;
    this.dateNaissanceSouscripteurpickDirty = false;
    this.dateNaissanceDirty = false;
    if (value && this.dateNaissance) {
      this.dateNaissanceSouscripteur = this.dateNaissance;
      this.dateNaissance = null;
      this.dateNaissanceSouscripteurpickDirty = true;
      this.checkAgeSouscripteur();
    }
    if (!value && this.dateNaissanceSouscripteur) {
      this.dateNaissance = this.dateNaissanceSouscripteur;
      this.dateNaissanceSouscripteur = null;
      this.dateNaissanceDirty = true;
      this.checkAge();
    }
  }

  resetAssure() {
    const code = this.adherentDTO.codePostal;
    const email = this.adherentDTO.email;
    this.adherentDTO = new Adherent();
    this.adherentDTO.codePostal = code;
    this.adherentDTO.email = email;
  }

  verifEmailExist() {
    this.showUnfinishDevis = false;
  }
  redirectToLogin() {
    this.cookies.set('email', this.adherentDTO.email, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.LOGIN_URL]);
  }
  redirectToEspaceProspect() {
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
      , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.ESPACE_PROSPECT]);
  }
  connectedUserProcess(templateEmail, result: ListDevisDTO) {
    this.spinner.show();
    if (result && result.listDevisEnCours) {
      this.showUnfinishDevis = true;
      this.openModal(templateEmail);
    } else {
      this.prepareDevis();
    }
  }

  testForBlackSpaces(value) {
    if (value) {
      const temp = value;
      return temp.toString().replace(/\s/g, '');
    }
    return false;
  }

  isCurrentBrowserIe11(): boolean {
    return (navigator.userAgent.indexOf('Trident')) ? true : false;
  }


  deleteBlanks(event) {
    event.preventDefault();
    event.defaultPrevented = false;
  }

  updateAdhesionDate() {
    this.dateAdhesionInvalide = false;
    if (this.resiliation) {
      this.minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 33);
      this.adhesionDate = new Date(this.minDate);
    } else {
      this.minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
      this.adhesionDate = new Date(this.minDate);
    }
  }

  trackByFn(index, item) {
    return index;
  }

  deleteClientData() {
    if (this.cookies.get('client')) {
      this.cookies.delete('client');
    }
  }

  openGeolocalisation() {
    this.santiclaireService.getGeoclaireUrl().subscribe((data: any) => {
      window.open(data.location, '_blank');
    }, error => {
      console.error('error when get santiclaire');
    });
  }

  private makeFakeEmail(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let strEmail = '';
    for (let i = 0; i < 10; i++) {
      strEmail = strEmail + characters.charAt(Math.round(characters.length * Math.random()));
    }
    strEmail = strEmail + '@';
    for (let j = 0; j < 8; j++) {
      strEmail = strEmail + characters.charAt(Math.round(characters.length * Math.random()));
    }
    strEmail = strEmail + '.com';
    return strEmail;
  }

  noMailAddressChange(): void {
    this.adherentDTO.email = this.cookies.check(OavConstants.DEVIS) ?
    JSON.parse(this.cookies.get('client')).email : (this.noMailAddress ? this.makeFakeEmail() : null);
  }
}

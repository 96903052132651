import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {TrakingParamConstants} from '../../shared/data/TrakingParamValues';
import {TagCommanderSevice} from '../../shared/services/tag-Commander.sevice';

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.css']
})
export class MentionsLegalesComponent implements OnInit {

  private canal: string;

  constructor(private cookies: CookieService, private readonly elementRef: ElementRef,
              private renderer: Renderer2, private tagCommanderSevice: TagCommanderSevice) {
  }

  ngOnInit() {
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.TRANSVERSE_MENTIONS_LEGALES_PAGENAME, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.TRANSVERSE_MENTIONS_LEGALES_PAGENAME, this.renderer, this.elementRef);


    if (this.cookies.get('mlg')) {
      if (this.cookies.get('mlg') === 'personal data') {
        this.scrollToElement('pdata');
      } else if (this.cookies.get('mlg') === 'mentions legales') {
        this.scrollToElement('mgl');
      } else if (this.cookies.get('mlg') === 'cookies') {
        this.scrollToElement('cookies');
      }
    }
  }

  private scrollToElement(id) {
    document.getElementById(id).scrollIntoView(true);
  }

}

import {Component, HostBinding, HostListener, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router} from '@angular/router';
import smoothscroll from 'smoothscroll-polyfill';
import { OavConstants } from './shared/data/OavConstants';
import { CookieService } from 'ngx-cookie-service';
import { RoleConstants } from './shared/data/RoleConstants';
import { UserService } from './core/services/user.service';
import { AppConfig } from './app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  show = true;
  token;
  @HostBinding('class') defaultClass = 'owl-carousel';
  name: string;
  decryptedToken: Object;
  refreshToken;
  expire_in;
  fixedFooter = false;

  /** Handling User Inactivity */
  userActivityEvent: any;

  @HostListener('document:click', ['$event'])
  @HostListener('document:keydown', ['$event'])
  userActivityEventFn(event) {
    this.userActivityEvent = event;
  }

  constructor(private cookies: CookieService, translate: TranslateService, private router: Router, private activatedRoute: ActivatedRoute,
    private userService: UserService) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('fr');
    const browserLang = translate.getBrowserLang();
    smoothscroll.polyfill();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    // this.show = !window.location.href.includes('/login') || this.callbackService.getShow();
    this.checkTokenValidity();

  }
  ngOnInit() {
 }

  checkTokenValidity() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token;
      this.refreshToken = params.rf;
      this.expire_in = params.exp;
      if (this.token != null) {
        this.cookies.set('accessToken', this.token, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.userService.getConnectedUser().subscribe(data => {
          this.decryptedToken = data;
          this.cookies.set('_ext', '_010');
          this.redirectUserSwitchRole(this.decryptedToken);
        }, error => {
          this.cookies.delete('accessToken');
          this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
        });
      }
    });
  }

  redirectUserSwitchRole(decryptedToken) {
    const globalData = decryptedToken.globalExternalData;
    const role = decryptedToken.authorities[0].authority;
    this.initCookies(globalData);
    if (globalData.produit === OavConstants.PRODUIT_OAV && globalData.agentData) {
      this.router.navigate([OavConstants.OAV_REDIRECTION]);
    } else {
      if (role === RoleConstants.AGENT || role === RoleConstants.EXT_AGENT) {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_AGENT
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.ESPACE_AGENT]);
      } else if (role === RoleConstants.AGENT_CLIENT || role === RoleConstants.CLIENT) {
        this.redirectClient(globalData);
      } else {
        if (globalData.produit === OavConstants.PRODUIT_SANTE || globalData.produit === OavConstants.PRODUIT_CI) {
          this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
        } else {
          this.router.navigate([OavConstants.PROFIL_ACDS_URL]);
        }
      }
    }
  }

  private initCookies(globalData) {
    if (globalData.agentData) {
      this.cookies.set('agent', JSON.stringify(globalData.agentData), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set('userId', globalData.agentData.agentUid, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    }
    if (globalData.clientData) {
      this.cookies.set('client', JSON.stringify(globalData.clientData), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    }
    if (globalData.produit) {
      this.cookies.set('produit', globalData.produit, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    }
  }

  private redirectClient(globalData) {
    this.userService.getUserByIdExterne(globalData.clientData.idExterne).subscribe(data => {
      if (data) {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.ESPACE_PROSPECT]);
      } else if (globalData.produit === OavConstants.PRODUIT_SANTE || globalData.produit === OavConstants.PRODUIT_CI) {
        this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
      } else {
        this.router.navigate([OavConstants.PROFIL_ACDS_URL]);
      }
    }, error => {
      this.cookies.delete('accessToken');
      if (globalData.produit === OavConstants.PRODUIT_SANTE || globalData.produit === OavConstants.PRODUIT_CI) {
        this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
      } else {
        this.router.navigate([OavConstants.PROFIL_ACDS_URL]);
      }
    });
  }

  isAgent(): boolean {
    return this.cookies.check(OavConstants.AGENT);
  }

}

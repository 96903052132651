import { AfterViewInit, Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { CallBakcDTO } from 'src/app/features/process-sante/models/CallBackDTO';
import { NgxSpinnerService } from 'ngx-spinner';
import { DevisService } from 'src/app/features/process-sante/services/devis.service';
import { DevisModelDTO } from 'src/app/features/process-sante/models/devisDTO';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from 'src/app/core/services/user.service';
import { SouscripteurService } from 'src/app/features/process-sante/services/souscripteur.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { CallBackService } from '../../services/call-back.service';
import { OavConstants } from '../../data/OavConstants';
import {ClickType, TrakingParamConstants} from '../../data/TrakingParamValues';
import {TagCommanderSevice} from '../../services/tag-Commander.sevice';

@Component({
  selector: 'app-call-back',
  templateUrl: './callBack.component.html',
})
export class CallBackComponent implements OnInit, AfterViewInit {
  modalRef: BsModalRef;
  resumeRDV = false;
  rdv: any;
  tryToSubmitForm = false;
  devisId;
  immediatCall: any;
  resumeImmediat = false;
  hours = [];
  bsConfig: Partial<BsDatepickerConfig>;
  minDate: Date;
  maxDate: Date;
  telError = false;
  telErrorRDV = false;
  quest1;
  quest2;
  toggleCodeValue;
  addHeight;
  recaptcha = false;
  siteKey = '6LcA-uEUAAAAAHERpRri7dPKJVG-_u3RM5gtYaj4';
  validCaptcha = false;
  validCaptchaCall = false;
  recaptchaCall = false;
  telRdvBlur = true;
  telBlur = true;
  codeKiamo;
  errorHour;
  size: string;
  dateFocusout = false;
  datesDisabled = [];
  displayImmediat = false;
  displayRdv = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.size = (event.target.innerWidth > 465) ? 'normal' : 'compact';
  }

  constructor(private devisService: DevisService, private userService: UserService,
              private souscripteurService: SouscripteurService, private spinner: NgxSpinnerService, private localeService: BsLocaleService,
              private cookies: CookieService, private callBackService: CallBackService , private tagCommanderSevice: TagCommanderSevice
             ) {
    frLocale.invalidDate = '';
    defineLocale('fr', frLocale);
    this.localeService.use('fr');
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', isAnimated: true, showWeekNumbers: false });
  }

  ngOnInit() {
    this.initCallBackBloc();
    this.isDisplayImmediat();
    this.isDisplayRendezVous();
  }

  ngAfterViewInit() {
    this.size = (window.screen.width > 465) ? 'normal' : 'compact';
  }

  openModal() {
    this.tagCommanderSevice.trackingClick(TrakingParamConstants.CONTACTER_CONSEILLER_HUB_CLICKLABEL, ClickType.ACTION);
    this.initCallBackBloc();
    this.quest1 = false;
    this.quest2 = false;
    this.toggleCodeValue = null;
    this.addHeight = false;
  }

  initCallBackBloc() {
    this.telErrorRDV = false;
    this.telError = false;
    this.rdv = new CallBakcDTO();
    this.immediatCall = new CallBakcDTO();
    this.devisId = this.cookies.get('devis');
    this.initMinDate();
    this.maxDate = new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate());
    this.rdv.dateRappel = this.minDate;
    this.initTime(this.rdv.dateRappel);
    this.rdv.datePref = this.hours[0];
    this.initDisabledDates();
    if (this.devisId != null || this.devisId !== undefined) {
      this.initData();
    }
  }

  private initMinDate(): void {
    const today = new Date();
    const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    const nextDay = new Date(tomorrow.getTime() + 24 * 60 * 60 * 1000);
    this.minDate = today.getDay() === 0 ? tomorrow :
    ((today.getDay() === 6 && (today.getHours() > 12 || (today.getHours() === 12 && today.getMinutes() >= 30))) ? nextDay : today);
  }

  verifMobilePattern(tel) {
    const re = /^[0-9]\d*$/;
    return re.test(tel);
  }

  initTime(selectedDate) {
    this.hours = [];
    const today = new Date();
    let firstHour = 0;
    if (this.isToday(selectedDate) && today.getHours() > 8) {
      firstHour = + today.toLocaleTimeString().replace(/[^\x00-\x7F]/g, '').split(':')[0];
    } else {
      firstHour = 9;
    }
    if ((firstHour < 12 && new Date(selectedDate).getDay() === 6) ||
    (new Date(selectedDate).getDay() !== 6 && this.isToday(selectedDate) &&
    new Date(selectedDate).getHours() > 8 && new Date(selectedDate).getHours() < 18)
    || !this.isToday(selectedDate)) {
      this.hours.push(firstHour + 'h/' + (firstHour + 1) + 'h');
    }
    if (new Date(selectedDate).getDay() === 6) {
      while (firstHour < 12) {
        firstHour = firstHour + 1;
        if (firstHour === 12) {
          this.hours.push(firstHour + 'h30');
        } else if (firstHour < 12) {
          this.hours.push(firstHour + 'h/' + (firstHour + 1) + 'h');
        }
      }
    } else {
      while (firstHour < 17) {
        firstHour = firstHour + 1;
        if (firstHour === 17) {
          this.hours.push(firstHour + 'h30');
        } else {
          this.hours.push(firstHour + 'h/' + (firstHour + 1) + 'h');
        }
      }
    }
    if (!this.hours.includes(this.rdv.datePref)) {
      this.rdv.datePref = this.hours[0];
    }
    this.errorHour = false;
  }

  isToday(someDate) {
    const today = new Date();
    return (someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()) || (someDate < today);
  }

  resetRdv() {
    this.rdv = new CallBakcDTO();
    this.tryToSubmitForm = false;
    this.telErrorRDV = false;
  }

  createRDV() {
    this.tagCommanderSevice.trackingClick(TrakingParamConstants.CONTACTER_CONSEILLER_VALIDER, ClickType.ACTION);
    this.spinner.show();
    if (!this.verifRDV()) {
      this.tryToSubmitForm = true;
    } else {
      this.resumeRDV = true;
      this.tryToSubmitForm = false;
      this.rdv.isRdv = true;
      if (this.devisId != null || this.devisId !== undefined) {
        this.rdv.idDevis = this.devisId;
      }
      this.rdv.produit = this.cookies.get(OavConstants.PRODUIT);
      this.callBackService.createCallBack(this.rdv).subscribe((res: any) => {
        this.codeKiamo = res.code;
        this.spinner.hide();
      }, error => {
        this.codeKiamo = '500';
        this.spinner.hide();
      });
    }
    this.spinner.hide();
  }

  createImmediatCall() {
    this.tagCommanderSevice.trackingClick(TrakingParamConstants.CONTACTER_CONSEILLER_VALIDER, ClickType.ACTION);
    if (!this.verifImmediatCall()) {
      this.tryToSubmitForm = true;
    } else {
      this.resumeImmediat = true;
      this.tryToSubmitForm = false;
      this.immediatCall.isRdv = false;
      if (this.devisId != null || this.devisId !== undefined) {
        this.immediatCall.idDevis = this.devisId;
      }
      this.immediatCall.produit = this.cookies.get(OavConstants.PRODUIT);
      this.spinner.show();
      this.callBackService.createCallBack(this.immediatCall).subscribe((res: any) => {
        this.codeKiamo = res.code;
        this.spinner.hide();
      }, error => {
        this.codeKiamo = '500';
        this.spinner.hide();
      });
    }
  }

  verifRDV() {
    const validatePrenom = (this.rdv.prenom != null || this.rdv.prenom !== undefined);
    const validateNom = (this.rdv.nom != null || this.rdv.nom !== undefined);
    const validateCivilite = (this.rdv.civilite != null || this.rdv.civilite !== undefined);
    const validateDateRappel = (this.rdv.dateRappel != null || this.rdv.dateRappel !== undefined);
    const validateDatePref = (this.rdv.datePref != null || this.rdv.datePref !== undefined);
    const validateTel = ((this.rdv.telephone != null || this.rdv.telephone !== undefined) && this.verifMobilePattern(this.rdv.telephone));
    return validateTel && validatePrenom && validateNom && validateCivilite && validateDateRappel && validateDatePref && !this.telErrorRDV
      && this.recaptcha && this.verifDatePref(this.rdv.dateRappel, this.rdv.datePref) && this.isAvailableDate(this.rdv.dateRappel);
  }

  verifImmediatCall() {
    const validatePrenom = (this.immediatCall.prenom != null || this.immediatCall.prenom !== undefined);
    const validateNom = (this.immediatCall.nom != null || this.immediatCall.nom !== undefined);
    const validateCivilite = (this.immediatCall.civilite != null || this.immediatCall.civilite !== undefined);
    const validateTel = ((this.immediatCall.telephone != null || this.immediatCall.telephone !== undefined)
      && this.verifMobilePattern(this.immediatCall.telephone));
    return validateTel && validatePrenom && validateNom && validateCivilite
      && !this.telError && this.recaptchaCall;
  }

  resetImmediatCall() {
    this.immediatCall = new CallBakcDTO();
    this.tryToSubmitForm = false;
    this.telError = false;
  }

  checkDate(date) {
    setTimeout(() => {
      if (this.rdv.dateRappel != null && this.rdv.dateRappel !== undefined) {
        this.hours = [];
        this.initTime(this.rdv.dateRappel);
      } else {
        this.rdv.datePref = '';
      }
    }, 1000);
  }

  lengthTel(telephone) {
    const tel = telephone as String;
    if (tel.length < 10) {
      this.telError = true;
    } else {
      this.telError = false;
    }
  }

  lengthTelRDV(telephone) {
    const tel = telephone as String;
    if (tel.length < 10) {
      this.telErrorRDV = true;
    } else {
      this.telErrorRDV = false;
    }
  }

  initData() {
    if (this.cookies.get('client')) {
      const client = JSON.parse(this.cookies.get('client'));
      if (client.email) {
        this.userService.getUserByUsername(client.email).subscribe((user: any) => {
          this.souscripteurService.getByUser(user.id).subscribe((data: any) => {
            this.initRDV(data);
            this.initCall(data);
          }, error => {
            console.error('error when get souscripteur by user');
          });
        }, error => {
          console.error('error when get user by username');
        });
      } else {
        this.userService.getUserByIdExterne(client.idExterne).subscribe((user: any) => {
          if (user) {
            this.souscripteurService.getByUser(user.id).subscribe((data: any) => {
              this.initRDV(data);
              this.initCall(data);
            }, error => {
              console.error('error when get souscripteur by user');
            });
          }
        }, error => {
          console.error('error when get user by username');
        });
      }
    } else if (this.cookies.get('devis')) {
      this.devisService.getDevisById(this.devisId).subscribe((res: DevisModelDTO) => {
        this.initRDV(res.souscripteur);
        this.initCall(res.souscripteur);
      }, error => {
      });
    }
  }

  initRDV(souscripteur) {
    this.rdv.nom = souscripteur.nom;
    this.rdv.prenom = souscripteur.prenom;
    this.rdv.civilite = souscripteur.sexe ? 'Mme' : 'M';
    this.rdv.telephone = souscripteur.contact.telMobile;
  }
  initCall(souscripteur) {
    this.immediatCall.nom = souscripteur.nom;
    this.immediatCall.prenom = souscripteur.prenom;
    this.immediatCall.civilite = souscripteur.sexe ? 'Mme' : 'M';
    this.immediatCall.telephone = souscripteur.contact.telMobile;
  }
  handleSuccess(event) {
    this.recaptcha = true;
  }

  handleLoad() {
    this.recaptcha = false;
  }

  handleExpire() {
    this.recaptcha = false;
  }

  handleReset() {
    this.recaptcha = false;
  }

  handleSuccessCall(event) {
    this.recaptchaCall = true;
  }

  handleLoadCall() {
    this.recaptchaCall = false;
  }

  handleExpireCall() {
    this.recaptchaCall = false;
  }

  handleResetCall() {
    this.recaptchaCall = false;
  }

  verifDatePref(dateRappel, datePref) {
    if (dateRappel.toLocaleDateString() === (new Date().toLocaleDateString())) {
      if ((datePref.replace(/[^\x00-\x7F]/g, '').split('/')[0].replace(/[^\x00-\x7F]/g, '').split('h')[0]) >= new Date().getHours() + 1) {
        this.errorHour = false;
        return true;
      } else {
        this.errorHour = true;
        return false;
      }
    } else {
      this.errorHour = false;
      return true;
    }
  }

  trackByFn(index, item) {
    return index;
  }

  closeRendezVous() {
    this.quest1 = false;
    this.quest2 = false;
    this.initData();
    this.addHeight = false;
    this.resumeRDV = false;
  }

  closePopup() {
    this.initData();
    this.quest1 = false;
    this.quest2 = false;
    this.toggleCodeValue = null;
    this.addHeight = false;
  }

  rappelImmediat() {
    this.tagCommanderSevice.trackingClick(TrakingParamConstants.CONTACTER_CONSEILLER_IMMEDIATEMENT_CLICKLABEL, ClickType.ACTION);
    this.quest1 = false;
    this.quest2 = true;
    this.initData();
    this.addHeight = true;
    this.toggleCodeValue = true;
    this.tryToSubmitForm = false;
  }

  rappelRendezVous() {
    this.tagCommanderSevice.trackingClick(TrakingParamConstants.CONTACTER_CONSEILLER_SUR_RDV_CLICKLABEL, ClickType.ACTION);
    this.quest1 = true;
    this.quest2 = false;
    this.initData();
    this.addHeight = true;
    this.toggleCodeValue = false;
    this.tryToSubmitForm = false;
  }

  closeImmediat() {
    this.quest1 = false;
    this.quest2 = false;
    this.initData();
    this.addHeight = false;
    this.resumeImmediat = false;
  }

  initDisabledDates(): void {
    const paquesMax = this.calculatesPaques(this.maxDate.getFullYear());
    const paquesMin = this.calculatesPaques(this.minDate.getFullYear());
    // Le lundi de Pâques
    this.datesDisabled.push(this.incrementDate(paquesMax, 1));
    this.datesDisabled.push(this.incrementDate(paquesMin, 1));
    // Le jeudi de l’Ascension
    this.datesDisabled.push(this.incrementDate(paquesMax, 39));
    this.datesDisabled.push(this.incrementDate(paquesMin, 39));
    // Le lundi de Pentecôte
    this.datesDisabled.push(this.incrementDate(paquesMax, 50));
    this.datesDisabled.push(this.incrementDate(paquesMin, 50));
    // Le jour de l’an
    this.datesDisabled.push(new Date(this.maxDate.getFullYear(), 0, 1));
    this.datesDisabled.push(new Date(this.minDate.getFullYear(), 0, 1));
    // La fête du Travail
    this.datesDisabled.push(new Date(this.maxDate.getFullYear(), 4, 1));
    this.datesDisabled.push(new Date(this.minDate.getFullYear(), 4, 1));
    // La victoire de 1945
    this.datesDisabled.push(new Date(this.maxDate.getFullYear(), 4, 8));
    this.datesDisabled.push(new Date(this.minDate.getFullYear(), 4, 8));
    // La fête nationale
    this.datesDisabled.push(new Date(this.maxDate.getFullYear(), 6, 14));
    this.datesDisabled.push(new Date(this.minDate.getFullYear(), 6, 14));
    // L’Assomption
    this.datesDisabled.push(new Date(this.maxDate.getFullYear(), 7, 15));
    this.datesDisabled.push(new Date(this.minDate.getFullYear(), 7, 15));
    // La Toussaint
    this.datesDisabled.push(new Date(this.maxDate.getFullYear(), 10, 1));
    this.datesDisabled.push(new Date(this.minDate.getFullYear(), 10, 1));
    // L’Armistice
    this.datesDisabled.push(new Date(this.maxDate.getFullYear(), 10, 11));
    this.datesDisabled.push(new Date(this.minDate.getFullYear(), 10, 11));
    // Noël
    this.datesDisabled.push(new Date(this.maxDate.getFullYear(), 11, 25));
  }

  // calcul de dimanche de paques
  private calculatesPaques(year: number): Date {
    const n = year % 19;
    const c = Math.trunc(year / 100);
    const u = year % 100;
    const s = Math.trunc(c / 4);
    const t = c % 4;
    const p = Math.trunc((c + 8) / 25);
    const q = Math.trunc((c - p + 1) / 3);
    const e = ((19 * n) + c - s - q + 15) % 30;
    const b = Math.trunc(u / 4);
    const d = u % 4;
    const l = ((2 * t) + (2 * b) - e - d + 32) % 7;
    const h = Math.trunc((n + (11 * e) + (22 * l)) / 451);
    const m = Math.trunc((e + l - (7 * h) + 114) / 31);
    const j = (e + l - (7 * h) + 114) % 31;
    return new Date(year, m - 1, j + 1);
  }

  private incrementDate(date: Date, nbDays: number): Date {
    return new Date(date.getTime() + (1000 * 60 * 60 * 24) * nbDays);
  }

  isAvailableDate(date): boolean {
    for (const element of this.datesDisabled) {
      if ((new Date(date).getDate() === element.getDate() && new Date(date).getMonth() === element.getMonth() &&
        new Date(date).getFullYear() === element.getFullYear()) || new Date(date).getDay() === 0) {
        return false;
      }
    }
    return true;
  }

  private isDisplayRendezVous(): void {
    this.displayRdv = (new Date().getDay() === 0 && (new Date().getHours() < 20 || new Date().getHours() >= 23)) ||
      (new Date().getDay() !== 0 && new Date().getHours() !== 20);
  }

  private isDisplayImmediat(): void {
    if (this.isAvailableDate(new Date)) {
      if (new Date().getDay() === 6) {
        this.displayImmediat = new Date().getHours() === 12 ? new Date().getMinutes() <= 30 :
          new Date().getHours() >= 9 && new Date().getHours() < 12;
      } else {
        this.displayImmediat = new Date().getHours() === 17 ? new Date().getMinutes() <= 30 :
          new Date().getHours() >= 9 && new Date().getHours() < 17;
      }
    } else {
      this.displayImmediat = false;
    }
  }

  isLatestHour(datePref): boolean {
    return datePref === this.hours[this.hours.length - 1];
  }

}

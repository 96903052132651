import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../services/user.service';
import { ReinitPasswordObj } from '../../models/reinitPasswordObj';
import { EncryptionDecryptionService } from '../../../shared/services/encryption-decryption.service';
import { AuthenticationUtilitiesService } from '../../services/authenticationUtilities.service';
import { LoginCredentials } from '../../models/loginObj';
import { AuthService } from '../../../shared/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { OavConstants } from 'src/app/shared/data/OavConstants';

@Component({
  selector: 'app-reinit-password',
  templateUrl: './reinit-password.component.html',
  styleUrls: ['./reinit-password.component.css']
})
export class ReinitPasswordComponent implements OnInit {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  confirmationPassswordError: boolean;
  username: string;
  token: string;
  isOldPasswordCorrect = true;
  isNewPasswordValid = true;
  accountExpired = false;
  accountLocked = false;
  successReinitPwd = false;
  trySubmit: boolean;
  @ViewChild('reinitPasswordForm') firstConnexionForm;

  constructor(private spinner: NgxSpinnerService, private cookies: CookieService,
    private userService: UserService, private encryptionDecryptionService: EncryptionDecryptionService,
    private authUtilities: AuthenticationUtilitiesService, private authService: AuthService) { }

  ngOnInit() {
    this.username = this.cookies.get(OavConstants.USER_TO_MODIF);
  }

  resetForm() {
    this.isOldPasswordCorrect = true;
    this.isNewPasswordValid = true;
    this.firstConnexionForm.reset();
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.trySubmit = false;
  }

  reinitPassword() {
    this.trySubmit = true;
    this.spinner.show();
    if (this.validForm()) {
      this.updateUserPassword();
    } else {
      this.spinner.hide();
    }
  }

  updateUserPassword() {
    this.accountExpired = false;
    this.accountLocked = false;
    this.isOldPasswordCorrect = true;
    this.isNewPasswordValid = true;
    this.successReinitPwd = false;
    const reinitPasswordObject = new ReinitPasswordObj();
    reinitPasswordObject.username = this.username;
    reinitPasswordObject.oldPassword = this.encryptionDecryptionService.encryptPassword(this.oldPassword);
    reinitPasswordObject.newPassword = this.encryptionDecryptionService.encryptPassword(this.newPassword);
    this.spinner.show();
    this.userService.reinitialisePassword(reinitPasswordObject).subscribe((response: any) => {
      this.successReinitPwd = true;
      const credentials = new LoginCredentials();
      credentials.password = reinitPasswordObject.newPassword;
      credentials.username = this.username;
      this.authService.login(credentials).subscribe((data: any) => {
        this.authUtilities.clearStorage();
        this.authUtilities.updateCookie(data);
        this.authUtilities.processAfterConnection(false);
      }, error => {
        this.spinner.hide();
      });
    }, error => {
      this.onFailReinit(error);
      this.spinner.hide();
    });
  }

  onFailReinit(error) {
    if (error) {
      if (error.errorCode === 19) {
        this.accountExpired = true;
      } else if (error.errorCode === 12) {
        this.accountLocked = true;
      } else if (error.errorCode === 14) {
        this.isOldPasswordCorrect = false;
      } else if (error.errorCode === 15) {
        this.isNewPasswordValid = false;
      }
    }
  }

  initOldPasswordErrorMessages() {
    this.isOldPasswordCorrect = true;
  }

  initNewPasswordErrorMessages() {
    this.isNewPasswordValid = true;
  }

  validForm() {
    return (this.validPassword() && (this.newPassword === this.confirmPassword));
  }

  validPassword() {
    const lettersRe = /[a-zA-Z]+/;
    const digitRe = /\d+/;
    const specialCharacterRe = /\W+/;
    if (this.newPassword && this.newPassword !== this.username) {
      return ((this.newPassword.length >= 10) && specialCharacterRe.test(this.newPassword)
        && (digitRe.test(this.newPassword)) && (lettersRe.test(this.newPassword)));
    }
    return false;
  }

  checkConfirmationPasswordError() {
    this.confirmationPassswordError = this.newPassword && this.confirmPassword && this.newPassword !== this.confirmPassword;
  }

}

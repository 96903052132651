import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfig} from 'src/app/app.config';
import {OavUrlConstants} from '../data/OavURLConstants';
import {CreatePaymentResponse} from '../../core/models/CreatePayment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  publicUri = AppConfig.securedEndpoint + OavUrlConstants.PAYMENT_ENDPOINT;

  constructor(private http: HttpClient) {
  }

  getPaymentInfo(data) {
    return this.http.post(this.publicUri + OavUrlConstants.CR, data);
  }

  getLatestByDevis(devis) {
    return this.http.get(this.publicUri + OavUrlConstants.LATEST_PAYMENT_BY_DEVIS + '/' + devis);
  }

  createPayement(idDevis) {
    return this.http.get<CreatePaymentResponse>(this.publicUri + '/create/payement/' + idDevis);
  }

  afterPayement(data, trans_id, idDevis) {
    const request = JSON.parse(data);
    request.idDevis = '' + idDevis;
    request.trans_id = trans_id;
    return this.http.post(this.publicUri + '/cb/transaction' + OavUrlConstants.CR, request);
  }

  getSceillusPayement(tran_id, idDevis) {
    return this.http.get(this.publicUri + '/scelliuspaiement' + OavUrlConstants.GET + '/' + idDevis + '/' + tran_id);
  }
}

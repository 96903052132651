import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {TrakingParamConstants} from '../../shared/data/TrakingParamValues';
import {ModalService} from '../../shared/services/modal.service';
import {FilterService} from '../../features/process-sante/services/filter.service';
import {TagCommanderSevice} from '../../shared/services/tag-Commander.sevice';
import {CookiesEntriesModel} from '../../shared/models/cookiesEntries.Model';
import {AppConfig} from '../../app.config';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {

  private canal: string;

  constructor(private cookies: CookieService, private router: Router,
              private readonly elementRef: ElementRef, private renderer: Renderer2,
              private tagCommanderSevice: TagCommanderSevice ) { }

  ngOnInit() {
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.TRANSVERSE_COOKIES_PAGENAME, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.TRANSVERSE_COOKIES_PAGENAME, this.renderer, this.elementRef);

  }

  paramCookiesTagCommander() {
    // @ts-ignore
    tC.privacy.showPrivacyCenter();

  }

}

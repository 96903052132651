import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import { Router } from '@angular/router';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { NgxSpinnerService } from 'ngx-spinner';
import { DevisModelDTO } from '../../models/devisDTO';
import { DevisService } from '../../services/devis.service';
import { FormulesService } from '../../services/formules.service';
import { BdocService } from '../../services/bdoc.service';
import { BdocDTO } from '../../models/bdocDTO';
import { CookieService } from 'ngx-cookie-service';
import { DocumentStatiqueService } from 'src/app/shared/services/document-statique.service';
import { DocumentStatiqueModel } from 'src/app/shared/models/documentStatiqueModel';
import { GuardService } from 'src/app/shared/services/guard.service';
import { AppConfig } from 'src/app/app.config';
import { SanticlaireService } from 'src/app/shared/services/santiclaire.service';
import {ClickType, TrakingParamConstants} from '../../../../shared/data/TrakingParamValues';
import {ModalService} from '../../../../shared/services/modal.service';
import {TagCommanderSevice} from '../../../../shared/services/tag-Commander.sevice';


@Component({
  selector: 'app-formules-sante',
  templateUrl: './resumeFormule.component.html'

})

export class ResumeFormuleSanteComponent implements OnInit {

  @Input() inputIdDevis: string;
  @Input() expired = false;
  @Input() abandonned = false;
  @Output() emitClose = new EventEmitter();
  @Input() isAgent = false;

  items = [{
    label: 'Mon Profil'
  },
  {
    label: 'Mes besoins'
  },
  {
    label: 'Formules'
  },
  {
    label: 'Votre devis'
  }
  ];
  activeIndex = 3;
  toggleCodeValue;
  devisId: string;
  devis: any;
  nbBenefs: any;
  refDevis;
  dateFinDevis;
  formuleLabel;
  prenom;
  nom;
  valDevis;
  bdocDto = new BdocDTO();
  data: any;
  email;
  showDocs;
  parcours = this.cookies.get(OavConstants.PARCOURS);
  listDocumentStatique: DocumentStatiqueModel[] = [];
  docsOpen = false;
  agentCRC = false;
  private canal: string;

  constructor(private cookies: CookieService, private router: Router, private devisService: DevisService,
              private formulesService: FormulesService, private bdocService: BdocService, private spinner: NgxSpinnerService,
              private documentStatiqueService: DocumentStatiqueService, private guardService: GuardService,
              private santiclaireService: SanticlaireService, private tagCommanderSevice: TagCommanderSevice,
              private readonly elementRef: ElementRef, private renderer: Renderer2, private modalService: ModalService) {
  }

  ngOnInit() {
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.SANTE_DEVIS_ETAPE4_RECAPITULATIF_PAGENAME, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.SANTE_DEVIS_ETAPE4_RECAPITULATIF_PAGENAME, this.renderer, this.elementRef);
    if (this.cookies.get('agent')) {
      const agent = JSON.parse(this.cookies.get('agent'));
      this.agentCRC = agent.profil === '53' || agent.profil === '33';
    }
    this.checkForCurrentScreen();
    window.scroll(0, 0);
    this.spinner.show();
    if (this.inputIdDevis) {
      this.devisId = this.inputIdDevis;
    } else {
      this.devisId = this.cookies.get('devis');
    }
    this.initData();
    this.getListDocumentStatique();
    this.spinner.hide();
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => {});
  }

  getListDocumentStatique() {
    this.documentStatiqueService.getListDocumentsByProduitAndParcoursAndEcran('Sante', this.getParcours(), 'Mes formules').
      subscribe(rep => {
        this.listDocumentStatique = rep;
        if (this.listDocumentStatique.length > 0) {
          this.showDocs = true;
        }
      });
  }

  getParcours() {
    if (this.parcours === 'MIXTE') {
      return 'Prospect';
    } else if (this.parcours === 'AGENT') {
      return 'Agent';
    } else {
      return null;
    }
  }

  initData() {
    this.devisService.getDevisById(this.devisId).subscribe((data: DevisModelDTO) => {
      this.email = data.souscripteur.contact.email;
      let nbAdult = 0;
      let nbChilds = 0;
      let strAdulte;
      let strChilds;
      if (data.beneficiaires.length === 1) {
        this.nbBenefs = '1 Adulte';
      } else {
        data.beneficiaires.forEach(element => {
          if (element.typeBenef === 1) {
            nbAdult = 1;
          } else if (element.typeBenef === 2) {
            nbAdult = nbAdult + 1;
          } else if (element.typeBenef === 3) {
            nbChilds = nbChilds + 1;
          }
        });
        if (nbAdult > 1) {
          strAdulte = ' Adultes';
        } else {
          strAdulte = ' Adulte';
        }
        if (nbChilds === 1) {
          strChilds = ' et ' + nbChilds + ' enfant.';
        } else if (nbChilds > 1) {
          strChilds = ' et ' + nbChilds + ' enfants.';
        } else {
          strChilds = '.';
        }
        this.nbBenefs = nbAdult + strAdulte + strChilds;
      }
      this.devis = data;
      this.refDevis = this.devis.refDevis;
      this.dateFinDevis = this.devis.dateFinDevis;
      this.formuleLabel = this.devis.formule.formuleLabel;
      this.prenom = this.devis.beneficiaires[0].prenomBenef;
      this.nom = this.devis.beneficiaires[0].nomBenef;
      this.valDevis = this.devis.valDevis;
    }, error => {
      this.spinner.hide();
    });
  }

  continueAdhesion() {
      if (this.inputIdDevis) {
        this.cookies.set(OavConstants.DEVIS, this.inputIdDevis, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      }
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_FORMULE_SANTE
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.INFORMATION_SANTE_URL]);
  }

  backProfil() {
    this.tagCommanderSevice.trackingClick(TrakingParamConstants.SANTE_RECAPITULATIF_ABANDONNER_CLICKLABEL, ClickType.ACTION);
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    this.cookies.delete(OavConstants.DEVIS);
    if (!this.cookies.get('agent') && !this.cookies.get('client')) {
      this.cookies.delete('accessToken');
    }
    if (this.cookies.get('agent') && this.cookies.get('client')) {
      this.cookies.delete('client');
    }
    this.router.navigate([OavConstants.HOME_URL]);
  }

  downloadPdf(type) {
    this.devis.formule.fileToDownload = type;
    this.formulesService.getPdf(this.devis).subscribe((data: ArrayBuffer) => {
      const newBlob = new Blob([data], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const dataa = window.URL.createObjectURL(newBlob);
      if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = dataa;
        a.download = type;
        a.click();
        return;
      }
      window.open(dataa);
    }, error => {
      this.spinner.hide();
    });
  }

  imprimerDevis() {
    this.spinner.show();
    this.devisId = this.cookies.get('devis');
    this.bdocDto.devisId = this.devisId;
    this.bdocService.getFileFromBdoc(this.bdocDto).subscribe(res => {
      this.spinner.hide();
      this.data = res;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        this.handeIE11DownloadFile(this.data.bdocReponse.documentb64, 'Devis.pdf');
      } else {
        const a = document.createElement('a');
        a.href = 'data:application/pdf;base64,' + this.data.bdocReponse.documentb64;
        a.download = 'Devis.pdf';
        a.click();
      }
    }, error => {
    });
  }

  close() {
    this.emitClose.emit();
  }

  handeIE11DownloadFile(data64, name) {
    window.navigator.msSaveBlob(this.base64ToBlob(data64), name);
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: 'application/pdf' });
  }

  openDocumentStatique(doc) {
    this.documentStatiqueService.getDocumentStatique(doc).subscribe(rep => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(this.base64ToBlob(rep.encoded));
      } else {
        const fileURL = URL.createObjectURL(this.base64ToBlob(rep.encoded));
        if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = doc;
          a.click();
          return;
        }
        window.open(fileURL);
      }
    });
  }

  openGeolocalisation() {
    this.santiclaireService.getGeoclaireUrl().subscribe((data: any) => {
      window.open(data.location, '_blank');
    }, error => {
      console.error('error when get santiclaire');
    });
  }

  clickBtnDocumentation() {
    let showDocOpen = document.getElementsByClassName('slider-open').length;
    if (showDocOpen === 0) {
      this.tagCommanderSevice.trackingClick(TrakingParamConstants.SANTE_FORMULES_DOCUMENTATION_CLICKLABEL, ClickType.ACTION);
    }
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BesoinsComponent} from './components/besoins/besoins.component';
import {FormulesComponent} from './components/formules/formules.component';
import {InformationsComponent} from './components/informations/informations.component';
import {MandatSignatureComponent} from './components/mandat-signature/mandat-signature.component';
import {ValidationSanteComponent} from './components/validation/validation.component';
import {ProfileComponent} from './components/profile/profile.component';
import {ResumeFormuleSanteComponent} from './components/resumeFormule/resumeFormuleSante.component';
import {SauvegardeDevisComponent} from './components/sauvegarde-devis/sauvegarde-devis.component';
import {PaiementComponent} from './components/paiement/paiement.component';
import {PaiementFormComponent} from './components/paiement/paiement-form/paiement-form.component';


const routes: Routes = [
  {
    path: '',
    component: ProfileComponent
  },
  {
    path: 'besoins',
    component: BesoinsComponent
  },
  {
    path: 'formules',
    component: FormulesComponent
  },
  {
    path: 'informations',
    component: InformationsComponent
  },
  {
    path: 'mandat-signature',
    component: MandatSignatureComponent
  },
  {
    path: 'validation',
    component: ValidationSanteComponent
  },
  {
    path: 'resumeFormule',
    component: ResumeFormuleSanteComponent
  },
  {
    path: 'sauvegarde-devis',
    component: SauvegardeDevisComponent
  },
  {
    path: 'paiement-cb',
    component: PaiementComponent
  },
  {
    path: 'paiement-cb-confirmation',
    component: PaiementFormComponent
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProcessSanteRoutingModule {

}

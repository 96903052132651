import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(private router: Router, private cookies: CookieService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = this.cookies.get('accessToken');
        const userId = this.cookies.get('userId');

        if (token) {
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                // if (event instanceof HttpResponse) {
                //     console.log('event--->>>', event);
                // }
                return event;
            }),
            catchError((httpError: HttpErrorResponse) => {
                console.error('error : ', httpError.error.message);
                if ((userId || this.cookies.get('user')) && (httpError.error.status === 401 || httpError.error.status === 403)) {
                    this.cookies.delete('accessToken');
                    this.cookies.delete('userId');
                    this.router.navigate(['/login']);
                    // return;
                  }
                return throwError(httpError.error);
            }));
    }
}

import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TarificateurService} from 'src/app/features/process-sante/services/tarificateur.service';
import {DevisRisqueQuestionsModelDTO} from 'src/app/features/process-sante/models/devisRisqueQuestionsModelDTO';
import {OavConstants} from 'src/app/shared/data/OavConstants';
import {BesoinsService} from '../../services/besoins.service';
import {DevisRisqueQuestionsIdDTO} from '../../models/devisRisqueQuestionsIdDTO';
import {BesoinReponsesScoreDTO} from '../../models/besoinReponsesScoreDTO';
import {CookieService} from 'ngx-cookie-service';
import {DocumentStatiqueService} from 'src/app/shared/services/document-statique.service';
import {DocumentStatiqueModel} from 'src/app/shared/models/documentStatiqueModel';
import {TarifRequestDTO} from '../../models/tarifRequest';
import {GuardService} from 'src/app/shared/services/guard.service';
import {AppConfig} from 'src/app/app.config';
import {SanticlaireService} from 'src/app/shared/services/santiclaire.service';
import {TrakingParamConstants} from '../../../../shared/data/TrakingParamValues';
import {TagCommanderSevice} from '../../../../shared/services/tag-Commander.sevice';

@Component({
  selector: 'app-besoins',
  templateUrl: './besoins.component.html',
  styleUrls: ['./besoins.component.css']
})
export class BesoinsComponent implements OnInit {
  listRisquesQuestions = [];
  devisTotal = 0;
  devisId;
  questions: any[] = [];
  displayQuestions: any[] = [];
  currentQuestion = 0;
  allAnswerd = false;
  docsOpen = false;
  listDocumentStatique: DocumentStatiqueModel[] = [];
  parcours;
  produit;
  dataLoaded = false;
  tarifError = false;
  items = [
    {
      label: 'Mon Profil'
    },
    {
      label: 'Mes besoins'
    },
    {
      label: 'Formules'
    },
    {
      label: 'Votre devis'
    }
  ];
  activeIndex = 1;
  showDocs;
  agentCRC = false;
  isAgent = false;
  private canal: string;

  constructor(private router: Router, private spinner: NgxSpinnerService, private guardService: GuardService,
    private cookies: CookieService, private documentStatiqueService: DocumentStatiqueService,
    private tarificateurService: TarificateurService, private besoinsService: BesoinsService,
    private santiclaireService: SanticlaireService, private tagCommanderSevice: TagCommanderSevice,
              private readonly elementRef: ElementRef, private renderer: Renderer2
             ) { }

  ngOnInit() {
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.SANTE_DEVIS_ETAPE2_BESOINS_PAGENAME, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.SANTE_DEVIS_ETAPE2_BESOINS_PAGENAME, this.renderer, this.elementRef);
    this.isAgent = this.cookies.check('agent');
    if (this.cookies.get('agent')) {
      const agent = JSON.parse(this.cookies.get('agent'));
      this.agentCRC = agent.profil === '53' || agent.profil === '33';
    }
    this.spinner.show();
    this.checkForCurrentScreen();
    this.cookies.set('_latest', '2', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.delete('_ext');
    this.devisId = this.cookies.get(OavConstants.DEVIS);
    this.parcours = this.cookies.get(OavConstants.PARCOURS);
    this.produit = this.cookies.get(OavConstants.PRODUIT);
    this.getListDocumentStatique();
    this.getQuestions();
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => {});
  }

  getQuestions() {
    this.besoinsService.getQuestionsReponsesScoresByDevis(this.devisId).subscribe(rep => {
      this.questions.push(...rep.devisRisqueQuestionsModelDTOList);
      this.questions.forEach(question => {
        this.sortListReponsesByOrdre(question.id.besoinQuestions.besoinReponsesScores);
      });
      this.sortListQuestionsByOrdre(this.questions);
      this.initQuestions();
      this.getInitialTarif();
    }, error => {
      console.error('error when get questions');
      this.spinner.hide();
    });
  }

  sortListQuestionsByOrdre(list) {
    list.sort((a, b) => {
      if (a.id.besoinQuestions.ordre < b.id.besoinQuestions.ordre) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  sortListReponsesByOrdre(list) {
    list.sort((a, b) => {
      if (a.ordre < b.ordre) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    });
  }


  initQuestions() {
    this.currentQuestion = 0;
    if (this.questions.length > 0) {
      this.displayQuestions.push(this.questions[this.currentQuestion]);
    }
    while ((this.questions[this.currentQuestion + 1] != null) && (this.questions[this.currentQuestion].besoinReponse != null)) {
      this.displayQuestions.push(this.questions[this.currentQuestion + 1]);
      this.currentQuestion++;
    }
   // this.devisTotal = tarif;
    this.allAnswerd = this.isAllQuestionsAreAnswered();
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight - 900, left: 0, behavior: 'smooth' });
    });
  }

  saveReponse(question, reponse) {
    const answeredQuestionIndex = this.displayQuestions.findIndex((item) => question.id.besoinQuestions.idBesoinQuestions ===
      item.id.besoinQuestions.idBesoinQuestions);
    this.displayQuestions[answeredQuestionIndex].besoinReponse = reponse;
    this.saveReponseData(question, reponse);
    if (this.questions[this.currentQuestion + 1] != null && question.id.besoinQuestions.idBesoinQuestions ===
      this.questions[this.currentQuestion].id.besoinQuestions.idBesoinQuestions) {
      this.displayQuestions.push(this.questions[this.currentQuestion + 1]);
      this.currentQuestion++;
      setTimeout(() => {
        window.scrollTo({ top: document.body.scrollHeight - 900, left: 0, behavior: 'smooth' });
      });
    }
    this.allAnswerd = this.isAllQuestionsAreAnswered();
  }

  saveReponseData(question, reponse) {
    const devisRisqueQuestionsIdDTO: DevisRisqueQuestionsIdDTO = new DevisRisqueQuestionsIdDTO();
    devisRisqueQuestionsIdDTO.devis.idDevis = this.devisId;
    devisRisqueQuestionsIdDTO.besoinQuestions.idBesoinQuestions = question.id.besoinQuestions.idBesoinQuestions;
    const besoinReponsesScoreDTO: BesoinReponsesScoreDTO = new BesoinReponsesScoreDTO();
    besoinReponsesScoreDTO.idBesoinReponsesScore = reponse.idBesoinReponsesScore;
    const devisRisqueQuestionsModelDTO: DevisRisqueQuestionsModelDTO = new DevisRisqueQuestionsModelDTO();
    devisRisqueQuestionsModelDTO.id = devisRisqueQuestionsIdDTO;
    devisRisqueQuestionsModelDTO.besoinReponse = besoinReponsesScoreDTO;
    devisRisqueQuestionsModelDTO.tarif = this.devisTotal;
    this.besoinsService.saveReponse(devisRisqueQuestionsModelDTO).subscribe(rep => {
      // this.devisTotal = rep.tarif;
    }, error => {
      console.error('error when saving reponse');
    });
  }


  isAllQuestionsAreAnswered() {
    if (this.displayQuestions.length < this.questions.length) {
      return false;
    } else {
      let missingAnswer = false;
      this.displayQuestions.forEach(item => {
        if (item.besoinReponse == null) {
          missingAnswer = true;
        }
      });
      return !missingAnswer;
    }
  }

  changeViewTab() {
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_BESOIN_SANTE
      , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.FORMULE_SANTE_URL]);
  }

  backViewTab() {
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
  }


  getInitialTarif() {
    const tarifRequest = new TarifRequestDTO();
    tarifRequest.idDevis = this.devisId;
    tarifRequest.idProduit = 1;
    this.tarificateurService.getInitialTarif(tarifRequest).subscribe((result: number) => {
      this.devisTotal = result;
      this.dataLoaded = true;
      this.spinner.hide();
    }, error => {
      this.tarifError = true;
      console.error('error when getInitial tarification');
      this.spinner.hide();
    });
  }

  getParcours() {
    if (this.cookies.get('agent')) {
      return 'AGENT';
    } else if (this.cookies.get('client')) {
      return 'Prospect';
    } else {
      return null;
    }
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: 'application/pdf' });
  }

  openDocumentStatique(doc) {
    this.documentStatiqueService.getDocumentStatique(doc).subscribe(rep => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(this.base64ToBlob(rep.encoded));
      } else {
        const fileURL = URL.createObjectURL(this.base64ToBlob(rep.encoded));
        if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = doc;
          a.click();
          return;
        }
        window.open(fileURL);
      }
    });
  }

  getListDocumentStatique() {
    this.documentStatiqueService.getListDocumentsByProduitAndParcoursAndEcran('Santé', this.getParcours(), 'Besoin').subscribe(rep => {
      this.listDocumentStatique = rep;
      if (this.listDocumentStatique.length > 0 && this.cookies.get('agent')) {
        this.showDocs = true;
      }
    }, error => {
      console.error('error when get document statique');
    });
  }

  trackByFn(index, item) {
    return index;
  }

  openGeolocalisation() {
    this.santiclaireService.getGeoclaireUrl().subscribe((data: any) => {
      window.open(data.location, '_blank');
    }, error => {
      console.error('error when get santiclaire');
    });
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { OavConstants } from '../data/OavConstants';
import { NgxSpinnerService } from 'ngx-spinner';
import {AppConfig} from '../../app.config';


@Injectable({
  providedIn: 'root'
})
export class GuardService {

  constructor(private cookies: CookieService, private router: Router, private spinner: NgxSpinnerService) { }

  checkCurrentScreen(): Observable<Boolean> {
    const currentScreen = this.cookies.get(OavConstants.CURRENT_SCREEN);
    console.log('currentScreen :' + currentScreen);
    this.checkForCurrentDevis();
    if (currentScreen === OavConstants.ECRAN_PROFIL_SANTE) {
      this.router.navigate([OavConstants.BESOIN_SANTE_URL]);
    } else if (currentScreen === OavConstants.ECRAN_BESOIN_SANTE) {
      this.router.navigate([OavConstants.FORMULE_SANTE_URL]);
    } else if (currentScreen === OavConstants.ECRAN_FORMULE_SANTE) {
      this.router.navigate([OavConstants.RESUME_SANTE_URL]);
    } else if (currentScreen === OavConstants.ECRAN_RESUME_FORMULE_SANTE) {
      this.router.navigate([OavConstants.INFORMATION_SANTE_URL]);
    } else if (currentScreen === OavConstants.ECRAN_INFORMATIONS_SANTE) {
      this.router.navigate([OavConstants.MANDAT_SIGNATURE_SANTE_URL]);
    } else if (currentScreen === OavConstants.ECRAN_MANDAT_SANTE) {
      this.router.navigate([OavConstants.VALIDATION_SANTE_URL]);
    } else if (currentScreen === OavConstants.ECRAN_PAIEMENT_SANTE) {
      this.router.navigate([OavConstants.PAIEMENT_CONFIRME_SANTE_URL]);
    } else if (currentScreen === OavConstants.ECRAN_PAIEMENT_ACDS) {
      this.router.navigate([OavConstants.PAIEMENT_CONFIRM_ACDS_URL]);
    } else if (currentScreen === OavConstants.ECRAN_MANDAT_CB_SANTE) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.PAIEMENT_SANTE_URL]);
    } else if (currentScreen === OavConstants.ECRAN_MANDAT_CB_ACDS) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_ACDS
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.PAIEMENT_ACDS_URL]);
    } else if (currentScreen === OavConstants.ECRAN_SAUVEGARDE_INFORMATIONS_SANTE) {
      this.router.navigate([OavConstants.SAUVEGARDE_DEVIS_SANTE_URL]);
    } else if (currentScreen === OavConstants.ECRAN_PROFIL_ACDS) {
      this.router.navigate([OavConstants.BESOIN_ACDS_URL]);
    } else if (currentScreen === OavConstants.ECRAN_BESOIN_ACDS) {
      this.router.navigate([OavConstants.FORMULE_ACDS_URL]);
    } else if (currentScreen === OavConstants.ECRAN_FORMULE_ACDS) {
      this.router.navigate([OavConstants.RESUME_ACDS_URL]);
    } else if (currentScreen === OavConstants.ECRAN_RESUME_FORMULE_ACDS) {
      this.router.navigate([OavConstants.INFORMATION_ACDS_URL]);
    } else if (currentScreen === OavConstants.ECRAN_INFORMATIONS_ACDS) {
      this.router.navigate([OavConstants.MANDAT_SIGNATURE_ACDS_URL]);
    } else if (currentScreen === OavConstants.ECRAN_MANDAT_ACDS) {
      this.router.navigate([OavConstants.VALIDATION_ACDS_URL]);
    } else if (currentScreen === OavConstants.ECRAN_ESPACE_PROSPECT) {
      this.router.navigate([OavConstants.ESPACE_PROSPECT]);
    } else if (currentScreen === OavConstants.ECRAN_ESPACE_AGENT) {
      this.router.navigate([OavConstants.ESPACE_AGENT]);
    } else if (currentScreen === OavConstants.ECRAN_LIST_CONTRAT) {
      this.router.navigate([OavConstants.LIST_CONTRAT]);
    } else if (currentScreen === OavConstants.ECRAN_LIST_DEVIS) {
      this.router.navigate([OavConstants.LIST_DEVIS]);
    } else if (currentScreen === OavConstants.ECRAN_RESUME_DEVIS) {
      this.router.navigate([OavConstants.RESUME_DEVIS]);
    } else if (currentScreen === OavConstants.ECRAN_RESUME_CONTRAT) {
      this.router.navigate([OavConstants.RESUME_CONTRAT]);
    } else if (currentScreen === OavConstants.QMS) {
      this.router.navigate([OavConstants.QMS_ACDS_URL]);
    } else if (currentScreen === OavConstants.QMS_NO_ELIGIBLE) {
      this.router.navigate([OavConstants.QMS_NON_ELIGIBLE_ACDS_URL]);
    }
    return new Observable<Boolean>();
  }

  private checkForCurrentDevis() {
    if (!this.cookies.get(OavConstants.DEVIS) && this.cookies.get(OavConstants.CURRENT_SCREEN)) {
      this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
    } else if (!this.cookies.get(OavConstants.CURRENT_SCREEN)) {
      if (this.cookies.get('produit') === 'ACDS' || this.cookies.get('produit') === '2') {
        this.router.navigate([OavConstants.PROFIL_ACDS_URL]);
      } else if (this.cookies.get('produit') === 'OAV') {
        this.router.navigate([OavConstants.OAV_REDIRECTION]);
      } else {
        this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
      }
    }
  }
}

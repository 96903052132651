import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core-routing.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { FirstConnexionComponent } from './components/first-connexion/first-connexion.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LbpBIPComponent } from './components/lbp-bip/lbp-bip.component';
import { EspaceProspectComponent } from './components/espace-prospect/espace-prospect.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SharedModule } from '../shared/shared.module';
import { ResumeDevisComponent } from './components/espace-prospect/resume-devis/resume-devis.component';
import { ListDevisComponent } from './components/espace-prospect/list-devis/list-devis.component';
import { ListContratsComponent } from './components/espace-prospect/list-contrats/list-contrats.component';
import { EspaceAgentComponent } from './components/espace-agent/espace-agent.component';
import { ResumeContratComponent } from './components/espace-prospect/resume-contrat/resume-contrat.component';
import { ReinitPasswordComponent } from './components/reinit-password/reinit-password.component';
import { ExternalRedirectionComponent } from './components/external-redirection/external-redirection.component';
import { RenitMailInfoComponent } from './components/renit-mail-info/renit-mail-info.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ChangePassInfoComponent } from './components/change-pass-info/change-pass-info.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '../../assets/i18n/');
}
@NgModule({
  declarations: [
    LoginComponent,
    FirstConnexionComponent,
    LbpBIPComponent,
    EspaceProspectComponent,
    ResumeDevisComponent,
    ListDevisComponent,
    ListContratsComponent,
    EspaceAgentComponent,
    ResumeContratComponent,
    ReinitPasswordComponent,
    ExternalRedirectionComponent,
    RenitMailInfoComponent,
    ChangePassInfoComponent
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    SharedModule,
    CommonModule,
    FormsModule,
    AlertModule.forRoot(),
  TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  })
  ],
  providers: []
})
export class CoreModule { }

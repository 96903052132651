import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {TrakingParamConstants} from '../../../shared/data/TrakingParamValues';
import {TagCommanderSevice} from '../../../shared/services/tag-Commander.sevice';


@Component({
  selector: 'app-renit-mail-info',
  templateUrl: './renit-mail-info.component.html',
  styleUrls: ['./renit-mail-info.component.css']
})
export class RenitMailInfoComponent implements OnInit {

  private canal: string;

  constructor(private router: Router, private tagCommanderSevice: TagCommanderSevice,
              private readonly elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.PROSPECT_CONFIRMATION_ENVOI_MAIL_REINITIALISATION_PAGENAME, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.PROSPECT_CONFIRMATION_ENVOI_MAIL_REINITIALISATION_PAGENAME, this.renderer, this.elementRef);
  }

  authenticate() {
    this.router.navigate(['/login']);
  }
}

import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {DevisService} from 'src/app/features/process-sante/services/devis.service';
import {DevisModelDTO} from '../../../process-sante/models/devisDTO';
import {OavConstants} from 'src/app/shared/data/OavConstants';
import {BdocDTO} from 'src/app/features/process-sante/models/bdocDTO';
import {BdocService} from 'src/app/features/process-sante/services/bdoc.service';
import {CookieService} from 'ngx-cookie-service';
import {DevisStatus} from 'src/app/features/process-sante/models/devisStatus';
import {SignatureService} from 'src/app/features/process-sante/services/signature.service';
import {TessiSignDocsModelDTO} from 'src/app/features/process-sante/models/tessiSignDocsDTO';
import {GuardService} from 'src/app/shared/services/guard.service';
import {AppConfig} from 'src/app/app.config';
import {PaymentService} from 'src/app/shared/services/payment.service';
import {TrakingParamConstants} from '../../../../shared/data/TrakingParamValues';
import {TagCommanderSevice} from '../../../../shared/services/tag-Commander.sevice';

@Component({
  selector: 'app-validation-acds',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.css']
})
export class ValidationAcdsComponent implements OnInit {
  showCards = false;
  adherentDto = {};
  devisId;
  montantTotal = 20;
  formuleLabel;
  emailAdherent;
  toggleValid;

  items = [
    {
      label: 'Mon questionnaire médical'
    },
    {
      label: 'Mes informations'
    },
    {
      label: 'Mon mandat de prélèvement'
    },
    {
      label: 'Ma signature'
    },
    {
      label: 'Mon contrat'
    }
  ];
  activeIndex = 4;
  devis: any;
  refContrat;
  dateFinDevis;
  prenom;
  nom;
  valDevis;
  nbBenefs: any;
  type;
  signType;
  bdocDto = new BdocDTO();
  data: any;
  receivedDocs: boolean;
  status;
  dataLoaded = false;
  @Input() inputIdDevis: string;
  @Output() emitClose = new EventEmitter();
  @Input() isAgent = false;
  docNames;
  errorPDF;
  showPayed = false;
  transactionDate;
  payedMount;
  private canal: string;

  constructor(private spinner: NgxSpinnerService, private devisService: DevisService, private router: Router,
              private cookies: CookieService, private bdocService: BdocService, private guardService: GuardService,
              private signatureService: SignatureService, private paymentService: PaymentService,
              private tagCommanderSevice: TagCommanderSevice, private readonly elementRef: ElementRef,
              private renderer: Renderer2) {
    this.guardService.checkCurrentScreen().subscribe(data => {
    });
    this.canal = this.tagCommanderSevice.getCanal();
    this.tagCommanderSevice.trackingPage(TrakingParamConstants.ACDS_SOUSCRIPTION_ETAPE4_VOTRE_CONTRAT_PAGENAME, this.canal);
    this.tagCommanderSevice.reloadScript(TrakingParamConstants.ACDS_SOUSCRIPTION_ETAPE4_VOTRE_CONTRAT_PAGENAME,
      this.renderer, this.elementRef);
  }

  ngOnInit() {
    this.checkForCurrentScreen();
    this.spinner.show();
    this.devisId = this.inputIdDevis ? this.inputIdDevis : this.cookies.get(OavConstants.DEVIS);
    this.initData();
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => { });
  }

  initData() {
    this.devisService.getDevisById(this.devisId).subscribe((data: DevisModelDTO) => {
      this.devis = data;
      if (data.beneficiaires.length === 1) {
        this.nbBenefs = '1 Adulte';
      } else if (data.beneficiaires.length === 2 && data.beneficiaires[1].typeBenef === 4) {
        this.nbBenefs = '1 Adulte';
      } else {
        this.nbBenefs = '1 Adulte et ' + (data.beneficiaires.length - 1) + ' bénéficiaires.';
      }
      this.devis = data;
      this.refContrat = this.devis.refContrat;
      this.dateFinDevis = this.devis.dateFinDevis;
      this.formuleLabel = this.devis.formule.formuleLabel;
      this.prenom = this.devis.beneficiaires[0].prenomBenef;
      this.nom = this.devis.beneficiaires[0].nomBenef;
      this.valDevis = this.devis.valDevis;
      this.type = 'Assurance Coups Durs Santé';
      this.updateDevisStatus(DevisStatus.A_ENVOYER_EN_GESTION);
    }, error => {
      this.spinner.hide();
      console.error('error when get devis by id');
    });
  }
  downloadPdf(pdf) {
    this.signatureService.getPdf(pdf.id).subscribe((data: ArrayBuffer) => {
      const newBlob = new Blob([data], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob, pdf.documentType + '.pdf');
        return;
      }
      const dataa = window.URL.createObjectURL(newBlob);
      if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = dataa;
        a.download = pdf.documentType;
        a.click();
        return;
      }
      window.open(dataa);
    }, error => {
      this.spinner.hide();
      this.errorPDF = true;
    });
  }

  close() {
    this.cookies.delete('devis');
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    if (this.cookies.get('agent')) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_AGENT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.ESPACE_AGENT]);
    } else {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.ESPACE_PROSPECT]);
    }
  }

  private updateDevisStatus(status) {
    this.devisService.updateDevisStatus(this.devisId, status).subscribe((data: DevisModelDTO) => {
      this.getLatestByDevis();
    }, error => {
      console.error('error when update devis status');
      this.spinner.hide();
    });
  }

  private getLatestByDevis() {
    this.paymentService.getLatestByDevis(this.devisId).subscribe((data: any) => {
      if (data && data.status === 'PAID') {
        this.showPayed = true;
        this.transactionDate = data.legacyTransDate;
        this.payedMount = data.effectiveAmount / 100;
      }
      this.initDocs();
    }, error => {
      this.spinner.hide();
      console.error('error when get latest payment by devis');
    });
  }

  handeIE11DownloadFile(data64, name) {
    window.navigator.msSaveBlob(this.base64ToBlob(data64), name);
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: 'application/pdf' });
  }

  imprimerDevis() {
    this.spinner.show();
    this.bdocDto.devisId = this.devisId;
    this.bdocService.getFileFromBdoc(this.bdocDto).subscribe(res => {
      this.spinner.hide();
      this.data = res;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        this.handeIE11DownloadFile(this.data.bdocReponse.documentb64, 'Contrat.pdf');
      } else {
        const a = document.createElement('a');
        a.href = 'data:application/pdf;base64,' + this.data.bdocReponse.documentb64;
        a.download = 'Contrat.pdf';
        a.click();
      }
    }, error => {
      console.error('error when get files from bdoc');
    });
  }

  initDocs() {
    let parcours = 'MIXTE';
    if (this.cookies.get('agent')) {
      parcours = 'AGENT';
    }
    this.signatureService.getDocs(this.devisId, parcours).subscribe((res: Array<TessiSignDocsModelDTO>) => {
      this.docNames = res;
      this.dataLoaded = true;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.errorPDF = true;
      console.error('error when get documents');
    });
  }

  trackByFn(index, item) {
    return index;
  }
}
